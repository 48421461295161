import moment from 'moment-timezone';
import _ from 'lodash';

import type {
  DigitalMapAccessReportUnit,
  DigitalMapAccessReportDataSet,
} from 'shared/models/swagger';

export type GraphDataItem = {
  name: string;
  visitCount: number;
  userCount: number;
  compareVisitCount: number;
  compareUserCount: number;
};
export type DateFilterPreset =
  | '7_DAY'
  | '14_DAYS'
  | '28_DAYS'
  | '30_DAYS'
  | 'CUSTOM';
export type ChartDisplayType = 'PV' | 'USER';
export type DigitalMapAccessReportSettings = {
  startDate: string;
  endDate: string;
  compareStartDate: string;
  compareEndDate: string;
  dateFilterPreset: DateFilterPreset;
  compare: boolean;
  unit: DigitalMapAccessReportUnit;
  chartDisplayType: ChartDisplayType;
};
export const initialSettings: DigitalMapAccessReportSettings = {
  startDate: '',
  endDate: '',
  compareStartDate: '',
  compareEndDate: '',
  dateFilterPreset: '7_DAY',
  compare: false,
  unit: 'DAY',
  chartDisplayType: 'PV',
};
export const convertAccessReportDataSetsToGraphData = (
  dataSets: DigitalMapAccessReportDataSet[]
): GraphDataItem[] => {
  const graphData: GraphDataItem[] = [];

  if (dataSets.length < 1 || 2 < dataSets.length) {
    return [];
  }

  if (dataSets.length === 1) {
    const dates = getDates(
      dataSets[0]?.date_range?.start || '',
      dataSets[0]?.date_range?.end || '',
      dataSets[0]?.unit || 'DAY'
    );
    dates.forEach((date) => {
      graphData.push({
        name: date,
        visitCount: 0,
        userCount: 0,
        compareVisitCount: 0,
        compareUserCount: 0,
      });
    });
    (dataSets[0].items || []).forEach((item) => {
      const graphDataItem = graphData.find((e) => e.name === item.date);

      if (!graphDataItem) {
        return;
      }

      graphDataItem.visitCount += item.visit_count || 0;
      graphDataItem.userCount += item.user_count || 0;
    });

    if (
      graphData.length > 0 &&
      graphData[0].name < (dataSets[0]?.date_range?.start || '')
    ) {
      graphData[0].name = dataSets[0]?.date_range?.start || '';
    }
  } else {
    const diff1 = moment(dataSets[0]?.date_range?.end).diff(
      moment(dataSets[0]?.date_range?.start),
      'days'
    );
    const diff2 = moment(dataSets[1]?.date_range?.end).diff(
      moment(dataSets[1]?.date_range?.start),
      'days'
    );
    const diff = diff1 < diff2 ? diff2 : diff1;
    const start1 = dataSets[0]?.date_range?.start;
    const end1 = moment(dataSets[0]?.date_range?.start || '')
      .add(diff, 'days')
      .format('YYYY-MM-DD');
    const dates1 = getDates(
      start1 || '',
      end1 || '',
      dataSets[0]?.unit || 'DAY'
    );
    const start2 = dataSets[1]?.date_range?.start;
    const end2 = moment(dataSets[1]?.date_range?.start || '')
      .add(diff, 'days')
      .format('YYYY-MM-DD');
    const dates2 = getDates(
      start2 || '',
      end2 || '',
      dataSets[1]?.unit || 'DAY'
    );
    dates1.forEach((date) => {
      graphData.push({
        name: date,
        visitCount: 0,
        userCount: 0,
        compareVisitCount: 0,
        compareUserCount: 0,
      });
    });

    for (let idx = 0; idx < dates1.length; idx++) {
      const item = (dataSets[0]?.items || []).find(
        (item) => item.date === dates1[idx]
      );
      const compareDate = dates2[idx];
      const compareItem = (dataSets[1]?.items || []).find(
        (item) => item.date === compareDate
      );
      const graphDataItem = graphData[idx];

      if (!graphDataItem) {
        continue;
      }

      graphDataItem.visitCount += item?.visit_count || 0;
      graphDataItem.userCount += item?.user_count || 0;
      graphDataItem.compareVisitCount += compareItem?.visit_count || 0;
      graphDataItem.compareUserCount += compareItem?.user_count || 0;
    }

    if (
      graphData.length > 0 &&
      graphData[0].name < (dataSets[0]?.date_range?.start || '')
    ) {
      graphData[0].name = dataSets[0]?.date_range?.start || '';
    }
  }

  return _.sortBy(graphData, (graphDataItem) => Number(graphDataItem.name));
};
export const convertAccessReportDataSetsToTableData = (
  dataSets: DigitalMapAccessReportDataSet[]
): GraphDataItem[] => {
  const graphData: GraphDataItem[] = [];

  if (dataSets.length < 1 || 2 < dataSets.length) {
    return [];
  }

  if (dataSets.length === 1) {
    const dates = getDates(
      dataSets[0]?.date_range?.start || '',
      dataSets[0]?.date_range?.end || '',
      dataSets[0]?.unit || 'DAY'
    );
    dates.forEach((date) => {
      graphData.push({
        name: date,
        visitCount: 0,
        userCount: 0,
        compareVisitCount: 0,
        compareUserCount: 0,
      });
    });
    (dataSets[0].items || []).forEach((item) => {
      const graphDataItem = graphData.find((e) => e.name === item.date);

      if (!graphDataItem) {
        return;
      }

      graphDataItem.visitCount += item.visit_count || 0;
      graphDataItem.userCount += item.user_count || 0;
    });

    if (
      graphData.length > 0 &&
      graphData[0].name < (dataSets[0]?.date_range?.start || '')
    ) {
      graphData[0].name = dataSets[0]?.date_range?.start || '';
    }
  } else {
    const diff1 = moment(dataSets[0]?.date_range?.end).diff(
      moment(dataSets[0]?.date_range?.start),
      'days'
    );
    const diff2 = moment(dataSets[1]?.date_range?.end).diff(
      moment(dataSets[1]?.date_range?.start),
      'days'
    );
    const diff = diff1 < diff2 ? diff2 : diff1;
    const start1 = dataSets[0]?.date_range?.start;
    const end1 = moment(dataSets[0]?.date_range?.start || '')
      .add(diff, 'days')
      .format('YYYY-MM-DD');
    const dates1 = getDates(
      start1 || '',
      end1 || '',
      dataSets[0]?.unit || 'DAY'
    );
    const start2 = dataSets[1]?.date_range?.start;
    const end2 = moment(dataSets[1]?.date_range?.start || '')
      .add(diff, 'days')
      .format('YYYY-MM-DD');
    const dates2 = getDates(
      start2 || '',
      end2 || '',
      dataSets[1]?.unit || 'DAY'
    );
    dates1.forEach((date) => {
      graphData.push({
        name: date,
        visitCount: 0,
        userCount: 0,
        compareVisitCount: 0,
        compareUserCount: 0,
      });
    });

    for (let idx = 0; idx < dates1.length; idx++) {
      const item = (dataSets[0]?.items || []).find(
        (item) => item.date === dates1[idx]
      );
      const compareDate = dates2[idx];
      const compareItem = (dataSets[1]?.items || []).find(
        (item) => item.date === compareDate
      );
      const graphDataItem = graphData[idx];

      if (!graphDataItem) {
        continue;
      }

      graphDataItem.visitCount += item?.visit_count || 0;
      graphDataItem.userCount += item?.user_count || 0;
      graphDataItem.compareVisitCount += compareItem?.visit_count || 0;
      graphDataItem.compareUserCount += compareItem?.user_count || 0;
    }

    if (
      graphData.length > 0 &&
      graphData[0].name < (dataSets[0]?.date_range?.start || '')
    ) {
      graphData[0].name = dataSets[0]?.date_range?.start || '';
    }
  }

  return _.sortBy(graphData, (graphDataItem) => graphDataItem.name);
};

const getDates = (
  start: string,
  end: string,
  unit: DigitalMapAccessReportUnit
): string[] => {
  let startDay = moment(start);
  const endDay = moment(end);
  const dates = [];

  if (unit === 'DAY') {
    while (startDay.isSameOrBefore(endDay)) {
      dates.push(startDay.format('YYYY-MM-DD'));
      startDay = startDay.add(1, 'days');
    }
  } else if (unit === 'WEEK') {
    let startWeek = moment(startDay.startOf('isoWeek').format('YYYY-MM-DD'));
    const endWeek = moment(endDay.startOf('isoWeek').format('YYYY-MM-DD'));

    while (startWeek.isSameOrBefore(endWeek)) {
      dates.push(startWeek.format('YYYY-MM-DD'));
      startWeek = startWeek.add(1, 'weeks');
    }
  } else {
    let startMonth = moment(startDay.startOf('month').format('YYYY-MM-DD'));
    const endMonth = moment(endDay.startOf('month').format('YYYY-MM-DD'));

    while (startMonth.isSameOrBefore(endMonth)) {
      dates.push(startMonth.format('YYYY-MM-DD'));
      startMonth = startMonth.add(1, 'months');
    }
  }

  return dates;
};
