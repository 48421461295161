import { parsePhoneNumber } from 'libphonenumber-js';

import { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { InquiryBulkSMSRequest } from 'shared/models/swagger';

export interface FormValues {
  reservations: {
    summary: ReservationSummaryShape;
    phone: string;
  }[];
  message: string;
}

export const convertFormValuesToSwagger = (
  values: FormValues
): InquiryBulkSMSRequest => {
  return {
    reservations: values.reservations.map((reservation) => {
      const phoneNumber = parsePhoneNumber(reservation.phone);

      return {
        reservation_id: reservation.summary.id,
        phone: `+${phoneNumber.countryCallingCode}${phoneNumber.nationalNumber}`,
      };
    }),
    body: values.message,
  };
};
