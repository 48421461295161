import type { Weekday } from 'client/libraries/util/weekdays';
import type {
  ConsumptionTaxCalculation,
  Organization,
  EmailCustomizationEmailType,
  SourceLanguage,
} from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';

export type Language = 'EN_US' | 'JA_JP' | 'KO_KR' | 'ZH_CN' | 'ZH_TW';
export type SupplierEmailSubject = {
  email_type: EmailCustomizationEmailType;
  subject: string;
};
export type SiteSettingsFormValues = {
  googleAnalyticsTag: string;
  uaGoogleAnalyticsTag: string;
  ga4GoogleAnalyticsTag: string;
  googleTagManagerId: string;
  supportedLanguages: Language[];
  logoUrl: string;
  faviconUrl: string;
  officeAddress: string;
  officePhone: string;
  officeEmail: string;
  emergencyContact: string;
  operatingDaysOfWeek: Weekday[];
  operatingTimeOfDayStart: string;
  operatingTimeOfDayEnd: string;
  acceptedPaymentTypes: ('PAID_IN_FULL' | 'PAY_ON_BOARD')[];
  acceptedPIFPaymentCurrencies: string[];
  pobAcceptedPaymentMethods: ('CASH' | 'CREDIT_CARD' | 'BANK')[];
  pobCreditCardFeeFixed: string;
  pobCreditCardFeePercent: string;
  creditCardFeeType: 'PERCENT' | 'FIXED';
  snsIds: Record<string, string>;
  showEmailOffersOptionForAgentBookings: boolean;
  allowViewingReservationDetails: boolean;
  allowUpdatingReservationInfo: boolean;
  allowChangingReservationParameters: boolean;
  allowCancellingReservation: boolean;
  allowIssuingDownloadingReceiptPDF: boolean;
  enableNoIndex: boolean;
  receiptBusinessName: string;
  receiptProviso: string;
  businessRegistrationNumber: string;
  consumptionTaxCalculation: ConsumptionTaxCalculation;
  pobPaymentMessage: string;
  needReservationEmail: boolean;
  shouldShowReservationFormInfo: boolean;
  contactEmails: string[];
  needReservationNotifications: boolean;
  needQrCheckinNotifications: boolean;
  supplierEmailSubjectCustomizationSettings: SupplierEmailSubject[];
};

export const getInitialValues = (org: Organization): SiteSettingsFormValues => {
  const supplierGuestPaymentSettings = org.supplier_guest_payment_settings;
  const acceptedPaymentTypes =
    supplierGuestPaymentSettings?.accepted_guest_payment_types || [];
  const pobAcceptedPaymentMethods = (
    supplierGuestPaymentSettings?.accepted_guest_pob_payment_methods || []
  ).map((method) => method.payment_method ?? 'CREDIT_CARD');
  const creditCardRule =
    supplierGuestPaymentSettings?.accepted_guest_pob_payment_methods?.find(
      (m) => m.payment_method === 'CREDIT_CARD'
    );
  const acceptedPIFPaymentCurrencies =
    supplierGuestPaymentSettings?.accepted_pif_payment_currencies || [
      'JPY',
      'USD',
    ];
  let creditCardFeeType = 'PERCENT';
  let pobCreditCardFeePercent = `${creditCardRule?.fee_percent ?? 0}`;
  let pobCreditCardFeeFixed = '';

  if (creditCardRule?.fee_fixed) {
    creditCardFeeType = 'FIXED';
    pobCreditCardFeePercent = '0';
    pobCreditCardFeeFixed = creditCardRule?.fee_fixed?.substring(3);
  }

  const operatingHoursRule =
    org.operating_hours_rules && org.operating_hours_rules.length > 0
      ? org.operating_hours_rules[0]
      : undefined;
  const operatingDaysOfWeek = operatingHoursRule?.days_of_week || [];
  const operatingTimeOfDayStart =
    operatingHoursRule?.start_time_local || '9:00';
  const operatingTimeOfDayEnd = operatingHoursRule?.end_time_local || '17:00';
  const snsIds: Record<string, string> = {};
  (org?.sns_ids || []).forEach((snsId) => {
    if (snsId?.key) {
      snsIds[snsId.key] = snsId.id || '';
    }
  });

  const supplierGuestReceiptSettings = org.supplier_guest_receipt_settings;
  const consumptionTaxCalculation =
    supplierGuestReceiptSettings?.consumption_tax_calculation ?? 'ROUND_OFF';

  const supplierEmailSubject =
    getDefaultSupplierEmailSubjectCustomizationSettings(
      org?.source_language || 'EN_US'
    );

  return {
    emergencyContact: org.emergency_contact ?? '',
    googleAnalyticsTag: org.booking_widget_settings?.google_analytics_tag ?? '',
    uaGoogleAnalyticsTag:
      org.booking_widget_settings?.ua_google_analytics_tag ?? '',
    ga4GoogleAnalyticsTag:
      org.booking_widget_settings?.ga4_google_analytics_tag ?? '',
    googleTagManagerId:
      org.booking_widget_settings?.google_tag_manager_id ?? '',
    logoUrl: org.logo_url ?? '',
    faviconUrl: org.booking_widget_design_params?.favicon_url ?? '',
    officeAddress: org.office_mailing_address ?? '',
    officePhone: org.office_phone ?? '',
    supportedLanguages: org.supported_languages ?? [],
    officeEmail: org.office_email ?? '',
    operatingDaysOfWeek,
    operatingTimeOfDayStart,
    operatingTimeOfDayEnd,
    acceptedPaymentTypes,
    pobAcceptedPaymentMethods,
    pobCreditCardFeePercent,
    pobCreditCardFeeFixed,
    creditCardFeeType: creditCardFeeType as 'PERCENT' | 'FIXED',
    acceptedPIFPaymentCurrencies,
    snsIds,
    allowViewingReservationDetails:
      org?.guest_my_page_settings?.allow_viewing_reservation_details ?? false,
    allowUpdatingReservationInfo:
      org?.guest_my_page_settings?.allow_updating_reservation_info ?? false,
    allowChangingReservationParameters:
      org?.guest_my_page_settings?.allow_changing_reservation_parameters ??
      false,
    allowCancellingReservation:
      org?.guest_my_page_settings?.allow_cancelling_reservation ?? false,
    allowIssuingDownloadingReceiptPDF:
      org?.guest_my_page_settings?.allow_issuing_downloading_receipt_pdf ??
      false,
    showEmailOffersOptionForAgentBookings:
      org?.common_subscription_settings
        ?.show_email_offers_option_for_agent_bookings ?? false,
    enableNoIndex: org?.booking_widget_settings?.enable_no_index ?? false,
    receiptBusinessName:
      org?.supplier_guest_receipt_settings?.receipt_business_name ?? '',
    receiptProviso: org?.supplier_guest_receipt_settings?.receipt_proviso ?? '',
    businessRegistrationNumber:
      org?.supplier_guest_receipt_settings?.business_registration_number ?? '',
    consumptionTaxCalculation: consumptionTaxCalculation,
    pobPaymentMessage:
      org?.booking_widget_design_params?.pob_payment_custom_message ?? '',
    needReservationEmail: (org?.contact_emails || []).length > 0,
    shouldShowReservationFormInfo:
      org?.supplier_email_settings?.should_show_reservation_form_info ?? false,
    contactEmails: org?.contact_emails || [],
    needReservationNotifications:
      !org?.extranet_notification_settings?.disable_reservation_notifications,
    needQrCheckinNotifications:
      !org?.extranet_notification_settings?.disable_qr_checkin_notifications,
    supplierEmailSubjectCustomizationSettings: supplierEmailSubject.map(
      (s: SupplierEmailSubject) => {
        const target = (
          org?.supplier_email_subject_customization_settings || []
        ).find(
          (setting) =>
            (s.email_type as EmailCustomizationEmailType) == setting.email_type
        );
        if (target) {
          return target;
        }
        return s;
      }
    ),
  };
};

export const getDefaultSupplierEmailSubjectCustomizationSettings: any = (
  language: SourceLanguage
) => {
  return [
    {
      email_type: 'RESERVATION_AWAITING_CONFIRMATION',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_AWAITING_CONFIRMATION'
      ),
    },
    {
      email_type: 'RESERVATION_CONFIRMED',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_CONFIRMED'
      ),
    },
    {
      email_type: 'RESERVATION_WAITLISTED',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_WAITLISTED'
      ),
    },
    {
      email_type: 'RESERVATION_REJECTED',
      subject: getSupplierEmailDefaultSubject(language, 'RESERVATION_REJECTED'),
    },
    {
      email_type: 'RESERVATION_CONFIRMED_PAYMENT_REQUIRED',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_CONFIRMED_PAYMENT_REQUIRED'
      ),
    },
    {
      email_type: 'RESERVATION_CONFIRMED_PAYMENT_COMPLETE',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_CONFIRMED_PAYMENT_COMPLETE'
      ),
    },
    {
      email_type: 'RESERVATION_CANCELED_BY_SUPPLIER',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_CANCELED_BY_SUPPLIER'
      ),
    },
    {
      email_type: 'RESERVATION_CANCELED_BY_AGENT',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_CANCELED_BY_AGENT'
      ),
    },
    {
      email_type: 'RESERVATION_CANCELED_BY_GUEST',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_CANCELED_BY_GUEST'
      ),
    },
    {
      email_type: 'RESERVATION_UPDATED_BY_SUPPLIER',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_UPDATED_BY_SUPPLIER'
      ),
    },
    {
      email_type: 'RESERVATION_UPDATED_BY_AGENT',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_UPDATED_BY_AGENT'
      ),
    },
    {
      email_type: 'RESERVATION_UPDATED_BY_GUEST',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_UPDATED_BY_GUEST'
      ),
    },
    {
      email_type: 'RESERVATION_CHANGED',
      subject: getSupplierEmailDefaultSubject(language, 'RESERVATION_CHANGED'),
    },
    {
      email_type: 'RESERVATION_CHANGED_BY_GUEST',
      subject: getSupplierEmailDefaultSubject(
        language,
        'RESERVATION_CHANGED_BY_GUEST'
      ),
    },
  ];
};

export const isDefaultSupplierEmailSubject = (
  language: SourceLanguage,
  emailType: EmailCustomizationEmailType,
  subject: string
) => {
  return Boolean(
    getSupplierEmailDefaultSubject(language, emailType) == subject
  );
};

export const getSupplierEmailDefaultSubject = (
  language: SourceLanguage,
  item: EmailCustomizationEmailType
) => {
  switch (item) {
    case 'RESERVATION_AWAITING_CONFIRMATION':
      if (language === 'JA_JP') {
        return '予約リクエストを受け付けました';
      }
      return 'reservation awaiting confirmation';
    case 'RESERVATION_CONFIRMED':
      if (language === 'JA_JP') {
        return '予約が確定しました';
      }
      return 'reservation confirmed';
    case 'RESERVATION_WAITLISTED':
      if (language === 'JA_JP') {
        return '予約の確定を待っています';
      }
      return 'reservation waitlisted';
    case 'RESERVATION_REJECTED':
      if (language === 'JA_JP') {
        return '予約が受付けられませんでした';
      }
      return 'reservation rejected';
    case 'RESERVATION_CONFIRMED_PAYMENT_REQUIRED':
      if (language === 'JA_JP') {
        return '予約が確定しました【お支払手続きが必要です】';
      }
      return 'reservation confirmed (Payment required)';
    case 'RESERVATION_CONFIRMED_PAYMENT_COMPLETE':
      if (language === 'JA_JP') {
        return 'お支払が完了しました';
      }
      return 'Your payment has been completed';
    case 'RESERVATION_CANCELED_BY_SUPPLIER':
      if (language === 'JA_JP') {
        return 'キャンセルを受け付けました';
      }
      return 'reservation canceled';
    case 'RESERVATION_CANCELED_BY_AGENT':
      if (language === 'JA_JP') {
        return '予約がキャンセルされました';
      }
      return 'reservation canceled by agent';
    case 'RESERVATION_CANCELED_BY_GUEST':
      if (language === 'JA_JP') {
        return '予約がキャンセルされました';
      }
      return 'reservation canceled by guest';
    case 'RESERVATION_UPDATED_BY_SUPPLIER':
      if (language === 'JA_JP') {
        return '予約を更新しました';
      }
      return 'reservation successfully updated';
    case 'RESERVATION_UPDATED_BY_AGENT':
      if (language === 'JA_JP') {
        return '予約を更新しました';
      }
      return 'reservation updated by agent';
    case 'RESERVATION_UPDATED_BY_GUEST':
      if (language === 'JA_JP') {
        return '予約を更新しました';
      }
      return 'reservation updated by guest';
    case 'RESERVATION_CHANGED':
      if (language === 'JA_JP') {
        return '予約を変更しました';
      }
      return 'reservation changed';
    case 'RESERVATION_CHANGED_BY_GUEST':
      if (language === 'JA_JP') {
        return '予約を変更しました';
      }
      return 'reservation changed';
  }
};
export const getSupplierEmailSubjectCustomizationItemName = (
  t: TranslateFuncType,
  item: EmailCustomizationEmailType
) => {
  switch (item) {
    case 'RESERVATION_AWAITING_CONFIRMATION':
      return t('reservation request', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_CONFIRMED':
      return t('reservation confirmed', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_WAITLISTED':
      return t('reservation waitlisted', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_REJECTED':
      return t('reservation rejected', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_CONFIRMED_PAYMENT_REQUIRED':
      return t('reservation confirmed payment required', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_CONFIRMED_PAYMENT_COMPLETE':
      return t('reservation confirmed payment complete', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_CANCELED_BY_SUPPLIER':
      return t('reservation canceled by supplier', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_CANCELED_BY_AGENT':
      return t('reservation canceled by agent', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_CANCELED_BY_GUEST':
      return t('reservation canceled by guest', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_UPDATED_BY_SUPPLIER':
      return t('reservation updated by supplier', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_UPDATED_BY_AGENT':
      return t('reservation updated by agent', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_UPDATED_BY_GUEST':
      return t('reservation updated by guest', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_CHANGED':
      return t('reservation changed by supplier', {
        context: 'supplierCustomEmailSubject',
      });
    case 'RESERVATION_CHANGED_BY_GUEST':
      return t('reservation changed by guest', {
        context: 'supplierCustomEmailSubject',
      });
  }
};

export const getSupplierGuestPaymentSettings = (
  values: SiteSettingsFormValues,
  defaultCurrency?: string,
  activeUserOrganization?: Organization
): Organization['supplier_guest_payment_settings'] => {
  const guestPaymentTypes: ('PAY_ON_BOARD' | 'PAID_IN_FULL')[] = [];
  const pobPaymentMethods: {
    payment_method?: 'CREDIT_CARD' | 'CASH' | 'BANK';
    fee_fixed?: string;
    fee_percent?: number;
  }[] = [];
  const acceptPOB = (values.acceptedPaymentTypes || []).includes(
    'PAY_ON_BOARD'
  );
  const acceptPIF = (values.acceptedPaymentTypes || []).includes(
    'PAID_IN_FULL'
  );
  const pobAcceptCreditCard = (values.pobAcceptedPaymentMethods || []).includes(
    'CREDIT_CARD'
  );
  const pobAcceptCash = (values.pobAcceptedPaymentMethods || []).includes(
    'CASH'
  );
  const pobAcceptBankTransfer = (
    values.pobAcceptedPaymentMethods || []
  ).includes('BANK');

  if (acceptPOB) {
    guestPaymentTypes.push('PAY_ON_BOARD');

    if (pobAcceptCash) {
      pobPaymentMethods.push({
        payment_method: 'CASH',
      });
    }

    if (pobAcceptCreditCard) {
      if (values.creditCardFeeType === 'FIXED') {
        pobPaymentMethods.push({
          payment_method: 'CREDIT_CARD',
          fee_fixed: `${defaultCurrency}${values.pobCreditCardFeeFixed || 0}`,
        });
      } else {
        pobPaymentMethods.push({
          payment_method: 'CREDIT_CARD',
          fee_percent: values.pobCreditCardFeePercent
            ? parseFloat(values.pobCreditCardFeePercent)
            : 0,
        });
      }
    }

    if (pobAcceptBankTransfer) {
      pobPaymentMethods.push({
        payment_method: 'BANK',
      });
    }
  }

  if (acceptPIF) {
    guestPaymentTypes.push('PAID_IN_FULL');
  }

  return {
    ...activeUserOrganization?.supplier_guest_payment_settings,
    accepted_guest_payment_types: guestPaymentTypes,
    accepted_guest_pob_payment_methods: pobPaymentMethods,
    accepted_pif_payment_currencies: values.acceptedPIFPaymentCurrencies,
  };
};

export const isFloat = (s: string): boolean => {
  if (/^[0-9]*\.?[0-9]*$/.test(s)) {
    return true;
  }

  return false;
};
