import FileSaver from 'file-saver';
import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  DOWNLOAD_MANIFEST_CSV_FAILURE,
  DOWNLOAD_MANIFEST_CSV_REQUEST,
  DOWNLOAD_MANIFEST_CSV_SUCCESS,
  DOWNLOAD_MANIFEST_PDF_FAILURE,
  DOWNLOAD_MANIFEST_PDF_REQUEST,
  DOWNLOAD_MANIFEST_PDF_SUCCESS,
  FETCH_MANIFEST_FAILURE,
  FETCH_MANIFEST_REQUEST,
  FETCH_MANIFEST_SUCCESS,
  SEND_MANIFEST_EMAIL_FAILURE,
  SEND_MANIFEST_EMAIL_REQUEST,
  SEND_MANIFEST_EMAIL_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  ManifestExportParams,
  GetManifestRequest,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchManifestRequest = () => ({
  type: FETCH_MANIFEST_REQUEST,
});

const fetchManifestSuccess = (response: any) => ({
  type: FETCH_MANIFEST_SUCCESS,
  response,
});

const fetchManifestFailure = (error: any) => ({
  type: FETCH_MANIFEST_FAILURE,
  error,
});

let fetchManifestCancel: () => void | undefined;
export const fetchManifest =
  (queryParams: GetManifestRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchManifestCancel && fetchManifestCancel();
    dispatch(fetchManifestRequest());
    axios
      .get('/api/manifests', {
        params: queryParams,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchManifestCancel = c;
        }),
      })
      .then((result) => dispatch(fetchManifestSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchManifestFailure('canceled'));
        else dispatch(fetchManifestFailure(error));
      });
  };

const fetchManifestCSVRequest = () => ({
  type: DOWNLOAD_MANIFEST_CSV_REQUEST,
});

const fetchManifestCSVSuccess = () => ({
  type: DOWNLOAD_MANIFEST_CSV_SUCCESS,
});

const fetchManifestCSVFailure = (error: any) => ({
  type: DOWNLOAD_MANIFEST_CSV_FAILURE,
  error,
});

export const fetchManifestCSV =
  (req: ManifestExportParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchManifestCSVRequest());
    axios
      .post('/api/manifests/csv', req, {
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        dispatch(fetchManifestCSVSuccess());
        FileSaver.saveAs(response.data, 'manifest.csv');
      })
      .catch((error) => dispatch(fetchManifestCSVFailure(error)));
  };

const fetchManifestPDFRequest = () => ({
  type: DOWNLOAD_MANIFEST_PDF_REQUEST,
});

const fetchManifestPDFSuccess = () => ({
  type: DOWNLOAD_MANIFEST_PDF_SUCCESS,
});

const fetchManifestPDFFailure = (error: any) => ({
  type: DOWNLOAD_MANIFEST_PDF_FAILURE,
  error,
});

export const fetchManifestPDF =
  (req: ManifestExportParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchManifestPDFRequest());
    axios
      .post('/api/manifests/pdf', req, {
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        dispatch(fetchManifestPDFSuccess());
        FileSaver.saveAs(response.data, 'manifest.pdf');
      })
      .catch(() => dispatch(fetchManifestPDFFailure('fetch failed')));
  };

const sendManifestEmailRequest = () => ({
  type: SEND_MANIFEST_EMAIL_REQUEST,
});

const sendManifestEmailSuccess = () => ({
  type: SEND_MANIFEST_EMAIL_SUCCESS,
});

const sendManifestEmailFailure = (error: any) => ({
  type: SEND_MANIFEST_EMAIL_FAILURE,
  error,
});

export const sendManifestEmail =
  (req: ManifestExportParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendManifestEmailRequest());
    axios
      .post('/api/manifests/email', req, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then(() => dispatch(sendManifestEmailSuccess()))
      .catch((error) => dispatch(sendManifestEmailFailure(error)));
  };
