import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, useFormState, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { DraggableSelect } from 'client/components/DraggableSelect/DraggableSelect';
import { getArrayMutators } from 'client/libraries/util/form';
import { Button, Select, ToggleButton } from 'client/components/Form';
import { getValidators } from 'shared/libraries/validate/validator';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import type { ReduxState } from 'client/reducers';

import {
  convertFormValuesToSwaggerTopPageSection,
  TopPageFormValues,
} from '../FormValues';
import type { RecommendedProductList } from '../FormValues';
import { updateTopPageSection } from '../util';

type Props = {
  name: string;
  open: boolean;
  onClose: () => void;
};
export const EditRecommendedProductsSectionModal = ({
  name,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );
  const dispatch = useDispatch();
  const productOptions = allProducts.map((product) => ({
    key: product.id ?? '',
    value: product.id ?? '',
    text: product.product_name ?? '',
  }));
  const parentForm = useForm<TopPageFormValues>();
  const formState = useFormState<TopPageFormValues>();
  const language = formState.values?.sectionLanguage ?? 'JA_JP';
  const { required } = getValidators(t);

  const initialValues = _.get(formState.values, name);

  return (
    <Modal
      title={t('Edit recommended products')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={async (values: RecommendedProductList) => {
          await dispatch(
            updateTopPageSection(
              convertFormValuesToSwaggerTopPageSection(values),
              language
            )
          );
          parentForm.change(name as any, values);
          onClose();
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting, form }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              e.stopPropagation();
            }}
          >
            <Modal.Content>
              <Box mb={2}>
                <Field type="checkbox" name="isVisible">
                  {({ input }) => (
                    <ToggleButton
                      {...input}
                      label={t(
                        'Show recommended products on booking website top page'
                      )}
                    />
                  )}
                </Field>
              </Box>
              <Box mb={2}>
                <Field name="productIds">
                  {({ input }) => (
                    <Select
                      label={t('Number of Recommended Products')}
                      options={_.times(6, (idx) => ({
                        text: `${idx + 1}`,
                        value: `${idx + 1}`,
                        key: idx + 1,
                      }))}
                      value={input.value.length}
                      onChange={(e, { value }) => {
                        input.onChange(
                          _.times(parseInt(value), (idx) =>
                            idx < input.value.length ? input.value[idx] : ''
                          )
                        );
                      }}
                    />
                  )}
                </Field>
              </Box>
              <FieldArray name="productIds">
                {({ fields }) => {
                  return fields.map((fieldName, idx) => {
                    return (
                      <Field
                        key={fieldName}
                        name={fieldName}
                        validate={required}
                      >
                        {({ input, meta: { touched, error } }) => (
                          <DraggableSelect
                            key={name}
                            value={input.value}
                            options={
                              // Options are any product IDs not yet present
                              productOptions.filter(
                                (p) =>
                                  input.value === p.key ||
                                  !fields.value?.includes(p.key)
                              )
                            }
                            index={idx}
                            onValueChange={input.onChange}
                            moveItem={(
                              dragIndex: number,
                              hoverIndex: number
                            ) => {
                              fields.move(dragIndex, hoverIndex);
                            }}
                            error={touched && error}
                          />
                        )}
                      </Field>
                    );
                  });
                }}
              </FieldArray>
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel
                onClick={() => {
                  form.change('isVisible', initialValues?.isVisible);
                  form.change('productIds', initialValues?.productIds);
                  onClose();
                }}
              >
                {t('Discard')}
              </Button.Cancel>
              <Button
                size="middle"
                style="blue"
                type="submit"
                loading={submitting}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
