import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment-timezone';

import { Box } from 'client/components/Box/Box';
import { FocusableInput, ToggleButton } from 'client/components/Form';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { StartTimesInput } from 'client/components/NewProductEditor/ReservationParamsSteps/StartTimesInput';
import { AvailabilityDatesInput } from 'client/components/NewProductEditor/ReservationParamsSteps/AvailabilityDatesInput';
import { timezoneOptions } from 'client/libraries/util/timezoneOptions';
import { getResourceTypeText } from 'client/libraries/util/resourceManager';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/ProductEditor/ProductEditor.module.css';

import { findOverlappingDateRange } from './util';
import {
  getAvailabilityRuleScheduleText,
  schedulesHaveRequestOnly,
  schedulesHaveFreesaleOnly,
} from './FormValues';
import type { AvailabilityAllotmentSchedule } from './FormValues';

const getDefaultAvailabilityAllotmentSchedule =
  (): AvailabilityAllotmentSchedule => {
    const defaultStartDate = moment().format('YYYY-MM-DD');
    const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');
    return {
      dateRanges: [
        {
          startDate: defaultStartDate,
          endDate: defaultEndDate,
        },
      ],
      weekdays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      closedDates: [],
      startTimes: [
        {
          time: '9:00',
          duration: '3:00',
          allotmentSlots: 10,
          description: '',
          perChannelAllotments: [],
          packageComponentTimeSlots: [],
        },
      ],
      key: uuidv4(),
    };
  };

export const AvailabilityAllotmentEditor = () => {
  const [activeScheduleIndex, setActiveScheduleIndex] =
    React.useState<number>(0);
  const { t } = useTranslation();
  const editingProduct = React.useContext(EditingProductContext);
  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0;
  const isSharedAllotment = Boolean(
    editingProduct?.shared_allotment_references?.parent_product_id
  );

  const recurrence = React.useMemo(() => {
    if (
      editingProduct?.recurrence &&
      editingProduct?.recurrence?.length > activeScheduleIndex
    ) {
      return editingProduct?.recurrence[activeScheduleIndex];
    }
    return undefined;
  }, [editingProduct?.recurrence, activeScheduleIndex]);

  const isProductEditOrProductCopy =
    location.pathname.includes('edit') || location.pathname.includes('copy');
  const validateNoOverlaps = React.useCallback(
    (schedules: AvailabilityAllotmentSchedule[]): any | null | undefined => {
      for (const [idx, schedule] of schedules.entries()) {
        const overlap = findOverlappingDateRange(
          schedule,
          schedules.slice(idx + 1)
        );

        if (overlap) {
          return t(
            'Duplicate participation rules for {{startDate}} ~ {{endDate}}. Please edit date range or add closed dates.',
            {
              startDate: overlap.startDate,
              endDate: overlap.endDate,
            }
          );
        }
      }

      return undefined;
    },
    [t]
  );
  const formState = useFormState();
  const isRequestOnlyProduct = schedulesHaveRequestOnly(
    formState?.values?.availabilityAllotmentSchedules
  );
  const isFreesaleOnlyProduct = schedulesHaveFreesaleOnly(
    formState?.values?.availabilityAllotmentSchedules
  );

  return (
    <FieldArray
      name="availabilityAllotmentSchedules"
      validate={validateNoOverlaps as any}
    >
      {({ fields, meta: { error, touched } }) => (
        <>
          <div className={styles['page-productsEdit__select']}>
            <p className={styles['page-productsEdit__select__ttl']}>
              {t('Select')}
            </p>
            <div className={styles['page-productsEdit__select__box']}>
              <label
                className={clsx(
                  baseStyles['base-form-select'],
                  baseStyles['base-maxwidth-800']
                )}
              >
                <select
                  value={activeScheduleIndex}
                  onChange={(e) => {
                    setActiveScheduleIndex(parseInt(e.target.value));
                  }}
                >
                  {fields.value.map((availabilityAllotmentSchedule, idx) => (
                    <option key={idx} value={idx}>
                      {getAvailabilityRuleScheduleText(
                        availabilityAllotmentSchedule,
                        t
                      )}
                    </option>
                  ))}
                </select>
              </label>
              <a
                className={clsx(
                  baseStyles['base-btn'],
                  baseStyles['small'],
                  baseStyles['green']
                )}
                onClick={() => {
                  fields.push(getDefaultAvailabilityAllotmentSchedule());
                  setActiveScheduleIndex(fields.length ?? 0);
                }}
              >
                {t('Add Participation Rule')}
              </a>
            </div>
            {error && typeof error === 'string' && (
              <FocusableInput name="availabilityAllotmentSchedules" />
            )}
            {touched && error && typeof error === 'string' && (
              <>
                <p className={baseStyles['base-form-box__err']}>{error}</p>
              </>
            )}
          </div>
          <div
            key={activeScheduleIndex}
            className={baseStyles['base-selectFrame']}
          >
            <div className={baseStyles['base-selectFrame__header']}>
              <p className={baseStyles['base-selectFrame__header__ttl']}>
                {t('Selected Rule')}
              </p>
              {fields.value.length > 1 && (
                <a
                  className={baseStyles['base-selectFrame__header__delete']}
                  onClick={() => {
                    fields.remove(activeScheduleIndex);
                    setActiveScheduleIndex(0);
                  }}
                >
                  {t('Remove this Rule')}
                </a>
              )}
            </div>
            <div className={baseStyles['base-selectFrame__body']}>
              <p className={styles['page-productsRegist__priceMain__ttl']}>
                {t('Availability Settings')}
              </p>
              <AvailabilityDatesInput
                name={`availabilityAllotmentSchedules.${activeScheduleIndex}`}
              />
              <p
                className={clsx(
                  styles['page-productsRegist__priceMain__ttl'],
                  styles['borderTop']
                )}
              >
                {t('Times and Inventory')}
              </p>
              <StartTimesInput
                name={`availabilityAllotmentSchedules.${activeScheduleIndex}`}
                showAllotment={!isPackage && !isSharedAllotment}
              />
              {(recurrence?.related_resources ?? []).length > 0 && (
                <Box mb={2}>
                  <div>
                    {t(
                      'Note: Product is linked with resources and availability of the resources will be the effective inventory count'
                    )}
                  </div>
                  <div>{t('Linked Resource')}:</div>
                  <ul>
                    {recurrence?.related_resources?.map((resource, idx) => (
                      <li key={idx}>
                        {getResourceTypeText(resource.type ?? '', t)} :{' '}
                        {resource.key}
                      </li>
                    ))}
                  </ul>
                </Box>
              )}
              <Box mb={2}>
                {isProductEditOrProductCopy &&
                  !isPackage &&
                  !isSharedAllotment &&
                  !isRequestOnlyProduct &&
                  !isFreesaleOnlyProduct && (
                    <Field name="allowRequestBookingBeyondCapacity">
                      {({ input }) => (
                        <ToggleButton
                          label={t(
                            'Accept request bookings when there is not enough availability'
                          )}
                          checked={input.value}
                          onChange={() => input.onChange(!input.value)}
                        />
                      )}
                    </Field>
                  )}
              </Box>
              <Field name="timezone">
                {({ input }) => (
                  <div className={styles['page-productsEdit__select']}>
                    <p className={styles['page-productsEdit__select__ttl']}>
                      {t('Timezone')}
                    </p>
                    <div className={styles['page-productsEdit__select__box']}>
                      <label
                        className={clsx(
                          baseStyles['base-form-select'],
                          baseStyles['base-maxwidth-800']
                        )}
                      >
                        <select {...input}>
                          {timezoneOptions.map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.text}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  </div>
                )}
              </Field>
            </div>
          </div>
        </>
      )}
    </FieldArray>
  );
};
