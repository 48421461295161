import * as React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/v3/Common/Button';
import { DashboardGadget } from 'client/reducers/dashboardSettings';

import { Gadget } from './Gadget';
import { GadgetEditContext } from './GadgetEditContext';

interface Props {
  gadget: DashboardGadget;
  positionInRow?: 'left' | 'right' | 'middle';
}

export const SortableGadget = ({ gadget, positionInRow }: Props) => {
  const { editModeIsActive, onDeleteGadget, onEditGadgetClick } =
    React.useContext(GadgetEditContext);
  const { t } = useTranslation();

  const {
    setNodeRef,
    transition,
    transform,
    isDragging,
    listeners,
    attributes,
  } = useSortable({
    id: gadget.key,
    disabled: !editModeIsActive,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
    width: '100%',
  };

  return (
    <Gadget
      gadget={gadget}
      ref={setNodeRef}
      style={style}
      listeners={listeners}
      attributes={attributes}
      actions={
        editModeIsActive ? (
          <Button
            color="white"
            iconBeforeText={<i className="c-icon-outline-general-trash-03" />}
            text={t('Delete')}
            onClick={() => onDeleteGadget(gadget.key)}
          />
        ) : (
          <Button
            color="white"
            iconBeforeText={<i className="c-icon-outline-general-edit-05" />}
            text={t('Edit')}
            onClick={() => onEditGadgetClick(gadget.key)}
          />
        )
      }
      positionInRow={positionInRow}
    />
  );
};
