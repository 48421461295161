import axios from 'axios';
import type { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_MEDIA_DOWNLOAD_ORDERS_SUCCESS,
  FETCH_MEDIA_DOWNLOAD_ORDERS_FAILURE,
  FETCH_MEDIA_DOWNLOAD_ORDERS_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { ListMediaDownloadOrdersRequest } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchMediaDownloadOrdersRequest = () => ({
  type: FETCH_MEDIA_DOWNLOAD_ORDERS_REQUEST,
});

const fetchMediaDownloadOrdersSuccess = (response: any) => ({
  type: FETCH_MEDIA_DOWNLOAD_ORDERS_SUCCESS,
  response,
});

const fetchMediaDownloadOrdersFailure = (error: any) => ({
  type: FETCH_MEDIA_DOWNLOAD_ORDERS_FAILURE,
  error,
});

export const fetchMediaDownloadOrders =
  (req: ListMediaDownloadOrdersRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchMediaDownloadOrdersRequest());
    axios
      .get('/api/mediadownloadorders', {
        params: req,
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchMediaDownloadOrdersSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchMediaDownloadOrdersFailure('canceled'));
        else dispatch(fetchMediaDownloadOrdersFailure(error));
      });
  };
