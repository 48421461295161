// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';
import clsx from 'clsx';

import {
  cancelReservation,
  fetchReservationByID,
} from 'client/actions/reservations';
import type { Reservation } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import type { ReduxState } from 'client/reducers';
import { ModalLoader } from 'client/components/ModalLoader';
import { getStartTime } from 'client/libraries/util/coreutil';
import componentStyles from 'client/components/components.module.css';
import pageStyles from 'client/pages/pages.module.css';
import { Modal } from 'client/components/Modal/Modal';
import { FieldWrapper, Button } from 'client/components/Form';

type OwnProps = {
  t: TranslateFuncType,
  locale: string,
  onDeclineCompleted?: () => void,
  fetchReservationByID: (string) => void,
  reservationID: string,
  reservation?: Reservation,
  loading: boolean,
  withdrawReservation: (string) => Promise<Object>,
  trigger: React.Element<'a' | 'button'>,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
};

class WithdrawReservationButtonComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  fetchReservation = () => {
    const { reservation } = this.props;

    if (!reservation) {
      this.props.fetchReservationByID(this.props.reservationID);
    }

    this.setState({
      showModal: true,
    });
  };

  render() {
    const {
      loading,
      onDeclineCompleted,
      reservation,
      reservationID,
      locale,
      t,
      trigger,
    } = this.props;

    const productName = (reservation && reservation.product_name) || '';
    const guests = (reservation && reservation.guests) || [];

    const startTime = reservation && getStartTime(reservation).locale(locale);

    return (
      <Modal
        title={t('Withdraw reservation')}
        trigger={trigger}
        open={this.state.showModal}
        onOpen={this.fetchReservation}
        onClose={() =>
          this.setState({
            showModal: false,
          })
        }
      >
        <div className={clsx(pageStyles['page-reservations__modal'])}>
          {loading ? (
            <ModalLoader />
          ) : (
            <>
              <div
                className={clsx(pageStyles['page-reservations__modal__box'])}
              >
                <FieldWrapper label={t('Product')}>{productName}</FieldWrapper>
              </div>

              <div
                className={clsx(pageStyles['page-reservations__modal__box'])}
              >
                <FieldWrapper label={t('Start time')}>
                  {moment(startTime).format('lll')}
                </FieldWrapper>
              </div>

              <div
                className={clsx(pageStyles['page-reservations__modal__box'])}
              >
                <FieldWrapper label={t('Total participants')}>
                  {guests.length}
                </FieldWrapper>
              </div>
            </>
          )}
        </div>
        <div className={clsx(componentStyles['c-modal__frame__body__btns'])}>
          <Button
            style="red"
            size="middle"
            inverted
            onClick={() => {
              this.props
                .withdrawReservation(reservationID)
                .then(() => onDeclineCompleted && onDeclineCompleted());
              this.setState({
                showModal: false,
              });
            }}
          >
            {t('Withdraw')}
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => ({
  loading: state.reservations.loading,
  reservation: state.reservations.byID[ownProps.reservationID],
  locale: state.language.selected.iso,
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  withdrawReservation: (id: string) => dispatch(cancelReservation(id)),
  fetchReservationByID: (id: string) => dispatch(fetchReservationByID(id)),
});

export const WithdrawReservationButtonV2 = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(WithdrawReservationButtonComponent);
