import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-focus';

import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { Divider } from 'client/components/Divider/Divider';
import { getValidators } from 'shared/libraries/validate/validator';
import { getArrayMutators } from 'client/libraries/util/form';
import { composeValidators } from 'client/libraries/util/composeValidators';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Badge, BadgeColor } from 'client/components/v3/Common/Badge';
import { journeyAnalyticsAutoTagSelector } from 'client/reducers/supplierSettings';
import { putSupplierSettings } from 'client/actions/supplierSettings';
import { ReduxState } from 'client/reducers';

import { ConditionGroupEditor } from './ConditionGroupEditor';
import styles from './EditTagModal.module.css';
import {
  AnalyticsCustomerAutoTag,
  convertFormValuesToSwaggerTag,
  convertSwaggerTagToFormValues,
} from './formValues';

type FormValues = AnalyticsCustomerAutoTag;

interface Props {
  onClose: () => void;
  tagIdx: number;
}

const focusOnError: any = createDecorator<FormValues, Partial<FormValues>>();

export const EditTagModal = ({ onClose, tagIdx }: Props) => {
  const { t } = useTranslation();

  const { required } = getValidators(t);

  const dispatch = useDispatch();

  const existingSettings = useSelector(
    (state: ReduxState) => state.supplierSettings.journeyAnalytics
  );
  const tags = useSelector(journeyAnalyticsAutoTagSelector);

  const colorOptions = [
    {
      text: t('Gray'),
      value: 'gray',
    },
    {
      text: t('Orange'),
      value: 'orange',
    },
    {
      text: t('Green'),
      value: 'green',
    },
    {
      text: t('Yellow'),
      value: 'yellow',
    },
    {
      text: t('Blue'),
      value: 'blue',
    },
    {
      text: t('Purple'),
      value: 'purple',
    },
    {
      text: t('Pink'),
      value: 'pink',
    },
    {
      text: t('Red'),
      value: 'red',
    },
  ];

  const tagNameUnique = React.useCallback(
    (tagName: string) => {
      if (tags.some((tag, idx) => tag.tag_name === tagName && idx !== tagIdx)) {
        return t('Tag name must be unique');
      }

      return undefined;
    },
    [tags]
  );

  const initialValues = React.useMemo((): AnalyticsCustomerAutoTag => {
    if (tagIdx >= tags.length) {
      return {
        tagName: '',
        tagColor: '',
        conditionGroups: [
          {
            classification: 'ANY',
            gender: 'ANY',
            ageGroup: 'ANY',
            device: 'ANY',
            referralPaths: [],
            dateRangePreset: 'LAST_24_HOURS',
            dimensionType: 'NONE',
          },
        ],
      };
    } else {
      return {
        ...convertSwaggerTagToFormValues(tags[tagIdx]),
      };
    }
  }, [tags, tagIdx]);

  return (
    <Form<FormValues>
      initialValues={initialValues}
      decorators={[focusOnError]}
      mutators={getArrayMutators()}
      onSubmit={async (values: FormValues) => {
        const newTags = [...tags];
        if (tagIdx < tags.length) {
          newTags[tagIdx] = convertFormValuesToSwaggerTag(values);
        } else {
          newTags.push(convertFormValuesToSwaggerTag(values));
        }
        await dispatch(
          putSupplierSettings('JOURNEY_ANALYTICS', {
            journey_analytics_settings: {
              ...existingSettings,
              autotags: newTags,
            },
          })
        );

        onClose();
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id="edit-tag-modal">
          <Modal
            insertAtRoot
            open={true}
            title={t('Edit Tag')}
            onClose={onClose}
            rightActionChildren={
              <>
                <Button
                  text={t('Cancel')}
                  size="md"
                  color="white"
                  onClick={onClose}
                />
                <Button text={t('Save')} type="submit" form="edit-tag-modal" />
              </>
            }
          >
            <Field
              name="tagName"
              validate={composeValidators(required, tagNameUnique)}
            >
              {({ input, meta: { error, touched } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  label={t('Tag Name')}
                  required
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
            <Box mt={4}>
              <Field name="tagColor" validate={required}>
                {({ input, meta: { error, touched } }) => (
                  <SingleDropdown
                    selectedOption={input.value}
                    onChange={input.onChange}
                    label={t('Tag Color')}
                    options={colorOptions}
                    error={touched && error ? error : undefined}
                    renderOption={(option) => (
                      <Box display="flex" gap={1} alignItems="center">
                        <Badge
                          size="sm"
                          borderRadius="4px"
                          color={option.value as BadgeColor}
                          label={' '}
                        />
                        <p>{option.text}</p>
                      </Box>
                    )}
                  />
                )}
              </Field>
            </Box>
            <Divider />
            <FieldArray name="conditionGroups">
              {({ fields }) =>
                fields.map((f, idx) => (
                  <>
                    <ConditionGroupEditor key={f} name={f} />
                    <hr
                      className={styles['condition-group-divider']}
                      data-content={t('OR')}
                    />
                    {idx === (fields.length ?? 0) - 1 && (
                      <div
                        className={styles['add-new']}
                        onClick={() =>
                          (fields as any).insertAt(idx + 1, {
                            classification: 'ANY',
                            gender: 'ANY',
                            ageGroup: 'ANY',
                            device: 'ANY',
                            referralPaths: [],
                            dateRangePreset: 'LAST_24_HOURS',
                            dimensionType: '',
                          })
                        }
                      >
                        <i className="c-icon-outline-general-plus-circle"></i>
                        <p>{t('Add Condition')}</p>
                      </div>
                    )}
                  </>
                ))
              }
            </FieldArray>
          </Modal>
        </form>
      )}
    </Form>
  );
};
