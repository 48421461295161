import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { productOptionsSelector } from 'client/reducers/products';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { DashboardGadget } from 'client/reducers/dashboardSettings';
import styles from 'client/pages/v3/FlexibleDashboard/FlexibleDashboard.module.css';

export const ReservationReportGadgetEditor = () => {
  const { t } = useTranslation();

  const productOptions = useSelector(productOptionsSelector);

  const form = useForm<DashboardGadget>();
  const values = form.getState().values;
  values.gadgetType = 'reservation-report';

  return (
    <>
      <li className={styles['p-dashboardModal__item']}>
        <p className={styles['p-dashboardModal__item__ttl']}>
          {t('Display Data')}
        </p>
        <div className={styles['p-dashboardModal__item__body']}>
          <Field name="params.displayType">
            {({ input }) => (
              <SingleDropdown
                selectedOption={input.value}
                onChange={input.onChange}
                options={[
                  { value: 'LINE_CHART', text: t('Line Chart') },
                  {
                    value: 'SOURCES_TABLE',
                    text: t('Top 5 reservation sources'),
                  },
                  {
                    value: 'SOURCES_BAR_CHART',
                    text: t('Totals by Reservation sources in bar chart'),
                  },
                  { value: 'PRODUCTS_TABLE', text: t('Top 5 products') },
                  {
                    value: 'PRODUCTS_BAR_CHART',
                    text: t('Totals by products in bar chart'),
                  },
                ]}
              />
            )}
          </Field>
        </div>
      </li>
      <li className={styles['p-dashboardModal__item']}>
        <p className={styles['p-dashboardModal__item__ttl']}>{t('Date')}</p>
        <div className={styles['p-dashboardModal__item__body']}>
          <Field name="params.dateType">
            {({ input }) => (
              <SingleDropdown
                selectedOption={input.value}
                onChange={input.onChange}
                options={[
                  { value: 'BOOKING_DATE', text: t('Booking Date') },
                  {
                    value: 'PARTICIPATION_DATE',
                    text: t('Participation Date'),
                  },
                ]}
              />
            )}
          </Field>
        </div>
      </li>
      <li className={styles['p-dashboardModal__item']}>
        <p className={styles['p-dashboardModal__item__ttl']}>
          {t('Default Date Range')}
        </p>
        <div className={styles['p-dashboardModal__item__body']}>
          <Field name="params.dateRange">
            {({ input }) => (
              <SingleDropdown
                selectedOption={input.value}
                onChange={input.onChange}
                options={[
                  {
                    value: 'PREV_7_DAYS',
                    text: t('Last {{count}} days', { count: 7 }),
                  },
                  {
                    value: 'PREV_28_DAYS',
                    text: t('Last {{count}} days', { count: 28 }),
                  },
                  {
                    value: 'PREV_3_MONTHS',
                    text: t('Last {{count}} months', { count: 3 }),
                  },
                  { value: 'NEXT_7_DAYS', text: t('Next 7 days') },
                  { value: 'NEXT_28_DAYS', text: t('Next 28 days') },
                  { value: 'NEXT_3_MONTHS', text: t('Next 3 Months') },
                ]}
              />
            )}
          </Field>
        </div>
      </li>
      <li className={styles['p-dashboardModal__item']}>
        <p className={styles['p-dashboardModal__item__ttl']}>{t('Products')}</p>
        <div className={styles['p-dashboardModal__item__body']}>
          <Field name="params.productIds">
            {({ input }) => (
              <MultiDropdown
                selectedOptions={input.value}
                onChange={input.onChange}
                options={productOptions}
                searchable={true}
              />
            )}
          </Field>
        </div>
      </li>
      {values.gadgetType === 'reservation-report' &&
        values.params &&
        values.params.displayType === 'LINE_CHART' && (
          <li className={styles['p-dashboardModal__item']}>
            <p className={styles['p-dashboardModal__item__ttl']}>
              {t('Aggregation Type')}
            </p>
            <div className={styles['p-dashboardModal__item__body']}>
              <Field name="params.aggregationType">
                {({ input }) => (
                  <SingleDropdown
                    selectedOption={input.value}
                    onChange={input.onChange}
                    options={[
                      { value: 'DAILY', text: t('Daily') },
                      { value: 'WEEKLY', text: t('Weekly') },
                      { value: 'MONTHLY', text: t('Monthly') },
                    ]}
                  />
                )}
              </Field>
            </div>
          </li>
        )}
    </>
  );
};
