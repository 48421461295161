import * as React from 'react';
import clsx from 'clsx';
import moment, { Moment } from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import type { ReduxState } from 'client/reducers';
import { Select, DateTimeInput, FieldWrapper } from 'client/components/Form';
import { EquipmentSortModal } from 'client/components/Seat/EquipmentSortModal';
import { equipmentsSelector } from 'client/reducers/equipments';
import {
  setSortEquipments,
  setCandidateEquipments,
} from 'client/actions/equipments';
import thIcon from 'client/images/ic_th.svg';
import baseStyles from 'client/base.module.css';

import styles from './SeatAssignmentSelector.module.css';

interface Props {
  participationDate: string;
  onParticipationDateChange: (date: string) => void;
  selectedEquipmentId: string;
  onSelectedEquipmentIdChange: (equipmentId: string) => void;
  selectedStartTimeKey: string;
  onSelectedStartTimeKeyChange: (startTimeKey: string) => void;
}

export const SeatAssignmentSelector = ({
  participationDate,
  onParticipationDateChange,
  selectedEquipmentId,
  onSelectedEquipmentIdChange,
  selectedStartTimeKey,
  onSelectedStartTimeKeyChange,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const equipmentInstances = useSelector(equipmentInstancesSelector);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const equipments = useSelector(equipmentsSelector);

  const equipmentsLoading = useSelector(
    (state: ReduxState) => state.equipments.loading
  );

  const sortedEquipments = useSelector(
    (state: ReduxState) => state.equipments.sortedEquipments
  );
  const candidateEquipments = useSelector(
    (state: ReduxState) => state.equipments.candidateEquipments
  );

  const isClosedEquipmentInstance = (startTimeKey: string): boolean => {
    const equipmentInstance = equipmentInstances.find((instance) => {
      return (
        instance.equipment_id === selectedEquipmentId &&
        instance.start_time_key === startTimeKey &&
        instance.date === participationDate
      );
    });

    if (equipmentInstance) {
      return (
        equipmentInstance.per_channel_info?.all_channels_are_closed || false
      );
    }
    return false;
  };

  React.useEffect(() => {
    const options = (equipments ?? [])
      .filter((equipment) => {
        return (equipment.equipment_schedules ?? []).some((schedule) => {
          if (
            schedule.start_date_local &&
            schedule.start_date_local > participationDate
          ) {
            return false;
          }

          if (
            schedule.end_date_local &&
            schedule.end_date_local < participationDate
          ) {
            return false;
          }
          return true;
        });
      })
      .map((equipment) => ({
        text: equipment.title || '',
        value: equipment.id || '',
      }));

    const isSameCandidate = options.every((e) =>
      candidateEquipments.find((c) => e.value === c.value)
    );
    if (!isSameCandidate) {
      dispatch(setCandidateEquipments(options));
      dispatch(setSortEquipments(options.map((i) => i.value)));
    }
  }, [equipments, participationDate]);

  const startTimeOptions = React.useMemo(() => {
    const equipment = equipments.find(
      (equipment) => equipment.id === selectedEquipmentId
    );

    const schedule = (equipment?.equipment_schedules ?? []).find((schedule) => {
      if (
        schedule.start_date_local &&
        schedule.start_date_local > participationDate
      ) {
        return false;
      }
      if (
        schedule.end_date_local &&
        schedule.end_date_local < participationDate
      ) {
        return false;
      }
      return true;
    });

    return (schedule?.start_time_mappings ?? [])
      .sort((a, b) => {
        if (!b?.start_time_local) {
          return 0;
        }
        if (!a?.start_time_local) {
          return 0;
        }
        const startTimeLocalA = a.start_time_local;
        const startTimeLocalB = b.start_time_local;
        const [aHours, aMinutes] = startTimeLocalA.split(':').map(Number);
        const [bHours, bMinutes] = startTimeLocalB.split(':').map(Number);

        // 時刻を分単位に変換して比較
        return aHours * 60 + aMinutes - (bHours * 60 + bMinutes);
      })
      .map((mappings) => ({
        text: mappings.start_time_local || '',
        value: mappings.key || '',
      }));
  }, [participationDate, selectedEquipmentId]);

  React.useEffect(() => {
    if (startTimeOptions.length) {
      onSelectedStartTimeKeyChange(startTimeOptions[0].value);
    }
  }, [startTimeOptions]);

  return (
    <div className={clsx(styles['reservationsHeader'])}>
      <DateTimeInput
        label={t('Select Date')}
        value={moment(participationDate)}
        locale={locale}
        onChange={(date: Moment) => {
          onParticipationDateChange(date.format('YYYY-MM-DD'));
        }}
        showTodayButton={true}
        showWeekDay={true}
      />

      <Select
        label={t('Select a resource')}
        search
        maxWidth={400}
        value={selectedEquipmentId}
        options={sortedEquipments.map((id) => {
          const target = (equipments ?? []).find((e) => id === e.id);
          return {
            text: target?.title || '',
            value: target?.id || '',
          };
        })}
        onChange={(_, { value }) => {
          onSelectedEquipmentIdChange(value);
        }}
        loading={equipmentsLoading}
      />

      <EquipmentSortModal
        trigger={
          <a
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['square'],
              baseStyles['gray'],
              styles['equipmentSortModalIcon']
            )}
          >
            <img src={thIcon} />
          </a>
        }
      />

      <div style={{ width: '100%' }}>
        <FieldWrapper label={t('Select start time')}>
          <ul className={clsx(styles['tag'])}>
            {startTimeOptions.map((option) => (
              <li key={option.value}>
                <a
                  onClick={() => {
                    onSelectedStartTimeKeyChange(option.value);
                  }}
                  className={clsx(
                    option.value === selectedStartTimeKey
                      ? styles['is-selected']
                      : styles['is-unselected'],
                    isClosedEquipmentInstance(option.value)
                      ? styles['is-closed']
                      : styles['is-onsale']
                  )}
                >
                  {option.text}
                </a>
              </li>
            ))}
          </ul>
        </FieldWrapper>
      </div>
    </div>
  );
};
