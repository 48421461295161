import * as React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import type { GuideAccountShape } from 'client/libraries/util/accountShape';
import { ModalLoader } from 'client/components/ModalLoader';
import {
  getOperationHourText,
  hasNote,
  isOutOfOffice,
  isOverwroteBySingleDayOperatingHour,
} from 'client/libraries/util/accountShape';
import styles from 'client/components/GuideScheduleCalendar/GuideScheduleCalendar.module.css';
import type { GuideSingleDaySchedule } from 'shared/models/swagger';
import iMarkIcon from 'client/images/ic_imark.svg';

export type GuideScheduleCalendarEvent = {
  id: string;
  time: string;
  title: string;
  startDateTimeUtc: string;
  style?: Record<string, any>;
};
type Props = {
  onClose: (arg0: any) => void;
  events: null | GuideScheduleCalendarEvent[];
  timezone: string;
  locale: string;
  title: string;
  onRangeChange: (arg0: { start: Moment; end: Moment }) => void;
  onEventDayClick?: (arg0: string) => void;
  onDayClick?: (arg0: string) => void;
  loading?: boolean;
  guideAccountShape: GuideAccountShape | null;
  guideSingleDaySchedules: GuideSingleDaySchedule[];
};
export const GuideScheduleCalendarModal = ({
  onClose,
  events,
  timezone,
  locale,
  title,
  onRangeChange,
  onEventDayClick,
  onDayClick,
  loading,
  guideAccountShape,
  guideSingleDaySchedules,
}: Props) => {
  const rootEl = document.getElementById('root') as Element;
  return ReactDOM.createPortal(
    <div className={clsx(styles['c-modalCalendar'])}>
      <div className={clsx(styles['c-modalCalendar__frame'])}>
        <div className={clsx(styles['c-modalCalendar__frame__header'])}>
          <span className={clsx(styles['c-modalCalendar__frame__header__ttl'])}>
            {title}
          </span>
          <a
            className={clsx(styles['c-modalCalendar__frame__header__close'])}
            onClick={onClose}
          ></a>
        </div>
        <div className={clsx(styles['c-modalCalendar__frame__body'])}>
          <GuideScheduleCalendar
            events={events}
            timezone={timezone}
            locale={locale}
            onRangeChange={onRangeChange}
            onEventDayClick={onEventDayClick}
            onDayClick={onDayClick}
            loading={loading}
            guideAccountShape={guideAccountShape}
            guideSingleDaySchedules={guideSingleDaySchedules}
          />
        </div>
      </div>
      <div
        className={clsx(styles['c-modalCalendar__overlay'])}
        onClick={onClose}
      ></div>
    </div>,
    rootEl
  );
};
type GuideScheduleCalendarProps = {
  events: null | GuideScheduleCalendarEvent[];
  timezone: string;
  locale: string;
  onRangeChange: (arg0: { start: Moment; end: Moment }) => void;
  onEventDayClick?: (arg0: string) => void;
  onDayClick?: (arg0: string) => void;
  loading?: boolean;
  guideAccountShape: GuideAccountShape | null;
  guideSingleDaySchedules: GuideSingleDaySchedule[];
};
export const GuideScheduleCalendar = ({
  events,
  timezone,
  locale,
  onRangeChange,
  onEventDayClick,
  onDayClick,
  loading,
  guideAccountShape,
  guideSingleDaySchedules,
}: GuideScheduleCalendarProps) => {
  const { t } = useTranslation();
  const [movedMonth, setMovedMonth] = React.useState<number>(0);
  const numOfList = 3;

  const getDaysOfTheMonth = (
    month: number,
    year: number,
    timezone: string
  ): Moment[] => {
    const date = moment.tz(
      {
        year: year,
        month: month,
        day: 1,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      },
      timezone
    );
    let days = [];
    const before = moment(date);

    for (let i = before.day(); i > 0; i--) {
      days.push(moment(before.add('-1', 'day')));
    }

    days = days.reverse();

    while (date.month() === month) {
      days.push(moment(date));
      date.add('1', 'day');
    }

    while (date.day() !== 0) {
      days.push(moment(date));
      date.add('1', 'day');
    }

    return days;
  };

  React.useEffect(() => {
    const today = moment.tz(timezone).locale(locale);
    const target = today.add(`${movedMonth}`, 'month');
    onRangeChange({
      start: moment(target).startOf('month').add(-1, 'weeks'),
      end: moment(target).endOf('month').add(1, 'weeks'),
    });
  }, [movedMonth]);
  const today = moment.tz(timezone).locale(locale);
  const target = moment(today).add(`${movedMonth}`, 'month');
  const days = getDaysOfTheMonth(target.month(), target.year(), timezone);
  return (
    <>
      <div className={clsx(styles['c-modalCalendar__frame__body__top'])}>
        <div className={clsx(styles['c-modalCalendar__datepick'])}>
          <a
            className={clsx(
              styles['c-modalCalendar__datepick__btn'],
              styles['prev']
            )}
            onClick={() => {
              setMovedMonth(movedMonth - 1);
            }}
          ></a>
          <p className={clsx(styles['c-modalCalendar__datepick__main'])}>
            {target.format('YYYY/MM')}
          </p>
          <a
            className={clsx(
              styles['c-modalCalendar__datepick__btn'],
              styles['next']
            )}
            onClick={() => {
              setMovedMonth(movedMonth + 1);
            }}
          ></a>
        </div>
        <div className={clsx(styles['c-modalCalendar__info'])}>
          <a
            className={clsx(styles['c-modalCalendar__info__today'])}
            onClick={() => {
              setMovedMonth(0);
            }}
          >
            Today
          </a>
          <p className={clsx(styles['c-modalCalendar__info__timezone'])}>
            {t('Timezone: {{tz}}', {
              tz: timezone,
            })}
          </p>
        </div>
      </div>
      {loading || events === null ? (
        <ModalLoader />
      ) : (
        <div className={clsx(styles['c-modalCalendar__frame__body__main'])}>
          <div className={clsx(styles['c-calendar'])}>
            <table>
              <tbody>
                <tr>
                  <th>{t('Sun')}</th>
                  <th>{t('Mon')}</th>
                  <th>{t('Tue')}</th>
                  <th>{t('Wed')}</th>
                  <th>{t('Thu')}</th>
                  <th>{t('Fri')}</th>
                  <th>{t('Sat')}</th>
                </tr>

                {_.chunk(days, 7).map((week, widx) => {
                  return (
                    <tr key={widx}>
                      {week.map((day, didx) => {
                        const eventOfTheDay = events.filter((e) => {
                          const startTime = moment(e.startDateTimeUtc).tz(
                            timezone
                          );

                          if (
                            day.year() === startTime.year() &&
                            day.month() === startTime.month() &&
                            day.date() === startTime.date()
                          ) {
                            return true;
                          }

                          return false;
                        });
                        return (
                          <td
                            key={didx}
                            className={clsx(
                              target.month() === day.month()
                                ? ''
                                : styles['disabled'],
                              isOutOfOffice(
                                day.format('YYYY-MM-DD'),
                                guideAccountShape,
                                guideSingleDaySchedules
                              )
                                ? styles['out-of-office']
                                : ''
                            )}
                            onClick={() => {
                              if (onDayClick) {
                                onDayClick(day.format('YYYY-MM-DD'));
                              }
                            }}
                          >
                            <p
                              className={clsx(
                                styles['c-calendar__day'],
                                day.year() === today.year() &&
                                  day.month() === today.month() &&
                                  day.date() === today.date()
                                  ? styles['today']
                                  : ''
                              )}
                              style={{
                                margin: '0 auto',
                              }}
                            >
                              {day.date()}
                            </p>
                            <ul className={clsx(styles['c-calendar__scheds'])}>
                              <li
                                className={clsx(
                                  isOutOfOffice(
                                    day.format('YYYY-MM-DD'),
                                    guideAccountShape,
                                    guideSingleDaySchedules
                                  )
                                    ? styles['out-of-office']
                                    : styles['in-of-office'],
                                  isOverwroteBySingleDayOperatingHour(
                                    day.format('YYYY-MM-DD'),
                                    guideAccountShape,
                                    guideSingleDaySchedules
                                  )
                                    ? styles['single-day']
                                    : styles['normal-day']
                                )}
                              >
                                {' '}
                                <div>
                                  {getOperationHourText(
                                    day.format('YYYY-MM-DD'),
                                    guideAccountShape,
                                    guideSingleDaySchedules
                                  )}
                                  {hasNote(
                                    day.format('YYYY-MM-DD'),
                                    guideAccountShape,
                                    guideSingleDaySchedules
                                  ) && <img src={iMarkIcon} />}
                                </div>
                              </li>
                              <li
                                onClick={(clickEvent) => {
                                  clickEvent.stopPropagation();
                                  onEventDayClick &&
                                    onEventDayClick(day.format('YYYY-MM-DD'));
                                }}
                              >
                                <ul>
                                  {eventOfTheDay
                                    .filter((e, sid) => {
                                      return sid < numOfList;
                                    })
                                    .map((e, sidx) => {
                                      return (
                                        <li key={sidx}>
                                          <p>{e.time}</p>
                                          <b>{e.title}</b>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </li>
                            </ul>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};
