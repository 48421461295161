import { combineReducers } from 'redux';

import {
  BATCH_FETCH_RESERVATION_STATS_BY_DATE_SUCCESS,
  CANCEL_RESERVATION_FAILURE,
  CANCEL_RESERVATION_WITH_FEE_FAILURE,
  FETCH_RESERVATIONS_FAILURE,
  FETCH_RESERVATIONS_REQUEST,
  FETCH_RESERVATIONS_SUCCESS,
  FETCH_RESERVATION_STATS_FAILURE,
  FETCH_RESERVATION_STATS_REQUEST,
  FETCH_RESERVATION_STATS_SUCCESS,
  FETCH_RESERVATION_STATS_BY_DATE_SUCCESS,
  FETCH_RESERVATIONS_CSV_FAILURE,
  FETCH_RESERVATIONS_CSV_REQUEST,
  FETCH_RESERVATIONS_CSV_SUCCESS,
  FETCH_RESERVATION_BY_ID_SUCCESS,
  CHECKIN_RESERVATION_FAILURE,
  CHECKIN_RESERVATION_SUCCESS,
  CHECKIN_RESERVATION_REQUEST,
  UNDO_CHECKIN_RESERVATION_FAILURE,
  UNDO_CHECKIN_RESERVATION_SUCCESS,
  UNDO_CHECKIN_RESERVATION_REQUEST,
  CANCEL_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_WITH_FEE_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  CONFIRM_RESERVATION_FAILURE,
  CONFIRM_RESERVATION_SUCCESS,
  STANDBY_RESERVATION_FAILURE,
  STANDBY_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_REQUEST,
  CANCEL_RESERVATION_WITH_FEE_REQUEST,
  CONFIRM_RESERVATION_REQUEST,
  STANDBY_RESERVATION_REQUEST,
  FETCH_RESERVATION_BY_ID_FAILURE,
  FETCH_RESERVATION_BY_ID_REQUEST,
  LOGOUT_SUCCESS,
  CREATE_RESERVATION_REQUEST,
  CREATE_RESERVATION_SUCCESS,
  CREATE_RESERVATION_FAILURE,
  DECLINE_RESERVATION_REQUEST,
  DECLINE_RESERVATION_FAILURE,
  DECLINE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_REQUEST,
  UPDATE_RESERVATION_FAILURE,
  FARE_ADJUST_RESERVATION_FAILURE,
  FARE_ADJUST_RESERVATION_REQUEST,
  FARE_ADJUST_RESERVATION_SUCCESS,
  SEND_PAYMENT_EMAIL_FAILURE,
  SEND_PAYMENT_EMAIL_REQUEST,
  SEND_PAYMENT_EMAIL_SUCCESS,
  FETCH_RESERVATION_REPORT_DATA_FAILURE,
  FETCH_RESERVATION_REPORT_DATA_REQUEST,
  FETCH_RESERVATION_REPORT_DATA_SUCCESS,
  DELETE_WAIVER_SUCCESS,
  FETCH_RESERVATIONS_BY_ID_REQUEST,
  FETCH_RESERVATIONS_BY_ID_FAILURE,
  FETCH_RESERVATIONS_BY_ID_SUCCESS,
  UNDO_REDEEM_RESERVATION_COUPON_REQUEST,
  UNDO_REDEEM_RESERVATION_COUPON_SUCCESS,
  UNDO_REDEEM_RESERVATION_COUPON_FAILURE,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_REQUEST,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_SUCCESS,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_FAILURE,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_REQUEST,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_SUCCESS,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_FAILURE,
  BULK_CANCEL_RESERVATION_REQUEST,
  BULK_CANCEL_RESERVATION_SUCCESS,
  BULK_CANCEL_RESERVATION_FAILURE,
  BULK_CANCEL_RESERVATION_TIME_OUT,
  MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_FAILURE,
  MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_REQUEST,
  MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_SUCCESS,
  CLEAR_CREATE_RESERVATION_ERROR,
  BULK_DECLINE_RESERVATION_REQUEST,
  BULK_DECLINE_RESERVATION_SUCCESS,
  BULK_DECLINE_RESERVATION_FAILURE,
  BULK_DECLINE_RESERVATION_TIME_OUT,
  CLEAR_LAST_CREATED_RESERVATION_ID,
  UNDO_EQUIPMENT_TICKET_PRINT_REQUEST,
  UNDO_EQUIPMENT_TICKET_PRINT_SUCCESS,
  UNDO_EQUIPMENT_TICKET_PRINT_FAILURE,
} from 'client/constants/ActionTypes';
import type {
  CreateReservationResponse,
  GetReservationStatsResponse,
  ListReservationsResponse,
  Reservation,
  GetReservationReportDataResponse,
} from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_RESERVATION_BY_ID_REQUEST:
    case FETCH_RESERVATIONS_REQUEST:
    case FETCH_RESERVATIONS_BY_ID_REQUEST:
    case CHECKIN_RESERVATION_REQUEST:
    case UNDO_CHECKIN_RESERVATION_REQUEST:
    case CANCEL_RESERVATION_REQUEST:
    case CANCEL_RESERVATION_WITH_FEE_REQUEST:
    case CONFIRM_RESERVATION_REQUEST:
    case CREATE_RESERVATION_REQUEST:
    case STANDBY_RESERVATION_REQUEST:
    case DECLINE_RESERVATION_REQUEST:
    case UPDATE_RESERVATION_REQUEST:
    case FETCH_RESERVATION_REPORT_DATA_REQUEST:
    case UNDO_REDEEM_RESERVATION_COUPON_REQUEST:
    case UNDO_REDEEM_RESERVATION_STAMP_RALLY_REQUEST:
    case UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_REQUEST:
    case BULK_CANCEL_RESERVATION_REQUEST:
    case MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_REQUEST:
    case BULK_DECLINE_RESERVATION_REQUEST:
    case UNDO_EQUIPMENT_TICKET_PRINT_REQUEST:
      return true;

    case FETCH_RESERVATION_BY_ID_SUCCESS:
    case FETCH_RESERVATIONS_SUCCESS:
    case FETCH_RESERVATIONS_BY_ID_SUCCESS:
    case CHECKIN_RESERVATION_SUCCESS:
    case UNDO_CHECKIN_RESERVATION_SUCCESS:
    case CANCEL_RESERVATION_SUCCESS:
    case CANCEL_RESERVATION_WITH_FEE_SUCCESS:
    case CONFIRM_RESERVATION_SUCCESS:
    case CREATE_RESERVATION_SUCCESS:
    case STANDBY_RESERVATION_SUCCESS:
    case DECLINE_RESERVATION_SUCCESS:
    case UPDATE_RESERVATION_SUCCESS:
    case FARE_ADJUST_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_BY_ID_FAILURE:
    case FETCH_RESERVATIONS_FAILURE:
    case FETCH_RESERVATIONS_BY_ID_FAILURE:
    case CHECKIN_RESERVATION_FAILURE:
    case UNDO_CHECKIN_RESERVATION_FAILURE:
    case CANCEL_RESERVATION_FAILURE:
    case CANCEL_RESERVATION_WITH_FEE_FAILURE:
    case CONFIRM_RESERVATION_FAILURE:
    case CREATE_RESERVATION_FAILURE:
    case STANDBY_RESERVATION_FAILURE:
    case DECLINE_RESERVATION_FAILURE:
    case UPDATE_RESERVATION_FAILURE:
    case FETCH_RESERVATION_REPORT_DATA_FAILURE:
    case FETCH_RESERVATION_REPORT_DATA_SUCCESS:
    case UNDO_REDEEM_RESERVATION_COUPON_SUCCESS:
    case UNDO_REDEEM_RESERVATION_COUPON_FAILURE:
    case UNDO_REDEEM_RESERVATION_STAMP_RALLY_SUCCESS:
    case UNDO_REDEEM_RESERVATION_STAMP_RALLY_FAILURE:
    case UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_SUCCESS:
    case UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_FAILURE:
    case BULK_CANCEL_RESERVATION_SUCCESS:
    case BULK_CANCEL_RESERVATION_FAILURE:
    case MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_SUCCESS:
    case MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_FAILURE:
    case BULK_DECLINE_RESERVATION_SUCCESS:
    case BULK_DECLINE_RESERVATION_FAILURE:
    case UNDO_EQUIPMENT_TICKET_PRINT_SUCCESS:
    case UNDO_EQUIPMENT_TICKET_PRINT_FAILURE:
      return false;

    default:
      return state;
  }
};

const creating = (state = false, action: any) => {
  switch (action.type) {
    case CREATE_RESERVATION_REQUEST:
      return true;
    case CREATE_RESERVATION_SUCCESS:
    case CREATE_RESERVATION_FAILURE:
      return false;
    default:
      return state;
  }
};

const error = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_RESERVATION_BY_ID_FAILURE:
    case FETCH_RESERVATIONS_FAILURE:
    case FETCH_RESERVATIONS_BY_ID_FAILURE:
    case CHECKIN_RESERVATION_FAILURE:
    case UNDO_CHECKIN_RESERVATION_FAILURE:
    case CANCEL_RESERVATION_FAILURE:
    case CANCEL_RESERVATION_WITH_FEE_FAILURE:
    case CONFIRM_RESERVATION_FAILURE:
    case CREATE_RESERVATION_FAILURE:
    case STANDBY_RESERVATION_FAILURE:
    case DECLINE_RESERVATION_FAILURE:
    case FARE_ADJUST_RESERVATION_FAILURE:
    case FETCH_RESERVATIONS_CSV_FAILURE:
    case SEND_PAYMENT_EMAIL_FAILURE:
    case FETCH_RESERVATION_REPORT_DATA_FAILURE:
    case UNDO_REDEEM_RESERVATION_COUPON_FAILURE:
    case UNDO_REDEEM_RESERVATION_STAMP_RALLY_FAILURE:
    case UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_FAILURE:
    case MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_FAILURE:
    case UNDO_EQUIPMENT_TICKET_PRINT_FAILURE:
      return action.error;

    case FETCH_RESERVATION_BY_ID_REQUEST:
    case FETCH_RESERVATIONS_REQUEST:
    case FETCH_RESERVATIONS_BY_ID_REQUEST:
    case CHECKIN_RESERVATION_REQUEST:
    case UNDO_CHECKIN_RESERVATION_REQUEST:
    case CANCEL_RESERVATION_REQUEST:
    case CANCEL_RESERVATION_WITH_FEE_REQUEST:
    case CONFIRM_RESERVATION_REQUEST:
    case CREATE_RESERVATION_REQUEST:
    case STANDBY_RESERVATION_REQUEST:
    case DECLINE_RESERVATION_REQUEST:
    case FARE_ADJUST_RESERVATION_REQUEST:
    case FETCH_RESERVATIONS_CSV_REQUEST:
    case SEND_PAYMENT_EMAIL_REQUEST:
    case FETCH_RESERVATION_REPORT_DATA_REQUEST:
    case UNDO_REDEEM_RESERVATION_COUPON_REQUEST:
    case UNDO_REDEEM_RESERVATION_STAMP_RALLY_REQUEST:
    case UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_REQUEST:
    case MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_REQUEST:
    case UNDO_EQUIPMENT_TICKET_PRINT_REQUEST:
      return '';

    default:
      return state;
  }
};

const createError = (state = '', action: any) => {
  switch (action.type) {
    case CREATE_RESERVATION_FAILURE:
      return action.error || 'ERROR';

    case CREATE_RESERVATION_REQUEST:
    case CLEAR_CREATE_RESERVATION_ERROR:
      return '';

    default:
      return state;
  }
};

const bulkCancelError = (state = '', action: any) => {
  switch (action.type) {
    case BULK_CANCEL_RESERVATION_FAILURE:
      return action.error;

    case BULK_CANCEL_RESERVATION_REQUEST:
    case BULK_CANCEL_RESERVATION_TIME_OUT:
      return '';

    default:
      return state;
  }
};

const bulkDeclineError = (state = '', action: any) => {
  switch (action.type) {
    case BULK_DECLINE_RESERVATION_FAILURE:
      return action.error;

    case BULK_DECLINE_RESERVATION_REQUEST:
    case BULK_DECLINE_RESERVATION_TIME_OUT:
      return '';

    default:
      return state;
  }
};

const fareAdjustLoading = (state = false, action: any) => {
  switch (action.type) {
    case FARE_ADJUST_RESERVATION_REQUEST:
      return true;

    case FARE_ADJUST_RESERVATION_SUCCESS:
    case FARE_ADJUST_RESERVATION_FAILURE:
      return false;

    default:
      return state;
  }
};

const fareAdjustStatus = (state = '', action: any) => {
  switch (action.type) {
    case FARE_ADJUST_RESERVATION_REQUEST:
      return 'REQUESTED';

    case FARE_ADJUST_RESERVATION_SUCCESS:
      return 'SUCCEEDED';

    case FARE_ADJUST_RESERVATION_FAILURE:
      return 'FAILED';

    default:
      return state;
  }
};

const lastCreatedReservationID = (state = '', action: any) => {
  switch (action.type) {
    case CREATE_RESERVATION_SUCCESS: {
      const res = action.response as any as CreateReservationResponse;
      return res.id;
    }
    case CREATE_RESERVATION_REQUEST:
    case CLEAR_LAST_CREATED_RESERVATION_ID:
      return '';

    default:
      return state;
  }
};

const byID = (state: Record<string, Reservation> = {}, action: any) => {
  switch (action.type) {
    case FETCH_RESERVATION_BY_ID_SUCCESS:
    case CHECKIN_RESERVATION_SUCCESS:
    case UNDO_CHECKIN_RESERVATION_SUCCESS:
    case CANCEL_RESERVATION_SUCCESS:
    case CANCEL_RESERVATION_WITH_FEE_SUCCESS:
    case CONFIRM_RESERVATION_SUCCESS:
    case UPDATE_RESERVATION_SUCCESS:
    case STANDBY_RESERVATION_SUCCESS:
    case DECLINE_RESERVATION_SUCCESS:
    case FARE_ADJUST_RESERVATION_SUCCESS:
    case MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_SUCCESS:
    case UNDO_EQUIPMENT_TICKET_PRINT_SUCCESS:
      return { ...state, [action.response.id]: action.response };

    case FETCH_RESERVATIONS_BY_ID_SUCCESS: {
      const newState = {
        ...state,
      };
      for (const reservation of action.responses) {
        newState[reservation.id] = reservation;
      }

      return newState;
    }

    case DELETE_WAIVER_SUCCESS: {
      const reservation = state[action.reservationId];
      if (reservation) {
        reservation.waiver_info = {
          waiver_status: 'UNSIGNED',
        };

        return { ...state, [action.reservationId]: reservation };
      }

      return state;
    }

    default:
      return state;
  }
};

const ids = (state: string[] = [], action: any) => {
  switch (action.type) {
    case FETCH_RESERVATION_BY_ID_SUCCESS:
      return [...new Set([...state, action.response.id])];

    case FETCH_RESERVATIONS_BY_ID_SUCCESS:
      return [
        ...new Set([...state, action.responses.map((r: Reservation) => r.id)]),
      ];

    default:
      return state;
  }
};

const summaries = (state: Reservation[] = [], action: any) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_SUCCESS: {
      const resp = action.response as any as ListReservationsResponse;
      return resp.reservations || [];
    }
    case CHECKIN_RESERVATION_SUCCESS:
    case UNDO_CHECKIN_RESERVATION_SUCCESS:
    case CANCEL_RESERVATION_SUCCESS:
    case CANCEL_RESERVATION_WITH_FEE_SUCCESS:
    case CONFIRM_RESERVATION_SUCCESS:
    case UPDATE_RESERVATION_SUCCESS:
    case STANDBY_RESERVATION_SUCCESS:
    case DECLINE_RESERVATION_SUCCESS:
    case FARE_ADJUST_RESERVATION_SUCCESS:
    case MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_SUCCESS:
    case UNDO_EQUIPMENT_TICKET_PRINT_SUCCESS: {
      const newState = state.map((reservation) => {
        if (reservation.id === action.response.id) {
          return {
            ...action.response,
          };
        }
        return reservation;
      });
      return newState;
    }
    default:
      return state;
  }
};

const csvLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_CSV_REQUEST:
      return true;

    case FETCH_RESERVATIONS_CSV_SUCCESS:
    case FETCH_RESERVATIONS_CSV_FAILURE:
      return false;

    default:
      return state;
  }
};

const statsLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_RESERVATION_STATS_REQUEST:
      return true;

    case FETCH_RESERVATION_STATS_SUCCESS:
    case FETCH_RESERVATION_STATS_FAILURE:
      return false;

    default:
      return state;
  }
};

const totalConfirmedGrossAmounts = (state: string[] = [], action: any) => {
  switch (action.type) {
    case FETCH_RESERVATION_STATS_REQUEST:
      return [];

    case FETCH_RESERVATION_STATS_SUCCESS: {
      const resp = action.response as any as GetReservationStatsResponse;
      return resp.confirmed_total_amounts_gross || [];
    }
    default:
      return state;
  }
};

const totalConfirmedNetAmounts = (state: string[] = [], action: any) => {
  switch (action.type) {
    case FETCH_RESERVATION_STATS_REQUEST:
      return [];

    case FETCH_RESERVATION_STATS_SUCCESS: {
      const resp = action.response as any as GetReservationStatsResponse;
      return resp.confirmed_total_amounts_net || [];
    }
    default:
      return state;
  }
};

const totalConfirmedPaxByDate = (
  state = {},
  action: any
): Record<string, number> => {
  switch (action.type) {
    case FETCH_RESERVATION_STATS_BY_DATE_SUCCESS: {
      const paxByDate: Record<string, number> = {};
      (action.response as any)?.reservation_stats?.forEach((statItem: any) => {
        if (statItem.date) {
          paxByDate[statItem.date] = statItem.confirmed_pax_count ?? 0;
        }
      });

      return paxByDate;
    }

    case BATCH_FETCH_RESERVATION_STATS_BY_DATE_SUCCESS:
      return {
        ...state,
        [action.date]: (action.response as any).confirmed_pax_count || 0,
      };

    default:
      return state;
  }
};

const paymentEmailSending = (state = false, action: any) => {
  switch (action.type) {
    case SEND_PAYMENT_EMAIL_REQUEST:
      return true;

    case SEND_PAYMENT_EMAIL_SUCCESS:
    case SEND_PAYMENT_EMAIL_FAILURE:
      return false;

    default:
      return state;
  }
};

const lastPaymentEmailSendingStatus = (state = '', action: any) => {
  switch (action.type) {
    case SEND_PAYMENT_EMAIL_REQUEST:
      return 'REQUESTED';

    case SEND_PAYMENT_EMAIL_SUCCESS:
      return 'SUCCEEDED';

    case SEND_PAYMENT_EMAIL_FAILURE:
      return 'FAILED';

    default:
      return state;
  }
};

const reportData = (state: any[] = [], action: any) => {
  switch (action.type) {
    case FETCH_RESERVATION_REPORT_DATA_SUCCESS: {
      const resp = action.response as any as GetReservationReportDataResponse;
      return resp.sets || [];
    }
    default:
      return state;
  }
};

const fetchReservationReportDataStatus = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_RESERVATION_REPORT_DATA_REQUEST:
      return 'REQUESTED';

    case FETCH_RESERVATION_REPORT_DATA_SUCCESS:
      return 'SUCCEEDED';

    case FETCH_RESERVATION_REPORT_DATA_FAILURE:
      return 'FAILED';

    default:
      return state;
  }
};

type State = {
  error: string;
  createError: string;
  bulkCancelError: string;
  bulkDeclineError: string;
  byID: Record<string, Reservation>;
  lastCreatedReservationID: string;
  ids: string[];
  loading: boolean;
  creating: boolean;
  summaries: Reservation[];
  fareAdjustLoading: boolean;
  fareAdjustStatus: string;
  csvLoading: boolean;
  statsLoading: boolean;
  totalConfirmedGrossAmounts: string[];
  totalConfirmedNetAmounts: string[];
  totalConfirmedPaxByDate: { [key: string]: number };
  paymentEmailSending: boolean;
  lastPaymentEmailSendingStatus: string;
  reportData: any[];
  fetchReservationReportDataStatus: string;
};

const reducer = combineReducers<State>({
  error,
  createError,
  bulkCancelError,
  bulkDeclineError,
  byID,
  lastCreatedReservationID,
  ids,
  loading,
  creating,
  summaries,
  fareAdjustLoading,
  fareAdjustStatus,
  csvLoading,
  statsLoading,
  totalConfirmedGrossAmounts,
  totalConfirmedNetAmounts,
  totalConfirmedPaxByDate,
  paymentEmailSending,
  lastPaymentEmailSendingStatus,
  reportData,
  fetchReservationReportDataStatus,
});
export const reservations = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
