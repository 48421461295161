import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { config } from 'client/config';
import { getReviewAttributionText } from 'client/libraries/util/getReviewAttributionText';
import { summariesSelector } from 'client/reducers/products';
import {
  convertReviewRatingToNumber,
  getReviewStatusBadgeColor,
  getReviewStatusText,
} from 'client/libraries/util/review';
import { Stars } from 'client/components/Stars/Stars';
import type { Review } from 'shared/models/swagger';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';
import { ProductReviewBody } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Review/ProductReviewBody';
import { ProductReviewSupplierComments } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Review/ProductReviewSupplierComments';
import { ReviewEditModal } from 'client/pages/ProductDetails/ProductReviewsPane/ReviewEditModal';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import { ImageSlider } from 'client/pages/ProductDetails/ProductReviewsPane/ImageSlider';
import { Badge } from 'client/components/v3/Common/Badge';
import { Button } from 'client/components/v3/Common/Button';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { Tooltip } from 'client/components/v3/Common/Tooltip';

interface Props {
  review: Review;
  onReviewUpdate?: () => void;
  showProductName?: boolean;
  showStatus?: boolean;
  showEditButton?: boolean;
  showReservationDetailsLink?: boolean;
}
export const ReviewCard = ({
  review,
  onReviewUpdate,
  showProductName,
  showStatus,
  showEditButton,
  showReservationDetailsLink,
}: Props) => {
  const { t } = useTranslation();
  const productSummaries = useSelector(summariesSelector);
  const internalProductName = productSummaries.find(
    (p) => p.id == review.product_id
  )?.internal_product_name;
  const ratingAsNumber = convertReviewRatingToNumber(review.rating);
  const copyReviewUrl =
    config.nutmegExternalApiBaseUrl + '/reviews/' + review.id;
  const [copySuccess, setCopySuccess] = React.useState<boolean>(false);
  React.useEffect(() => {
    const copySuccessFlagTimer = setTimeout(() => {
      setCopySuccess(false);
    }, 10 * 1000);
    return () => {
      clearTimeout(copySuccessFlagTimer);
    };
  }, [copySuccess]);

  return (
    <li>
      {/* NEW COMPONENT */}
      <div className={styles['p-reviewCard']}>
        <div className={styles['p-reviewCard__header']}>
          {(showProductName ||
            showStatus ||
            showEditButton ||
            showReservationDetailsLink) && (
            <>
              {showProductName && (
                <div className={styles['p-reviewCard__header__productName']}>
                  {internalProductName ?? review.product_name}
                </div>
              )}
              <div className={styles['p-reviewCard__header__contents']}>
                <div className={styles['p-reviewCard__header__left']}>
                  {showStatus && (
                    <Badge
                      label={getReviewStatusText(review.status, t)}
                      color={getReviewStatusBadgeColor(review.status)}
                      size="lg"
                    />
                  )}
                  <span>
                    {t('Participation Date: {{participationDate}}', {
                      participationDate:
                        review.participation_date_time_local.substring(0, 7),
                    })}
                  </span>
                  <span>{getReviewAttributionText(review.attribution, t)}</span>
                </div>
                <div className={styles['p-reviewCard__header__right']}>
                  {(showReservationDetailsLink || showEditButton) && (
                    <>
                      {showReservationDetailsLink && (
                        <HashLink
                          to={`/reservations/${review.reservation_id}?t=reviews#detail`}
                          className={clsx(
                            baseStyles['base-btn'],
                            baseStyles['icon']
                          )}
                          data-text={t('Detail')}
                        >
                          <img src={reservationDetailIcon} />
                        </HashLink>
                      )}
                      {showEditButton && (
                        <ReviewEditModal
                          review={review}
                          trigger={
                            <Button
                              text={t('Edit')}
                              uiType="bg"
                              size="md"
                              color="white"
                              iconBeforeText={
                                <i className="c-icon-outline-general-edit-05"></i>
                              }
                            />
                          }
                          onReviewUpdate={onReviewUpdate}
                        />
                      )}
                      {review.status == 'PUBLISHED' && (
                        <Tooltip
                          text={t(
                            'Copy link to embed review in Custom pages in Homepage feature'
                          )}
                          width={200}
                        >
                          <Button
                            uiType="bg"
                            size="icon"
                            color="white"
                            iconBeforeText={
                              <i className="c-icon-outline-general-copy-02"></i>
                            }
                            onClick={() => {
                              navigator.clipboard.writeText(copyReviewUrl);
                              setCopySuccess(true);
                            }}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className={styles['p-reviewCard__body']}>
          <div className={styles['p-reviewCard__body__star']}>
            <Stars rating={ratingAsNumber} />
            <p>{ratingAsNumber}</p>
          </div>
          <p className={styles['p-reviewCard__body__ttl']}>{review.title}</p>
          <div className={styles['p-reviewCard__body__desc']}>
            <ProductReviewBody review={review} />
          </div>
          {/* TODO: originally this is <ul> but we might not need to modify the component */}
          <div className={styles['p-reviewCard__body__photo']}>
            {review.media_items != null && review.media_items.length > 0 && (
              <ImageSlider
                imageUrls={
                  review.media_items?.map((mediaItem) => mediaItem.url) ?? []
                }
              />
            )}
          </div>
          <div className={styles['p-reviewCard__body__user']}>
            <div className={styles['p-reviewCard__body__user__pic']}>
              <p>{review.guest_nickname.substring(0, 1)}</p>
            </div>
            <div className={styles['p-reviewCard__body__user__txt']}>
              <p>{review.guest_nickname}</p>
              <p>
                {t('Posted Date: {{postedDate}}', {
                  postedDate: moment(review.written_date_time_utc).format(
                    'YYYY/MM/DD'
                  ),
                })}
              </p>
            </div>
          </div>
          <ProductReviewSupplierComments review={review} />
        </div>
      </div>
      <Snackbar text={t('Copied')} color="success" shouldShow={copySuccess} />
    </li>
  );
};
