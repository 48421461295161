import { FormValues } from 'client/libraries/util/resourceManager';
import type { DispatchCrewMember } from 'shared/models/swagger';

export const getInitialValues = (
  resource: DispatchCrewMember | null
): FormValues => {
  return {
    availabilitySchedules: (resource?.availability?.schedules ?? []).map(
      (schedule) => ({
        startDateLocal: schedule.start_date_local ?? '',
        endDateLocal: schedule.end_date_local ?? '',
        productCapacities: (schedule.product_capacities ?? []).map(
          (product_capacities) => ({
            productId: product_capacities.product_id,
            capacity: product_capacities.capacity,
          })
        ),
      })
    ),
  };
};

export const convertFormValuesToDispatchResources = (
  values: FormValues,
  resourceKey: string | null,
  resources: DispatchCrewMember[]
): DispatchCrewMember[] => {
  const availability = {
    schedules: values.availabilitySchedules?.map((schedule) => ({
      start_date_local: schedule.startDateLocal,
      end_date_local: schedule.endDateLocal,
      product_capacities: schedule.productCapacities?.map(
        (productCapacity) => ({
          product_id: productCapacity.productId,
          capacity: Number(productCapacity.capacity),
        })
      ),
    })),
  };

  const resource = resources.find((resource) => resource.key === resourceKey);

  if (resource) {
    resource.availability = availability;
  }

  return resources;
};
