import * as React from 'react';
import ColorPicker from 'rc-color-picker';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import baseStyles from 'client/v3-base.module.css';

import { FontPicker } from '../TextStyleEditor/FontPicker';

export interface Props {
  name: string;
  expanderLabel: string;
  useFontPicker?: boolean;
}

export const ButtonStyleEditor = ({
  name,
  expanderLabel,
  useFontPicker = true,
}: Props) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);

  return (
    <div style={{ width: '100%' }}>
      <a
        className={baseStyles['base-link']}
        onClick={() => setExpanded(!expanded)}
      >
        {`${expanderLabel} ${expanded ? '▲' : '▼'}`}
      </a>
      {expanded && (
        <div style={{ display: 'flex', marginTop: '0.5em' }}>
          {useFontPicker && (
            <div style={{ width: '50%' }}>
              <Field name={`${name}.fontFamily`}>
                {({ input }) => (
                  <FontPicker
                    label={t('Font')}
                    value={input.value}
                    onChange={(newValue) => input.onChange(newValue)}
                  />
                )}
              </Field>
            </div>
          )}
          <div style={{ marginLeft: '8px' }}>
            <div style={{ marginBottom: '4px' }}>{t('Background Color')}</div>
            <Field name={`${name}.color`}>
              {({ input }) => (
                <ColorPicker
                  defaultColor="#008ec9"
                  color={input.value}
                  onChange={({ color }: any) => {
                    input.onChange(color);
                  }}
                />
              )}
            </Field>
          </div>
          <div style={{ marginLeft: '8px' }}>
            <div style={{ marginBottom: '4px' }}>{t('Text Color')}</div>
            <Field name={`${name}.textColor`}>
              {({ input }) => (
                <ColorPicker
                  defaultColor="#FFFFFF"
                  color={input.value}
                  onChange={({ color }: any) => {
                    input.onChange(color);
                  }}
                />
              )}
            </Field>
          </div>
        </div>
      )}
    </div>
  );
};
