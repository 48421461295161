import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ModalLoader } from 'client/components/ModalLoader';
import {
  getDateFilterPresetText,
  SearchInquiriesRequest,
} from 'client/libraries/util/searchInquiries';
import type { ReduxState } from 'client/reducers';

import styles from './InquirySearchFiltersDisplayBox.module.css';

type Props = {
  searchFilters: SearchInquiriesRequest;
};
export const InquirySearchFiltersDisplayBox = ({ searchFilters }: Props) => {
  const { t } = useTranslation();
  const loading = useSelector((state: ReduxState) => state.products.loading);
  const filters: {
    typeText: string;
    filterValue: string;
  }[] = [];

  if (searchFilters.dateFilterPreset) {
    const preset = searchFilters.dateFilterPreset;
    filters.push({
      typeText: t('Date Preset'),
      filterValue: getDateFilterPresetText(preset, t),
    });
  }

  if (
    searchFilters.participationDateFrom ||
    searchFilters.participationDateTo
  ) {
    filters.push({
      typeText: t('Participation Date'),
      filterValue: `${searchFilters.participationDateFrom ?? ''} ~ ${
        searchFilters.participationDateTo
      }`,
    });
  }

  if (searchFilters.updatedDateFrom || searchFilters.updatedDateTo) {
    filters.push({
      typeText: t('Last Updated'),
      filterValue: `${searchFilters.updatedDateFrom ?? ''} ~ ${
        searchFilters.updatedDateTo
      }`,
    });
  }

  if (searchFilters.customerGivenName) {
    filters.push({
      typeText: t('Given Name'),
      filterValue: searchFilters.customerGivenName,
    });
  }
  if (searchFilters.customerFamilyName) {
    filters.push({
      typeText: t('Family Name'),
      filterValue: searchFilters.customerFamilyName,
    });
  }

  if ((searchFilters.communicationTypes ?? []).length > 0) {
    const communicationTypes: string[] = [];
    if (searchFilters.communicationTypes.includes('EMAIL')) {
      communicationTypes.push(t('Email'));
    }
    if (searchFilters.communicationTypes.includes('SMS')) {
      communicationTypes.push(t('SMS'));
    }

    filters.push({
      typeText: t('Communication Types'),
      filterValue: communicationTypes.join(', '),
    });
  }

  if (searchFilters.contact) {
    filters.push({
      typeText: t('Email/Phone'),
      filterValue: searchFilters.contact,
    });
  }

  if (searchFilters.tag) {
    filters.push({
      typeText: t('Tag'),
      filterValue: searchFilters.tag,
    });
  }

  if ((searchFilters.categories ?? []).length > 0) {
    filters.push({
      typeText: t('Categories'),
      filterValue: searchFilters.categories.join(', '),
    });
  }

  return (
    <div className={styles['search-filter-display-box']}>
      <b>{t('Search Conditions')}</b>

      {loading ? (
        <ModalLoader />
      ) : (
        <ul>
          {filters.map((f) => (
            <li key={f.typeText}>{`${f.typeText}: ${f.filterValue}`}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
