import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './Accordion.module.css';

type AccordionProps = {
  children?: React.ReactNode;
};
export const Accordion = ({ children }: AccordionProps) => {
  return <ul className={styles['c-accordion']}>{children}</ul>;
};
type AccordionItemChildRenderProps = {
  open: boolean;
};
export type AccordionItemProps = {
  id?: string;
  children?: (props: AccordionItemChildRenderProps) => React.ReactNode;
  header: string;
  subtitle?: string;
  initialOpen?: boolean;
};
export const AccordionItem = ({
  children,
  id,
  header,
  subtitle,
  initialOpen,
}: AccordionItemProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(initialOpen ?? false);
  return (
    <li
      id={id}
      className={clsx(
        styles['c-accordion__item'],
        styles['bg'],
        open && styles['is-active']
      )}
    >
      <div
        className={styles['c-accordion__item__header']}
        onClick={() => setOpen(!open)}
      >
        <p>{header}</p>
        <div className={styles['ic']}>
          <p>{open ? t('Hide') : t('Show')}</p>
          <span></span>
        </div>
      </div>
      <div className={styles['c-accordion__item__body']}>
        {subtitle && (
          <div className={styles['acHeader']}>
            <p className={styles['acHeader__ttl']}>{subtitle}</p>
          </div>
        )}
        <div>
          {children?.({
            open,
          })}
        </div>
      </div>
    </li>
  );
};

export const AccordionItemWithRef = React.forwardRef(
  (props: AccordionItemProps, ref) => {
    const { children, id, header, subtitle, initialOpen } = props;
    const { t } = useTranslation();
    const [open, setOpen] = React.useState<boolean>(initialOpen ?? false);
    return (
      <li
        id={id}
        className={clsx(
          styles['c-accordion__item'],
          styles['bg'],
          open && styles['is-active']
        )}
      >
        <div
          className={styles['c-accordion__item__header']}
          onClick={() => setOpen(!open)}
          ref={ref as any}
        >
          <p>{header}</p>
          <div className={styles['ic']}>
            <p>{open ? t('Hide') : t('Show')}</p>
            <span></span>
          </div>
        </div>
        <div className={styles['c-accordion__item__body']}>
          {subtitle && (
            <div className={styles['acHeader']}>
              <p className={styles['acHeader__ttl']}>{subtitle}</p>
            </div>
          )}
          <div>
            {children?.({
              open,
            })}
          </div>
        </div>
      </li>
    );
  }
);
