import * as React from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Select } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { getBookingWidgetApiKeyUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { Box } from 'client/components/Box/Box';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import baseStyles from 'client/base.module.css';

interface Props {
  open: boolean;
  onClose: () => void;
  eTicketQrCodeId: string;
}

export const ETicketQrCodeDownloadModal = ({
  open,
  onClose,
  eTicketQrCodeId,
}: Props) => {
  const { t } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);
  const [selectedBwUrl, setSelectedBwUrl] = React.useState<string>('');

  const kioskUrl = `${getBookingWidgetApiKeyUrl(
    organization?.booking_widget_api_key ?? ''
  )}/eticket/verify?qrCodeId=${eTicketQrCodeId}`;

  const bwUrlOptions = React.useMemo(() => {
    const options = [
      {
        text: t('Normal Booking Widget'),
        value: kioskUrl,
      },
    ];

    if (organization?.liff_app_domain_name) {
      options.push({
        text: t('LIFF App'),
        value: `https://${
          organization?.liff_app_domain_name ?? ''
        }/tickets?qrCodeId=${eTicketQrCodeId}`,
      });
    }

    if ((organization?.line_mini_app_domain_names || []).length > 0) {
      (organization?.line_mini_app_domain_names ?? []).reduce(
        (acc, domainName) => {
          if (domainName?.domain_name) {
            acc.push({
              text: t('LINE Mini App') + ` (${domainName?.domain_name ?? ''})`,
              value: `https://${
                domainName?.domain_name ?? ''
              }/tickets?qrCodeId=${eTicketQrCodeId}`,
            });
          }
          return acc;
        },
        options
      );
    }

    return options;
  }, [kioskUrl, t, organization]);
  return (
    <Modal open={open} onClose={onClose} title={t('Show QR Code')}>
      <Modal.Content>
        {bwUrlOptions.length > 1 ? (
          <>
            <Box mb={2}>
              <p>
                {t(
                  'Please select the booking widget you want to use to scan the QR code.'
                )}
              </p>
              <Select
                search
                options={bwUrlOptions}
                value={selectedBwUrl}
                onChange={(_, { value }) => {
                  setSelectedBwUrl(value);
                }}
              />
            </Box>
            {selectedBwUrl && (
              <>
                <Box mb={2}>
                  <a
                    className={baseStyles['base-link']}
                    href={selectedBwUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedBwUrl}
                  </a>
                </Box>
                <Box mt={2} mb={2}>
                  <QRCode value={selectedBwUrl} />
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            <a
              className={baseStyles['base-link']}
              href={kioskUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {kioskUrl}
            </a>
            <Box mt={2} mb={2}>
              <QRCode value={kioskUrl} />
            </Box>
          </>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button style="gray" size="middle" onClick={onClose}>
          {t('Close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
