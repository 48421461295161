import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Props as CustomEditorProps } from 'shared/components/templates/newsletter/new-design/CustomEditor';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Props as SellOutProps } from 'shared/components/templates/newsletter/SellOut';
import { Props as LimitedTimeSaleProps } from 'shared/components/templates/newsletter/LimitedTimeSale';
import { Props as SoaringPopularityProps } from 'shared/components/templates/newsletter/SoaringPopularity';
import { Props as IntroduceNewPlanProps } from 'shared/components/templates/newsletter/IntroduceNewPlan';
import { Props as PromoCodeProps } from 'shared/components/templates/newsletter/PromoCode';
import { Props as AutoPromoProps } from 'shared/components/templates/newsletter/AutoPromo';
import { Props as PlainProps } from 'shared/components/templates/newsletter/Plain';
import { Props as CustomProps } from 'shared/components/templates/newsletter/Custom';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';
import sellOutJa from 'client/images/newsletter/ja/thumbnail-1.png';
import limitedTimeJa from 'client/images/newsletter/ja/thumbnail-2.png';
import soaringPopularityJa from 'client/images/newsletter/ja/thumbnail-3.png';
import introduceNewPlanJa from 'client/images/newsletter/ja/thumbnail-4.png';
import seasonalEventJa from 'client/images/newsletter/ja/thumbnail-5.png';
import promoCodeJa from 'client/images/newsletter/ja/thumbnail-6.png';
import autoPromoJa from 'client/images/newsletter/ja/thumbnail-7.png';
import plainJa from 'client/images/newsletter/ja/thumbnail-8.png';
import simpleJa from 'client/images/newsletter/ja/thumbnail-9.png';
import customJa from 'client/images/newsletter/ja/thumbnail-10.png';
import sellOutEn from 'client/images/newsletter/en/thumbnail-1.png';
import limitedTimeEn from 'client/images/newsletter/en/thumbnail-2.png';
import soaringPopularityEn from 'client/images/newsletter/en/thumbnail-3.png';
import introduceNewPlanEn from 'client/images/newsletter/en/thumbnail-4.png';
import seasonalEventEn from 'client/images/newsletter/en/thumbnail-5.png';
import promoCodeEn from 'client/images/newsletter/en/thumbnail-6.png';
import autoPromoEn from 'client/images/newsletter/en/thumbnail-7.png';
import plainEn from 'client/images/newsletter/en/thumbnail-8.png';
import simpleEn from 'client/images/newsletter/en/thumbnail-9.png';
import customEn from 'client/images/newsletter/en/thumbnail-10.png';
import { Organization } from 'shared/models/swagger';
import { TranslateFuncType } from 'client/components/Translate';
import { useDefaultContents } from 'shared/components/templates/newsletter/CustomEditor/useTemplateOptions';
import { config } from 'client/config';

const getOperatingHoursDescription = (
  org: Organization | null,
  t: TranslateFuncType
) => {
  if (org?.operating_hours_rules && org?.operating_hours_rules.length > 0) {
    return t('{{hours}} (local time)', {
      hours: `${org?.operating_hours_rules[0].start_time_local} ~ ${org?.operating_hours_rules[0].end_time_local}`,
    });
  }

  return '';
};

interface TemplateOption {
  value: string;
  title: string;
  description: string;
  thumbnail: string;
  defaultTemplateValues:
    | SellOutProps
    | LimitedTimeSaleProps
    | SoaringPopularityProps
    | IntroduceNewPlanProps
    | PromoCodeProps
    | AutoPromoProps
    | PlainProps
    | CustomProps
    | CustomEditorProps
    | null;
}

export const useTemplateOptions = (): TemplateOption[] => {
  const { t, i18n } = useTranslation();

  const org = useSelector(activeUserOrganizationSelector);
  const bookingWebsiteUrl = getBookingWebsiteUrl(
    org,
    lowercaseIsoToUppercaseIso[
      i18n.language as keyof typeof lowercaseIsoToUppercaseIso
    ]
  );
  const unsubscribeUrl = `${bookingWebsiteUrl}/me/signin`;

  return [
    {
      value: 'SellOut',
      title: t('Likely to Sell Out'),
      description: t(
        'Email to inform customers that products are likely to sell out. Emphasize one product as the main topic of the email, and describe in detail about the product with a photo. Links to other related products are also shown at the bottom of the email.'
      ),
      thumbnail: i18n.language === 'en' ? sellOutEn : sellOutJa,
      defaultTemplateValues: {
        language: i18n.language,
        topPageUrl: bookingWebsiteUrl,
        unsubscribeUrl: unsubscribeUrl,
        logoUrl: org?.logo_full_url ?? '',
        supplierName: org?.name ?? '',
        supplierEmail: org?.office_email ?? '',
        supplierOfficeAddress: org?.office_mailing_address ?? '',
        supplierOfficePhone: org?.office_phone ?? '',
        supplierOfficeHours: getOperatingHoursDescription(org, t),
        customerIntroduction: t('Dear {customerName},'),
        introParagraph:
          i18n.language === 'ja'
            ? `こんにちは！\nゴールデンウィークの予定は決まりましたか？\n${org?.name}ではGW期間中もまだまだ予約を受け付けております。ご予約お待ちしております。`
            : t(
                'Our holiday season diving experience is filling up fast and availability is becoming limited. Book now to secure your spot!'
              ),
        keyVisualUrl: 'https://assets.ntmg.com/newsletter/header-1.png',
        headline: t('Popular plans are likely to sell out!'),
        headlineDescription:
          i18n.language === 'ja'
            ? 'シュノーケリングとダイビングがセットになったお得なプランもまだ予約を受け付けていますが、お日にちによっては既に満席となっている日もあります。ご予約はお早めに！'
            : t(
                "Some dates are likely to sell out soon. Don't miss out on this amazing opportunity to explore the underwater world with our team of experienced professionals."
              ),
        highlightedDateSection: {
          title: t('Limited availability dates'),
          dates: t('Apr 30, May 3'),
          availableSlots: '5',
          style: 'RED',
        },
        mainProduct: {
          productId: '',
          productName: t('Sample: Snorkeling & Diving Experience'),
          productDescription: t(
            'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
          ),
          productUrl: 'https://ntmg.jp',
          productThumbnailUrl:
            'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
          buttonText: t('Plan Details'),
        },
        productList: {
          title: t('Other Recommended Plans'),
          products: [
            {
              productId: '',
              productName: t('Sample: Snorkeling & Diving Experience'),
              productDescription: t(
                'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
              ),
              productUrl: 'https://ntmg.jp',
              productThumbnailUrl:
                'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
              buttonText: t('Plan Details'),
            },
            {
              productId: '',
              productName: t('Sample: Snorkeling & Diving Experience'),
              productDescription: t(
                'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
              ),
              productUrl: 'https://ntmg.jp',
              productThumbnailUrl:
                'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
              buttonText: t('Plan Details'),
            },
          ],
        },
        twitterId: org?.sns_ids?.find((sns) => sns.key === 'twitter')?.id ?? '',
        instagramId:
          org?.sns_ids?.find((sns) => sns.key === 'instagram')?.id ?? '',
        youtubeId: org?.sns_ids?.find((sns) => sns.key === 'youtube')?.id ?? '',
        facebookId:
          org?.sns_ids?.find((sns) => sns.key === 'facebook')?.id ?? '',
        lineId: org?.sns_ids?.find((sns) => sns.key === 'line')?.id ?? '',
        inquiryHtml: t(
          'For any inquiries, please contact us at <a href=mailto:{{email}} target="_blank" rel="noreferrer">{{email}}</a>.',
          { email: org?.office_email ?? '' }
        ),
        unsubscribeText: t(
          'To unsubscribe, please go to My Page and cancel your email preferences.'
        ),
        poweredByText: t('Newsletter Powered by Nutmeg'),
      },
    },
    {
      value: 'LimitedTimeSale',
      title: t('Limited-time Sale'),
      description: t(
        'Email for limited-time sales and special sales to boost your bookings for a specific product.'
      ),
      thumbnail: i18n.language === 'en' ? limitedTimeEn : limitedTimeJa,
      defaultTemplateValues: {
        language: i18n.language,
        topPageUrl: bookingWebsiteUrl,
        unsubscribeUrl: unsubscribeUrl,
        logoUrl: org?.logo_full_url ?? '',
        supplierName: org?.name ?? '',
        supplierEmail: org?.office_email ?? '',
        supplierOfficeAddress: org?.office_mailing_address ?? '',
        supplierOfficePhone: org?.office_phone ?? '',
        supplierOfficeHours: getOperatingHoursDescription(org, t),
        customerIntroduction: t('Dear {customerName},'),
        introParagraph:
          i18n.language === 'ja'
            ? `こんにちは！\n ${org?.name} からお得な情報のお届けです！\n当店一番人気のプランを、3月31日までのお申し込みなら特別価格でご予約いただける特別セールを実施中です。\n予約で埋まってしまう前に、是非お申し込みください！`
            : t(
                'Hello! We have a great deals just for you! If you book by March 31st, you can get special discounts for your reservations.Make sure to check this out before it sells out.'
              ),
        keyVisualUrl: 'https://assets.ntmg.com/newsletter/header-1.png',
        headline: t('Get 20% discounts for our top-selling plan!'),
        headlineDescription:
          i18n.language === 'ja'
            ? 'シュノーケリングとダイビングがセットになったお得なプランが20%割引でご予約いただけます！'
            : t(
                'Book a combination snorkeling and diving package with a 20% discount!'
              ),
        highlightedBookingDeadlineSection: {
          title: t('Booking deadline'),
          date: t('March 31, 23:59'),
          style: 'RED',
        },
        mainProduct: {
          productId: '',
          productName: t('Sample: Snorkeling & Diving Experience'),
          productDescription: t(
            'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
          ),
          productUrl: 'https://ntmg.jp',
          productThumbnailUrl:
            'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
          buttonText: t('Plan Details'),
        },
        productList: {
          title: t('Other Recommended Plans'),
          products: [
            {
              productId: '',
              productName: t('Sample: Snorkeling & Diving Experience'),
              productDescription: t(
                'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
              ),
              productUrl: 'https://ntmg.jp',
              productThumbnailUrl:
                'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
              buttonText: t('Plan Details'),
            },
            {
              productId: '',
              productName: t('Sample: Snorkeling & Diving Experience'),
              productDescription: t(
                'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
              ),
              productUrl: 'https://ntmg.jp',
              productThumbnailUrl:
                'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
              buttonText: t('Plan Details'),
            },
          ],
        },
        twitterId: org?.sns_ids?.find((sns) => sns.key === 'twitter')?.id ?? '',
        instagramId:
          org?.sns_ids?.find((sns) => sns.key === 'instagram')?.id ?? '',
        youtubeId: org?.sns_ids?.find((sns) => sns.key === 'youtube')?.id ?? '',
        facebookId:
          org?.sns_ids?.find((sns) => sns.key === 'facebook')?.id ?? '',
        lineId: org?.sns_ids?.find((sns) => sns.key === 'line')?.id ?? '',
        inquiryHtml: t(
          'For any inquiries, please contact us at <a href=mailto:{{email}} target="_blank" rel="noreferrer">{{email}}</a>.',
          { email: org?.office_email ?? '' }
        ),
        unsubscribeText: t(
          'To unsubscribe, please go to My Page and cancel your email preferences.'
        ),
        poweredByText: t('Newsletter Powered by Nutmeg'),
      },
    },
    {
      value: 'SoaringPopularity',
      title: t('Soaring Popularity'),
      description: t(
        'Email to tell guests that a particular product is becoming popular and encourage booking it.'
      ),
      thumbnail:
        i18n.language === 'en' ? soaringPopularityEn : soaringPopularityJa,
      defaultTemplateValues: {
        language: i18n.language,
        topPageUrl: bookingWebsiteUrl,
        unsubscribeUrl: unsubscribeUrl,
        logoUrl: org?.logo_full_url ?? '',
        supplierName: org?.name ?? '',
        supplierEmail: org?.office_email ?? '',
        supplierOfficeAddress: org?.office_mailing_address ?? '',
        supplierOfficePhone: org?.office_phone ?? '',
        supplierOfficeHours: getOperatingHoursDescription(org, t),
        customerIntroduction: t('Dear {customerName},'),
        introParagraph:
          i18n.language === 'ja'
            ? `こんにちは！\n${org?.name} からオススメ情報のお届けです！\n今の時期にしか体験することのできない特別プランを販売中です。\nまだお席の余裕もありますが、現在沢山のご予約をいただいています。\n近々、満席となってしまい予約をお受けできない日も出てくるかもしれませんので、ぜひ今度のお出かけにご検討ください。`
            : t(
                'Hello! Just wanted to share with you that our special seasonal plan is becoming hot now. There are still some seats available, but we are receiving more and more reservations everyday and it may sell out on some dates, soon. So, make sure to get your seat before they are gone.'
              ),
        keyVisualUrl:
          'https://assets.ntmg.com/newsletter/claudia-chiavazza-N9vsB6OEeKM-unsplash.jpg',
        headline: t('About the special plan'),
        headlineDescription:
          i18n.language === 'ja'
            ? '今しか見ることができない◯◯の鑑賞と、地元でとれた新鮮な海鮮丼のランチをご堪能いただけます。ランチの後は◯◯の街をのんびり散策 & イチゴ狩りと盛りだくさんのプランです。'
            : t(
                'You will be able to enjoy a lunch of fresh local seafood bowls and the rare and unique "◯◯". After lunch, enjoy a leisurely stroll in the town of ◯◯ and strawberry picking.'
              ),
        highlightedPlanSummarySection: {
          title: t('Plan Summary'),
          items: [
            t('Only available until April 20'),
            t('Includes seafood lunch and strawberry picking'),
          ],
          style: 'RED',
        },
        mainProduct: {
          productId: '',
          productName: t('Sample: Snorkeling & Diving Experience'),
          productDescription: t(
            'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
          ),
          productUrl: 'https://ntmg.jp',
          productThumbnailUrl:
            'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
          buttonText: t('Plan Details'),
        },
        productList: {
          title: t('Other Recommended Plans'),
          products: [
            {
              productId: '',
              productName: t('Sample: Snorkeling & Diving Experience'),
              productDescription: t(
                'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
              ),
              productUrl: 'https://ntmg.jp',
              productThumbnailUrl:
                'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
              buttonText: t('Plan Details'),
            },
            {
              productId: '',
              productName: t('Sample: Snorkeling & Diving Experience'),
              productDescription: t(
                'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
              ),
              productUrl: 'https://ntmg.jp',
              productThumbnailUrl:
                'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
              buttonText: t('Plan Details'),
            },
          ],
        },
        twitterId: org?.sns_ids?.find((sns) => sns.key === 'twitter')?.id ?? '',
        instagramId:
          org?.sns_ids?.find((sns) => sns.key === 'instagram')?.id ?? '',
        youtubeId: org?.sns_ids?.find((sns) => sns.key === 'youtube')?.id ?? '',
        facebookId:
          org?.sns_ids?.find((sns) => sns.key === 'facebook')?.id ?? '',
        lineId: org?.sns_ids?.find((sns) => sns.key === 'line')?.id ?? '',
        inquiryHtml: t(
          'For any inquiries, please contact us at <a href=mailto:{{email}} target="_blank" rel="noreferrer">{{email}}</a>.',
          { email: org?.office_email ?? '' }
        ),
        unsubscribeText: t(
          'To unsubscribe, please go to My Page and cancel your email preferences.'
        ),

        poweredByText: t('Newsletter Powered by Nutmeg'),
      },
    },
    {
      value: 'IntroduceNewPlan',
      title: t('Introduce New Plan'),
      description: t(
        'Email to introduce new plans. Send this email to introduce products to specific target customers, such as customers who have participated in similar products in the past or families with children. Give a quick introduction of the product with highlighted header and short description.'
      ),
      thumbnail:
        i18n.language === 'en' ? introduceNewPlanEn : introduceNewPlanJa,
      defaultTemplateValues: {
        language: i18n.language,
        topPageUrl: bookingWebsiteUrl,
        unsubscribeUrl: unsubscribeUrl,
        logoUrl: org?.logo_full_url ?? '',
        supplierName: org?.name ?? '',
        supplierEmail: org?.office_email ?? '',
        supplierOfficeAddress: org?.office_mailing_address ?? '',
        supplierOfficePhone: org?.office_phone ?? '',
        supplierOfficeHours: getOperatingHoursDescription(org, t),
        customerIntroduction: t('Dear {customerName},'),
        introParagraph:
          i18n.language === 'ja'
            ? `もうすぐ夏休みですね。\n夏休みにおすすめのプランをご紹介します。\n人気プランでは夏休みシーズンの予約は予約は6月から埋まり始めてしまいます。希望する日に満席で予約できない、となってしまう前に、早めの予約をオススメしています。`
            : t(
                'We are excited to announce a new plan. As we approach to summer holiday season, it starts to get booked as early as may. Book now to secure your seat for an adventure of a lifetime.'
              ),
        planSections: [
          {
            key: '1',
            keyVisualUrl:
              'https://assets.ntmg.com/newsletter/claudia-chiavazza-N9vsB6OEeKM-unsplash.jpg',
            title: t('New plan for families with small kids'),
            highlightText: t('Highlights'),
            highlights: [
              t('Charter plan for one group'),
              t('Experienced staff will guide you through out your exprience.'),
              t('You can try other activities such as banana boat and SUP'),
            ],
            linkType: 'PRODUCT',
            linkUrl:
              'https://tutorial-1.book.stg.ntmg.com/products/c1f7d2f5-1e7d-5ed3-82a0-bb5beff22feb',
            productThumbnailUrl:
              'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
            productId: '',
            productName: t('Sample: Snorkeling & Diving Experience'),
            productDescription: t(
              'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
            ),
            detailsButtonText: t('Plan Details'),
          },
          {
            key: '2',
            keyVisualUrl:
              'https://assets.ntmg.com/newsletter/claudia-chiavazza-N9vsB6OEeKM-unsplash.jpg',
            title: t('New plan for families with small kids'),
            highlightText: t('Highlights'),

            highlights: [
              t('Charter plan for one group'),
              t('Experienced staff will guide you through out your exprience.'),
              t('You can try other activities such as banana boat and SUP'),
            ],
            linkType: 'PRODUCT',
            linkUrl:
              'https://tutorial-1.book.stg.ntmg.com/products/c1f7d2f5-1e7d-5ed3-82a0-bb5beff22feb',
            productThumbnailUrl:
              'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
            productId: '',
            productName: t('Sample: Snorkeling & Diving Experience'),
            productDescription: t(
              'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
            ),
            detailsButtonText: t('Plan Details'),
          },
        ],
        twitterId: org?.sns_ids?.find((sns) => sns.key === 'twitter')?.id ?? '',
        instagramId:
          org?.sns_ids?.find((sns) => sns.key === 'instagram')?.id ?? '',
        youtubeId: org?.sns_ids?.find((sns) => sns.key === 'youtube')?.id ?? '',
        facebookId:
          org?.sns_ids?.find((sns) => sns.key === 'facebook')?.id ?? '',
        lineId: org?.sns_ids?.find((sns) => sns.key === 'line')?.id ?? '',
        inquiryHtml: t(
          'For any inquiries, please contact us at <a href=mailto:{{email}} target="_blank" rel="noreferrer">{{email}}</a>.',
          { email: org?.office_email ?? '' }
        ),
        unsubscribeText: t(
          'To unsubscribe, please go to My Page and cancel your email preferences.'
        ),

        poweredByText: t('Newsletter Powered by Nutmeg'),
      },
    },
    {
      value: 'SeasonalEvent',
      title: t('Seasonal Event Information'),
      description: t(
        'Email to introduce seasonal events. Give quick introduction of the event with highlighted header and short description.'
      ),
      thumbnail: i18n.language === 'en' ? seasonalEventEn : seasonalEventJa,
      defaultTemplateValues: {
        language: i18n.language,
        topPageUrl: bookingWebsiteUrl,
        unsubscribeUrl: unsubscribeUrl,
        logoUrl: org?.logo_full_url ?? '',
        supplierName: org?.name ?? '',
        supplierEmail: org?.office_email ?? '',
        supplierOfficeAddress: org?.office_mailing_address ?? '',
        supplierOfficePhone: org?.office_phone ?? '',
        supplierOfficeHours: getOperatingHoursDescription(org, t),
        customerIntroduction: t('Dear {customerName},'),
        introParagraph:
          i18n.language === 'ja'
            ? `こんにちは。\n少しずつ暖かい日も増えて、春はもうすぐそこまで来ていますね。\n3月末から4月にかけて、お花見シーズンに開催する特別プランをご紹介します。\n少し贅沢なお花見を、特製ランチと一緒にご堪能いただけます。\n皆様のご参加お待ちしております！`
            : t(
                'Hello. The days are getting warmer little by little, and spring is just around the corner. From the end of March to April, we have a special plan that will be held during the cherry blossom viewing season. You can enjoy a little extravagant cherry blossom viewing with a special lunch. We look forward to seeing you!'
              ),
        planSections: [
          {
            key: '1',
            keyVisualUrl:
              'https://assets.ntmg.com/newsletter/claudia-chiavazza-N9vsB6OEeKM-unsplash.jpg',
            title: t('Cherry Blossoms'),
            highlightText: t('Highlights'),
            highlights: [
              t('Enjoy cherry blossoms'),
              t('Special lunch only provided for this plan'),
              t('Stroll around the town of ◯◯ after lunch'),
              t('Between March 20 and April 10'),
            ],
            linkType: 'PRODUCT',
            linkUrl:
              'https://tutorial-1.book.stg.ntmg.com/products/c1f7d2f5-1e7d-5ed3-82a0-bb5beff22feb',
            productThumbnailUrl:
              'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
            productId: '',
            productName: t('Sample: Snorkeling & Diving Experience'),
            productDescription: t(
              'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
            ),
            detailsButtonText: t('Plan Details'),
          },
          {
            key: '2',
            keyVisualUrl:
              'https://assets.ntmg.com/newsletter/claudia-chiavazza-N9vsB6OEeKM-unsplash.jpg',
            title: t('New plan for families with small kids'),
            highlightText: t('Highlights'),

            highlights: [
              t('Charter plan for one group'),
              t('Experienced staff will guide you through out your exprience.'),
              t('You can try other activities such as banana boat and SUP'),
            ],
            linkType: 'PRODUCT',
            linkUrl:
              'https://tutorial-1.book.stg.ntmg.com/products/c1f7d2f5-1e7d-5ed3-82a0-bb5beff22feb',
            productThumbnailUrl:
              'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
            productId: '',
            productName: t('Sample: Snorkeling & Diving Experience'),
            productDescription: t(
              'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
            ),
            detailsButtonText: t('Plan Details'),
          },
        ],
        twitterId: org?.sns_ids?.find((sns) => sns.key === 'twitter')?.id ?? '',
        instagramId:
          org?.sns_ids?.find((sns) => sns.key === 'instagram')?.id ?? '',
        youtubeId: org?.sns_ids?.find((sns) => sns.key === 'youtube')?.id ?? '',
        facebookId:
          org?.sns_ids?.find((sns) => sns.key === 'facebook')?.id ?? '',
        lineId: org?.sns_ids?.find((sns) => sns.key === 'line')?.id ?? '',
        inquiryHtml: t(
          'For any inquiries, please contact us at <a href=mailto:{{email}} target="_blank" rel="noreferrer">{{email}}</a>.',
          { email: org?.office_email ?? '' }
        ),
        unsubscribeText: t(
          'To unsubscribe, please go to My Page and cancel your email preferences.'
        ),

        poweredByText: t('Newsletter Powered by Nutmeg'),
      },
    },
    {
      value: 'PromoCode',
      title: t('Announcing Promotions with Promo Codes'),
      description: t(
        'An email that introduces a discount campaign using a promo code. Tell customers that this is a special offer for only those who received this email.'
      ),
      thumbnail: i18n.language === 'en' ? promoCodeEn : promoCodeJa,
      defaultTemplateValues: {
        language: i18n.language,
        topPageUrl: bookingWebsiteUrl,
        unsubscribeUrl: unsubscribeUrl,
        logoUrl: org?.logo_full_url ?? '',
        supplierName: org?.name ?? '',
        supplierEmail: org?.office_email ?? '',
        supplierOfficeAddress: org?.office_mailing_address ?? '',
        supplierOfficePhone: org?.office_phone ?? '',
        supplierOfficeHours: getOperatingHoursDescription(org, t),
        customerIntroduction: t('Dear {customerName},'),
        introParagraph:
          i18n.language === 'ja'
            ? `こんにちは！\nゴールデンウィークの予定は決まりましたか？\n${org?.name}ではGW期間中もまだまだ予約を受け付けております。ご予約お待ちしております。`
            : t(
                "Hello!\nDo you have plans for Golden Week?\nWe're still accepting bookings for Golden Week and look forward to hearing from you."
              ),
        eyeCatchText: t('Special deal now only!'),
        keyVisualUrl: 'https://assets.ntmg.com/newsletter/header-3.png',
        promoLeadText: t('Promo Code'),
        promoDiscountText: t('10% discount'),
        promoCodePreText: t('Promo Code: '),
        promoCode: t('XXXXXX'),
        promoDescription:
          i18n.language === 'ja'
            ? `このメールを受け取った方限定で対象商品10%で予約できる\nお得なキャンペーンを実施中！！\n\n◇プロモコードご利用条件◇\n以下の期間に該当する予約でプロモコードをご利用いただけます。`
            : t(
                "Don't miss out on this opportunity to get 10% discount for bookings with promo code.\n\n[ Using Promo Code ] \nTo use the promo code, booking must be within the following period:"
              ),
        promoPeriodText: t(
          'Enter promo code at time of booking to get 10% off'
        ),
        periodText: t(
          'Booked date: Mar 1, 2023 ~ Mar 31, 2023\nParticipation Date: Mar 1, 2023 ~ Apr 30, 2023'
        ),
        noteText: t(
          'Please note that plan detail pages will not show anything about this campaign.\nPlease make sure to enter the promo code to get the discount on the checkout page.'
        ),
        productList: {
          title: t('Plans'),
          products: [
            {
              productId: '',
              productName: t('Sample: Snorkeling & Diving Experience'),
              productDescription: t(
                'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
              ),
              productUrl: 'https://ntmg.jp',
              productThumbnailUrl:
                'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
              buttonText: t('Plan Details'),
            },
            {
              productId: '',
              productName: t('Sample: Snorkeling & Diving Experience'),
              productDescription: t(
                'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
              ),
              productUrl: 'https://ntmg.jp',
              productThumbnailUrl:
                'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
              buttonText: t('Plan Details'),
            },
          ],
        },
        twitterId: org?.sns_ids?.find((sns) => sns.key === 'twitter')?.id ?? '',
        instagramId:
          org?.sns_ids?.find((sns) => sns.key === 'instagram')?.id ?? '',
        youtubeId: org?.sns_ids?.find((sns) => sns.key === 'youtube')?.id ?? '',
        facebookId:
          org?.sns_ids?.find((sns) => sns.key === 'facebook')?.id ?? '',
        lineId: org?.sns_ids?.find((sns) => sns.key === 'line')?.id ?? '',
        inquiryHtml: t(
          'For any inquiries, please contact us at <a href=mailto:{{email}} target="_blank" rel="noreferrer">{{email}}</a>.',
          { email: org?.office_email ?? '' }
        ),
        unsubscribeText: t(
          'To unsubscribe, please go to My Page and cancel your email preferences.'
        ),

        poweredByText: t('Newsletter Powered by Nutmeg'),
      },
    },
    {
      value: 'AutoPromo',
      title: t('Announcing Promotions that can be Applied Automatically'),
      description: t(
        'An email that introduces a discount campaign that does not require a promo code.'
      ),
      thumbnail: i18n.language === 'en' ? autoPromoEn : autoPromoJa,
      defaultTemplateValues: {
        language: i18n.language,
        topPageUrl: bookingWebsiteUrl,
        unsubscribeUrl: unsubscribeUrl,
        logoUrl: org?.logo_full_url ?? '',
        supplierName: org?.name ?? '',
        supplierEmail: org?.office_email ?? '',
        supplierOfficeAddress: org?.office_mailing_address ?? '',
        supplierOfficePhone: org?.office_phone ?? '',
        supplierOfficeHours: getOperatingHoursDescription(org, t),
        customerIntroduction: t('Dear {customerName},'),
        introParagraph:
          i18n.language === 'ja'
            ? `こんにちは！\nゴールデンウィークの予定は決まりましたか？\n${org?.name}ではGW期間中もまだまだ予約を受け付けております。ご予約お待ちしております。`
            : t(
                "Hello!\nDo you have plans for Golden Week?\nWe're still accepting bookings for Golden Week and look forward to hearing from you."
              ),
        eyeCatchText: t('Looking for a great deal?'),
        keyVisualUrl: 'https://assets.ntmg.com/newsletter/header-3.png',
        promoLeadText: t(
          'Early bird discount 15% OFF for reservations in April'
        ),
        promoDiscountText: t('Up to 15% OFF'),
        promoDescription:
          i18n.language === 'ja'
            ? `GW期間中の予約を30日前までにお申し込みいただくと、15%OFFとなるキャンペーンを実施中。\n皆様のご参加お待ちしております！`
            : t(
                'We are currently running a promotion where reservations made for Golden Week at least 30 days in advance will receive a 15% discount. We look forward to seeing you!'
              ),
        promoPeriodText: t(
          'Early Bird Discount! 15% OFF until 30 days before participation at 12:00 PM!'
        ),
        periodText: t(
          'Booked date: Mar 1, 2023 ~ Mar 31, 2023\nParticipation Date: Mar 1, 2023 ~ Apr 30, 2023'
        ),
        productList: {
          title: t('Plans'),
          products: [
            {
              productId: '',
              productName: t('Sample: Snorkeling & Diving Experience'),
              productDescription: t(
                'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
              ),
              productUrl: 'https://ntmg.jp',
              productThumbnailUrl:
                'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
              buttonText: t('Plan Details'),
            },
            {
              productId: '',
              productName: t('Sample: Snorkeling & Diving Experience'),
              productDescription: t(
                'description of your plan goes here.\n\ne.g.\n\nExperience underwater world of\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
              ),
              productUrl: 'https://ntmg.jp',
              productThumbnailUrl:
                'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
              buttonText: t('Plan Details'),
            },
          ],
        },
        twitterId: org?.sns_ids?.find((sns) => sns.key === 'twitter')?.id ?? '',
        instagramId:
          org?.sns_ids?.find((sns) => sns.key === 'instagram')?.id ?? '',
        youtubeId: org?.sns_ids?.find((sns) => sns.key === 'youtube')?.id ?? '',
        facebookId:
          org?.sns_ids?.find((sns) => sns.key === 'facebook')?.id ?? '',
        lineId: org?.sns_ids?.find((sns) => sns.key === 'line')?.id ?? '',
        inquiryHtml: t(
          'For any inquiries, please contact us at <a href=mailto:{{email}} target="_blank" rel="noreferrer">{{email}}</a>.',
          { email: org?.office_email ?? '' }
        ),
        unsubscribeText: t(
          'To unsubscribe, please go to My Page and cancel your email preferences.'
        ),
        poweredByText: t('Newsletter Powered by Nutmeg'),
      },
    },
    {
      value: 'Plain',
      title: t('Plain Message'),
      description: t('An email with a simple message and no special features.'),
      thumbnail: i18n.language === 'en' ? plainEn : plainJa,
      defaultTemplateValues: {
        logoPath: org?.logo_full_url ?? '',
        supplierName: org?.name ?? '',
        supplierEmail: org?.office_email ?? '',
        supplierOfficeAddress: org?.office_mailing_address ?? '',
        supplierOfficePhone: org?.office_phone ?? '',
        supplierOfficeHours: getOperatingHoursDescription(org, t),
        title: t('Hello'),
        message: t('Your message here. Your message here'),
        poweredByText: t('Newsletter Powered by Nutmeg'),
      },
    },
    {
      value: 'Custom',
      title: t('Simple Template'),
      description: t(
        'A simple template that can be used for various purposes, allowing you to place text, photos, and buttons.'
      ),
      thumbnail: i18n.language === 'en' ? simpleEn : simpleJa,
      defaultTemplateValues: {
        language: i18n.language,
        topPageUrl: bookingWebsiteUrl,
        unsubscribeUrl: unsubscribeUrl,
        logoUrl: org?.logo_full_url ?? '',
        supplierName: org?.name ?? '',
        supplierEmail: org?.office_email ?? '',
        supplierOfficeAddress: org?.office_mailing_address ?? '',
        supplierOfficePhone: org?.office_phone ?? '',
        supplierOfficeHours: getOperatingHoursDescription(org, t),
        customerIntroduction: t('Dear {customerName},'),
        introParagraph:
          i18n.language === 'ja'
            ? `こんにちは！\n${org?.name} からオススメ情報のお届けです！\n今の時期にしか体験することのできない特別プランを販売中です。\nまだお席の余裕もありますが、現在沢山のご予約をいただいています。\n近々、満席となってしまい予約をお受けできない日も出てくるかもしれませんので、ぜひ今度のお出かけにご検討ください。`
            : t('Introducing a new special opportunity.'),
        keyVisualUrl:
          'https://assets.ntmg.com/newsletter/claudia-chiavazza-N9vsB6OEeKM-unsplash.jpg',
        headline: t('About the very special opportunity'),
        headlineDescription: t('About the special opportunity'),
        customCards: [
          {
            title: t('Check out this special opportunity'),
            description: t(
              'description goes here.\n\ne.g.\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
            ),
            linkUrl: 'https://google.com',
            thumbnailUrl:
              'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
            buttonText: t('Plan Details'),
          },
        ],
        twitterId: org?.sns_ids?.find((sns) => sns.key === 'twitter')?.id ?? '',
        instagramId:
          org?.sns_ids?.find((sns) => sns.key === 'instagram')?.id ?? '',
        youtubeId: org?.sns_ids?.find((sns) => sns.key === 'youtube')?.id ?? '',
        facebookId:
          org?.sns_ids?.find((sns) => sns.key === 'facebook')?.id ?? '',
        lineId: org?.sns_ids?.find((sns) => sns.key === 'line')?.id ?? '',
        inquiryHtml: t(
          'For any inquiries, please contact us at <a href=mailto:{{email}} target="_blank" rel="noreferrer">{{email}}</a>.',
          { email: org?.office_email ?? '' }
        ),
        unsubscribeText: t(
          'To unsubscribe, please go to My Page and cancel your email preferences.'
        ),

        poweredByText: t('Newsletter Powered by Nutmeg'),
      },
    },
    ...(config.enableNewsletterCustomEditor
      ? [
          {
            value: 'CustomEditor',
            title: t('Custom Editor'),
            description: t(
              'A custom editor that lets you freely arrange and edit the overall structure of the email and the layout of photos.'
            ),
            thumbnail: i18n.language === 'en' ? customEn : customJa,
            defaultTemplateValues: {
              language: i18n.language,
              topPageUrl: bookingWebsiteUrl,
              unsubscribeUrl: unsubscribeUrl,
              logoUrl: org?.logo_full_url ?? '',
              supplierName: org?.name ?? '',
              supplierEmail: org?.office_email ?? '',
              supplierOfficeAddress: org?.office_mailing_address ?? '',
              supplierOfficePhone: org?.office_phone ?? '',
              supplierOfficeHours: getOperatingHoursDescription(org, t),
              customerIntroduction: t('Dear {customerName},'),
              introParagraph:
                i18n.language === 'ja'
                  ? `こんにちは！\n${org?.name} からオススメ情報のお届けです！\n今の時期にしか体験することのできない特別プランを販売中です。\nまだお席の余裕もありますが、現在沢山のご予約をいただいています。\n近々、満席となってしまい予約をお受けできない日も出てくるかもしれませんので、ぜひ今度のお出かけにご検討ください。`
                  : t('Introducing a new special opportunity.'),
              keyVisualUrl:
                'https://assets.ntmg.com/newsletter/claudia-chiavazza-N9vsB6OEeKM-unsplash.jpg',
              headline: t('About the very special opportunity'),
              headlineDescription: t('About the special opportunity'),
              customCards: [
                {
                  title: t('Check out this special opportunity'),
                  description: t(
                    'description goes here.\n\ne.g.\n\nExperienced instructor will guide you throughout the course.\n\nEven those who are not good at swimming or beginners can enjoy.'
                  ),
                  linkUrl: 'https://google.com',
                  thumbnailUrl:
                    'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
                  buttonText: t('Plan Details'),
                },
              ],
              twitterId:
                org?.sns_ids?.find((sns) => sns.key === 'twitter')?.id ?? '',
              instagramId:
                org?.sns_ids?.find((sns) => sns.key === 'instagram')?.id ?? '',
              youtubeId:
                org?.sns_ids?.find((sns) => sns.key === 'youtube')?.id ?? '',
              facebookId:
                org?.sns_ids?.find((sns) => sns.key === 'facebook')?.id ?? '',
              lineId: org?.sns_ids?.find((sns) => sns.key === 'line')?.id ?? '',
              inquiryHtml: t(
                'For any inquiries, please contact us at <a href=mailto:{{email}} target="_blank" rel="noreferrer">{{email}}</a>.',
                { email: org?.office_email ?? '' }
              ),
              unsubscribeText: t(
                'To unsubscribe, please go to My Page and cancel your email preferences.'
              ),
              poweredByText: t('Newsletter Powered by Nutmeg'),
              sections: useDefaultContents(t),
            },
          },
        ]
      : []),
  ];
};
