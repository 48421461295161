import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import baseStyles from 'client/base.module.css';

import styles from '../Tutorial.module.css';

type Props = {
  popUpDisable: () => void;
};

export const SiteSettingsTutorialPopUp = ({ popUpDisable }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['page-tutorial__popupFrame']}>
      <div className={styles['page-tutorial__popupFinish']}>
        <p className={styles['page-tutorial__popupFinish__header']}>
          {t('Booking Site Settings')}
        </p>
        <div className={styles['page-new-tutorial__popupFinish__body']}>
          <p>
            {t(
              'The final step of the tutorial is setting for the booking site.'
            )}
          </p>
          <p>{t('Enter basic information for your booking website.')}</p>
          <p>{t(' - Languages')}</p>
          <p>{t(' - Logo and icon')}</p>
          <p>{t(' - About your organization')}</p>
          <p>{t(' - Payment method')}</p>
          <p>{t(' - SNS accounts')}</p>
          <p>
            {t(
              'Same as the product details, you can come back to these settings and change them later.'
            )}
          </p>
          <button
            className={clsx(baseStyles['base-btn'], baseStyles['blue'])}
            style={{ width: '128px', height: '32px', margin: 'auto' }}
            onClick={() => popUpDisable?.()}
          >
            {t('OK')}
          </button>
        </div>
      </div>
    </div>
  );
};
