import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { DraggableProductList } from 'client/pages/ProductDetails/ProductContentsHeader/DraggableProductList/DraggableProductList';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { fetchProducts } from 'client/actions/products';
import type { ReduxState } from 'client/reducers';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import type { Product, Product$Patch } from 'shared/models/swagger';

type FormValues = {
  relatedProductIds: string[];
  recommendedProductIds: string[];
  crossSellRelatedProductIds: string[];
  upSellSimilarProductIds: string[];
};

const getInitialValues = (product: Product | null): FormValues => {
  return {
    relatedProductIds: product?.related_product_ids ?? [],
    recommendedProductIds: product?.recommended_product_ids ?? [],
    crossSellRelatedProductIds: product?.cross_sell_related_product_ids ?? [],
    upSellSimilarProductIds: product?.up_sell_similar_product_ids ?? [],
  };
};

const convertFormValuesToProductPatch = (values: FormValues): Product$Patch => {
  return {
    related_product_ids: values.relatedProductIds,
    recommended_product_ids: values.recommendedProductIds,
    cross_sell_related_product_ids: values.crossSellRelatedProductIds,
    up_sell_similar_product_ids: values.upSellSimilarProductIds,
  };
};

type Props = {
  onClose: () => void;
};
export const PMPSettingsEditorModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);
  const loading = useSelector((state: ReduxState) => state.products.loading);
  const allProducts = useSelector(summariesWithBookmarksSelector);
  const product = React.useContext(EditingProductContext);
  const initialValues = React.useMemo(
    () => getInitialValues(product),
    [product]
  );
  const options = allProducts
    .filter((p) => p.id !== product?.id)
    .map((p) => ({
      key: p.id,
      value: p.id,
      text: getVerboseDisplayProductName(p),
    }));
  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      loading={loading}
      title={t('Private Marketplace')}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <div>
        <Field name="relatedProductIds">
          {({ input }) => (
            <DraggableProductList
              productIds={input.value}
              setProductIds={input.onChange}
              options={options}
              label={t(
                'Related products shown on product details page (drag-and-drop to reorder)'
              )}
            />
          )}
        </Field>
        <Field name="recommendedProductIds">
          {({ input }) => (
            <DraggableProductList
              productIds={input.value}
              setProductIds={input.onChange}
              options={options}
              label={t(
                'Recommended products shown on product details page (drag-and-drop to reorder)'
              )}
            />
          )}
        </Field>
        <Field name="crossSellRelatedProductIds">
          {({ input }) => (
            <DraggableProductList
              productIds={input.value}
              setProductIds={input.onChange}
              options={options}
              label={t(
                'Cross-sell Related products (drag-and-drop to reorder)'
              )}
            />
          )}
        </Field>
        <Field name="upSellSimilarProductIds">
          {({ input }) => (
            <DraggableProductList
              productIds={input.value}
              setProductIds={input.onChange}
              options={options}
              label={t('Up-sell Similar Products (drag-and-drop to reorder)')}
            />
          )}
        </Field>
      </div>
    </ProductEditorModal>
  );
};
