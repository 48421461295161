import * as React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import clsx from 'clsx';
import _ from 'lodash';

import { Box } from 'client/components/Box/Box';
import {
  searchReservations,
  setLastReservationSearchQuery,
} from 'client/actions/reservationSearch';
import { formattedPhone } from 'client/libraries/util/formattedPhone';
import { fetchProducts } from 'client/actions/products';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { reservationVisibleColumnsSelector } from 'client/reducers/reservationTableControls';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
} from 'client/reducers/user';
import {
  ReservationSummaryShape,
  toReservationSummaryShape,
} from 'client/libraries/util/reservationSummaryShape';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import type { ReduxState } from 'client/reducers';
import type { ColumnType } from 'client/libraries/util/getReservationTableColumns';
import { Button, Checkbox } from 'client/components/Form';
import { operationAllowed } from 'shared/models/access';
import { ReservationSearchCustomTable } from 'client/pages/ReservationSearch/ReservationSearchTable/ReservationSearchCustomTable';
import { ReservationSearchQueryModal } from 'client/pages/ReservationSearch/ReservationSearchQueryModal/ReservationSearchQueryModal';
import { ReservationSearchQueryDisplayBox } from 'client/pages/ReservationSearch/ReservationSearchQueryDisplayBox/ReservationSearchQueryDisplayBox';
import {
  buildSearchReservationsRequest,
  SearchReservationsRequest,
} from 'client/pages/ReservationSearch/util';
import { setReservationCurrentPage } from 'client/actions/reservationTableControls';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import searchIcon from 'client/images/ic_search.svg';
import baseStyles from 'client/base.module.css';

import styles from './ReservationSelect.module.css';
import { validateInternationalPhoneNumber } from './util';
import { FormValues } from './formValues';
import { ReservationPhoneEditModal } from './ReservationPhoneEditModal';

interface Props {
  reservationSummary: ReservationSummaryShape;
}

export const ReservationSelectCell = ({ reservationSummary }: Props) => {
  const { t } = useTranslation();
  const form = useForm<FormValues>();
  const selectedReservations = form.getState().values?.reservations ?? [];

  const selectedReservation = selectedReservations.find(
    (r) => r.summary.id === reservationSummary.id
  );

  return reservationSummary.phone ? (
    <Box display="flex" alignItems="center" width="100%">
      <Checkbox
        checked={Boolean(selectedReservation)}
        onChange={() => {
          const prevValue = form.getState().values?.reservations ?? [];
          form.change(
            'reservations',
            selectedReservation
              ? prevValue.filter((r) => r.summary.id !== reservationSummary.id)
              : [
                  ...prevValue,
                  {
                    summary: reservationSummary,
                    phone: reservationSummary.phone,
                  },
                ]
          );
        }}
      />
    </Box>
  ) : (
    <>{t('No Phone')}</>
  );
};

export const ReservationPhoneCell = ({ reservationSummary }: Props) => {
  const { t } = useTranslation();
  const form = useForm<FormValues>();
  const selectedReservations = form.getState().values?.reservations ?? [];
  const [showPhoneNumberEditModal, setShowPhoneNumberEditModal] =
    React.useState(false);

  const selectedReservation = selectedReservations.find(
    (r) => r.summary.id === reservationSummary.id
  );

  const error = selectedReservation?.phone
    ? validateInternationalPhoneNumber(selectedReservation?.phone, t)
    : '';

  return reservationSummary.phone ? (
    <Box display="flex" alignItems="center" width="100%">
      {formattedPhone(selectedReservation?.phone ?? reservationSummary.phone)}
      {error && (
        <Box ml={2}>
          <Tooltip
            text={`${error} ${t(
              'Please click the edit button to correct it.'
            )}`}
            mobileStyle={{
              left: '-180px',
            }}
            icon={
              <i
                style={{ background: '#fc0' }}
                className={baseStyles['base-info__ic']}
              >
                ?
              </i>
            }
          />
        </Box>
      )}
      {selectedReservation &&
        (!selectedReservation?.phone?.startsWith('+') || error) && (
          <Box ml="auto">
            <EditIcon onClick={() => setShowPhoneNumberEditModal(true)} />
          </Box>
        )}
      {showPhoneNumberEditModal && (
        <ReservationPhoneEditModal
          onClose={() => setShowPhoneNumberEditModal(false)}
          onChange={(newPhone: string) => {
            const prevValue = form.getState().values?.reservations ?? [];
            form.change(
              'reservations',
              prevValue.map((formReservation) =>
                formReservation.summary.id === reservationSummary.id
                  ? {
                      summary: formReservation.summary,
                      phone: newPhone,
                    }
                  : formReservation
              )
            );
          }}
          initialPhone={selectedReservation?.phone ?? ''}
        />
      )}
    </Box>
  ) : (
    <>{t('No Phone')}</>
  );
};

export const ReservationSelect = () => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.reservationSearch.loading
  );
  const form = useForm<FormValues>();
  const selectedReservations = form.getState().values?.reservations ?? [];
  const dispatch = useDispatch();
  const allReservations = useSelector(
    (state: ReduxState) => state.reservationSearch.all
  );
  const totalSearchHits = useSelector(
    (state: ReduxState) => state.reservationSearch.totalHits
  );

  const visibleColumns = useSelector(reservationVisibleColumnsSelector);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservationSummaries = React.useMemo(
    () => allReservations.map((r) => toReservationSummaryShape(r, locale, t)),
    [allReservations, locale, t]
  );

  const allColumns = React.useMemo(() => {
    return [
      {
        Header: '',
        id: 'select',
        width: 'short',
        th: true,
        Cell: (cellInfo: { original: ReservationSummaryShape }) => (
          <ReservationSelectCell reservationSummary={cellInfo.original} />
        ),
      },
      {
        Header: t('Phone'),
        id: 'phone',
        width: 'long',
        Cell: (cellInfo: { original: ReservationSummaryShape }) => (
          <ReservationPhoneCell reservationSummary={cellInfo.original} />
        ),
      },
      ...getReservationTableColumns(t, locale),
    ];
  }, [locale, selectedReservations]);

  const showColumns = React.useMemo(() => {
    const getColumns = (columnMask: string[]): ColumnType[] => {
      return [
        'select',
        'phone',
        ...(columnMask.filter((c) => c !== 'select' && c !== 'phone') as any),
      ].map((c) => allColumns.find((col) => col.id === c) as any);
    };

    return getColumns(visibleColumns);
  }, [visibleColumns, allColumns]);

  const rowCount = useSelector(
    (state: ReduxState) => state.reservationTableControls.rowCount
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.reservationTableControls.currentPage
  );

  const lastQuery = useSelector(
    (state: ReduxState) => state.reservationSearch.lastQuery
  );
  const [searchCondition, setSearchCondition] =
    React.useState<SearchReservationsRequest>(lastQuery);
  React.useEffect(() => {
    search();
  }, [activeUser, rowCount, currentPage]);
  // Fetch products for search modal and search display
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUser]);
  React.useEffect(() => {
    // If user is a supplier, fetch contracted agents for search modal and search display
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);

  const search = () => {
    if (!isNutmegAdmin) {
      let pageToFetch = currentPage;
      if (!_.isEqual(searchCondition, lastQuery)) {
        dispatch(setLastReservationSearchQuery(searchCondition as any));
        pageToFetch = 1;
        dispatch(setReservationCurrentPage(pageToFetch));
      }

      dispatch(
        searchReservations(
          buildSearchReservationsRequest(searchCondition),
          rowCount,
          rowCount * (pageToFetch - 1)
        )
      );
    }
  };

  const reset = () => {
    setSearchCondition({
      presetKey: '',
      agentReference: '',
      agentIds: [],
      supplierReference: '',
      id: '',
      statuses: [],
      customerGivenName: '',
      customerFamilyName: '',
      customerPhone: '',
      customerEmail: '',
      bookingSourceTypes: [],
      groupIds: [],
      supplierIds: [],
      productIds: [],
      bookedDateFrom: '',
      bookedDateTo: '',
      participationDateFrom: '',
      participationDateTo: '',
      lastUpdatedDateFrom: '',
      lastUpdatedDateTo: '',
      dateFilterPreset: null,
      orderBy: 'last_updated_desc',
      supplierOrAgentReference: '',
      reservationLanguages: [],
      mostRecentEmailBounced: false,
      pickupCheckinLocationName: '',
      waiverCompletionStatuses: [],
      checkinStatuses: [],
      annualPassOnly: false,
      expirationPresetKey: '',
      expirationDateFrom: '',
      expirationDateTo: '',
      expirationDateFilterPreset: null,
      automaticContinuingStatus: null,
    });
  };

  return (
    <>
      {loading ? (
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      ) : (
        <>
          <div className={styles['step-button-row']}>
            <div className={baseStyles['base-subttl']}>
              {t('{{count}} reservations selected', {
                count: selectedReservations.length,
              })}
            </div>
            <Box ml="auto">
              <Button
                disabled={selectedReservations.length === 0}
                type="submit"
                style="green"
                size="middle"
              >
                {t('Continue to Step 2')}
              </Button>
            </Box>
          </div>

          <div className={clsx(baseStyles['base-main__body__header'])}>
            <div
              className={clsx(
                baseStyles['base-main__body__header__left'],
                baseStyles['spOrder-1'],
                styles['action-buttons-row']
              )}
            >
              <ReservationSearchQueryModal
                onReset={reset}
                onSearch={() => {
                  dispatch(setReservationCurrentPage(1));
                  search();
                }}
                searchCondition={searchCondition}
                setSearchCondition={(condition) =>
                  setSearchCondition(condition)
                }
                trigger={
                  <Button.Transition
                    content={
                      <>
                        <img src={searchIcon} />
                        {t('Search')}
                      </>
                    }
                  />
                }
              />
              <Button
                onClick={() => {
                  form.change('reservations', [
                    ..._.uniqBy(
                      [
                        ...selectedReservations,
                        ...reservationSummaries
                          .filter((r) => r.phone)
                          .map((r) => ({ summary: r, phone: r.phone })),
                      ],
                      (r) => r.summary.id
                    ),
                  ]);
                }}
                style="yellow"
                size="middle"
              >
                {t('Select All')}
              </Button>
              <div className={styles['clear-button-container']}>
                <Button
                  onClick={() => form.change('reservations', [])}
                  style="gray"
                  size="middle"
                >
                  {t('Clear Selection')}
                </Button>
              </div>
            </div>
          </div>

          <ReservationSearchQueryDisplayBox searchFilters={lastQuery} />

          <ReservationSearchCustomTable
            reservations={reservationSummaries}
            totalHits={totalSearchHits}
            visibleColumns={showColumns}
          />
        </>
      )}
    </>
  );
};
