import clsx from 'clsx';
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReduxState } from 'client/reducers';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import {
  DateRange,
  DateRangePreset,
  getDateRangeForPreset,
  getDateRangePresetOptions,
  inferDateRangePresetFromDateRange,
} from 'client/libraries/util/dateRangePresets';
import baseStyles from 'client/v3-base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { getCalendarWeeks } from 'client/libraries/util/getCalendarWeeks';

import styles from './ComparisonDateInput.module.css';

type ComparisonStyle = 'PREVIOUS_PERIOD' | 'PREVIOUS_YEAR' | 'CUSTOM' | null;

const getComparisonDateRange = (
  baseDateRange: DateRange,
  comparisonStyle: ComparisonStyle
): DateRange | null => {
  switch (comparisonStyle) {
    case 'PREVIOUS_PERIOD': {
      // If the base date range is XX months, treat the comparison date range as XX months as well.
      // This means that base date range and comparison date range may have different lengths.
      if (
        baseDateRange.start ===
          moment(baseDateRange.start).startOf('month').format('YYYY-MM-DD') &&
        baseDateRange.end ===
          moment(baseDateRange.end).endOf('month').format('YYYY-MM-DD')
      ) {
        const periodLengthInMonths = moment(baseDateRange.end)
          .add(1, 'days')
          .diff(baseDateRange.start, 'months');
        const comparisonEnd = moment(baseDateRange.start)
          .subtract(1, 'day')
          .format('YYYY-MM-DD');
        const comparisonStart = moment(comparisonEnd)
          .subtract(periodLengthInMonths, 'months')
          .add(1, 'day')
          .format('YYYY-MM-DD');
        return {
          start: comparisonStart,
          end: comparisonEnd,
        };
      } else {
        const periodLengthInDays = moment(baseDateRange.end).diff(
          baseDateRange.start,
          'days'
        );
        const comparisonEnd = moment(baseDateRange.start)
          .subtract(1, 'day')
          .format('YYYY-MM-DD');
        const comparisonStart = moment(comparisonEnd)
          .subtract(periodLengthInDays, 'days')
          .format('YYYY-MM-DD');
        return {
          start: comparisonStart,
          end: comparisonEnd,
        };
      }
    }
    case 'PREVIOUS_YEAR':
      return {
        start: moment(baseDateRange.start)
          .subtract(1, 'year')
          .format('YYYY-MM-DD'),
        end: moment(baseDateRange.end).subtract(1, 'year').format('YYYY-MM-DD'),
      };
  }

  return null;
};

const inferComparisonStyle = (
  baseDateRange: DateRange,
  comparisonDateRange: DateRange | null
): ComparisonStyle | null => {
  if (!comparisonDateRange) {
    return null;
  }
  for (const comparisonStyle of [
    'PREVIOUS_PERIOD',
    'PREVIOUS_YEAR',
  ] as (ComparisonStyle | null)[]) {
    const comparison = getComparisonDateRange(
      baseDateRange,
      comparisonStyle as ComparisonStyle
    );
    if (comparison && _.isEqual(comparison, comparisonDateRange)) {
      return comparisonStyle;
    }
  }

  return 'CUSTOM';
};

interface Props {
  baseDateRange: DateRange;
  setBaseDateRange: (dateRange: DateRange) => void;
  comparisonDateRange: DateRange | null;
  setComparisonDateRange: (dateRange: DateRange | null) => void;

  // Hack to allow positioning the menu so that it's visible in the viewport
  menuStyle?: React.CSSProperties;
}

export const ComparisonDateInput = ({
  baseDateRange,
  setBaseDateRange,
  comparisonDateRange,
  setComparisonDateRange,
  menuStyle,
}: Props) => {
  const { t } = useTranslation();
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [comparisonStyle, setComparisonStyle] = React.useState<ComparisonStyle>(
    inferComparisonStyle(baseDateRange, comparisonDateRange) ?? null
  );
  const [activeDate, setActiveDate] = React.useState<
    | 'BASE_START_DATE'
    | 'BASE_END_DATE'
    | 'COMPARE_START_DATE'
    | 'COMPARE_END_DATE'
  >('BASE_START_DATE');
  const monthYearFormat = useSelector(
    (state: ReduxState) => state.language.selected.monthYearFormat
  );
  const [editingBaseDateRange, setEditingBaseDateRange] =
    React.useState<DateRange>(baseDateRange);
  const [editingComparisonDateRange, setEditingComparisonDateRange] =
    React.useState<DateRange | null>(comparisonDateRange);

  const [calendarMonth, setCalendarMonth] = React.useState<string>(
    baseDateRange?.start
      ? baseDateRange.start.substring(0, 7)
      : moment().format('YYYY-MM')
  );
  const [baseDateRangePreset, setBaseDateRangePreset] = React.useState<
    DateRangePreset | 'CUSTOM'
  >(inferDateRangePresetFromDateRange(baseDateRange) ?? 'CUSTOM');
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const dateRangeOptions = [
    ...getDateRangePresetOptions(t),
    {
      value: 'CUSTOM',
      text: t('Custom'),
    },
  ];
  const comparisonStyleOptions = [
    {
      value: 'PREVIOUS_PERIOD',
      text: t('Previous Period'),
    },
    {
      value: 'PREVIOUS_YEAR',
      text: t('Previous Year'),
    },
    {
      value: 'CUSTOM',
      text: t('Custom'),
    },
  ];

  const getFormattedDateRange = (dateRange: DateRange) =>
    `${moment(dateRange.start).locale(locale).format('l')} ~ ${moment(
      dateRange.end
    )
      .locale(locale)
      .format('l')}`;

  // Update preset when base range changes
  React.useEffect(() => {
    setBaseDateRangePreset(
      inferDateRangePresetFromDateRange(baseDateRange) ?? 'CUSTOM'
    );
  }, [baseDateRange]);

  React.useEffect(() => {
    let newBaseDateRange: DateRange = editingBaseDateRange;
    let newComparisonDateRange: DateRange | null = editingComparisonDateRange;
    switch (baseDateRangePreset) {
      case 'CUSTOM':
        break;
      default:
        newBaseDateRange = getDateRangeForPreset(baseDateRangePreset);
        break;
    }

    if (comparisonStyle !== 'CUSTOM') {
      newComparisonDateRange = getComparisonDateRange(
        newBaseDateRange,
        comparisonStyle
      );
    }

    if (!_.isEqual(newBaseDateRange, editingBaseDateRange)) {
      setEditingBaseDateRange(newBaseDateRange);
    }
    if (!_.isEqual(newComparisonDateRange, editingComparisonDateRange)) {
      setEditingComparisonDateRange(newComparisonDateRange);
    }
  }, [
    baseDateRangePreset,
    comparisonStyle,
    editingBaseDateRange,
    editingComparisonDateRange,
  ]);
  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        showMenu &&
        target instanceof Node &&
        !menuRef?.current?.contains(target)
      ) {
        setShowMenu(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [showMenu]);

  const weeks = getCalendarWeeks(calendarMonth);
  const dates = weeks.flat();

  const firstDayOfCalendarMonth = moment(`${calendarMonth}-01`);

  return (
    <div className={styles['p-transition__comparison']}>
      <div className={styles['p-comparison']}>
        <div
          className={styles['p-comparison__body']}
          onClick={() => setShowMenu(!showMenu)}
        >
          <p className={styles['p-comparison__main']}>
            {getFormattedDateRange(baseDateRange)}
          </p>
          {comparisonDateRange && (
            <p className={styles['p-comparison__sub']}>
              <span>{t('Compare with:')}</span>
              {getFormattedDateRange(comparisonDateRange)}
            </p>
          )}
        </div>
        {showMenu && (
          <div
            ref={menuRef}
            className={styles['p-comparison__menu']}
            style={menuStyle}
          >
            <div className={styles['p-comparison__menu__body']}>
              <div className={styles['p-comparison__menu__flex']}>
                <SingleDropdown
                  selectedOption={baseDateRangePreset}
                  options={dateRangeOptions}
                  onChange={(option) => {
                    setBaseDateRangePreset(
                      option as DateRangePreset | 'CUSTOM'
                    );
                  }}
                />
                <p
                  className={clsx(
                    styles['date'],
                    activeDate === 'BASE_START_DATE' &&
                      baseDateRangePreset === 'CUSTOM' &&
                      styles['active']
                  )}
                  onClick={() => setActiveDate('BASE_START_DATE')}
                >
                  {editingBaseDateRange.start}
                </p>
                ~
                <p
                  className={clsx(
                    styles['date'],
                    activeDate === 'BASE_END_DATE' &&
                      baseDateRangePreset === 'CUSTOM' &&
                      styles['active']
                  )}
                  onClick={() => setActiveDate('BASE_END_DATE')}
                >
                  {editingBaseDateRange.end}
                </p>
              </div>
              <div className={styles['p-comparison__menu__toggle']}>
                <Toggle
                  label={t('Compare')}
                  checked={comparisonStyle != null}
                  onChange={() => {
                    if (!comparisonStyle) {
                      setComparisonStyle('PREVIOUS_PERIOD');
                    } else {
                      setComparisonStyle(null);
                      setActiveDate('BASE_START_DATE');
                    }
                  }}
                />
              </div>
              {comparisonStyle && editingComparisonDateRange && (
                <div
                  className={clsx(
                    styles['p-comparison__menu__flex'],
                    baseStyles['u-mt-4']
                  )}
                >
                  <SingleDropdown
                    selectedOption={comparisonStyle}
                    options={comparisonStyleOptions}
                    onChange={(option) => {
                      setComparisonStyle(option as ComparisonStyle);
                    }}
                    renderOption={(option) => (
                      <div>
                        <div>{option.text}</div>
                        {(option.value === 'PREVIOUS_PERIOD' ||
                          option.value === 'PREVIOUS_YEAR') && (
                          <div className={styles['dropdown-subtitle']}>
                            {getFormattedDateRange(
                              getComparisonDateRange(
                                editingBaseDateRange,
                                option.value as ComparisonStyle
                              ) as DateRange
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  />
                  <p
                    className={clsx(
                      styles['date'],
                      activeDate === 'COMPARE_START_DATE' &&
                        comparisonStyle === 'CUSTOM' &&
                        styles['active']
                    )}
                    onClick={() => setActiveDate('COMPARE_START_DATE')}
                  >
                    {editingComparisonDateRange.start}
                  </p>
                  ~
                  <p
                    className={clsx(
                      styles['date'],
                      activeDate === 'COMPARE_END_DATE' &&
                        comparisonStyle === 'CUSTOM' &&
                        styles['active']
                    )}
                    onClick={() => setActiveDate('COMPARE_END_DATE')}
                  >
                    {editingComparisonDateRange.end}
                  </p>
                </div>
              )}
              <div className={styles['p-comparison__menu__calendar']}>
                <div className={styles['p-comparisonCalendar__menu']}>
                  <div className={styles['p-comparisonCalendar__menu__header']}>
                    <a
                      onClick={() => {
                        setCalendarMonth(
                          moment(calendarMonth)
                            .subtract(1, 'month')
                            .format('YYYY-MM')
                        );
                      }}
                    ></a>
                    <p>
                      {moment(calendarMonth)
                        .locale(locale)
                        .format(monthYearFormat)}
                    </p>
                    <a
                      onClick={() => {
                        setCalendarMonth(
                          moment(calendarMonth)
                            .add(1, 'month')
                            .format('YYYY-MM')
                        );
                      }}
                    ></a>
                  </div>
                  <div className={styles['p-comparisonCalendar__menu__body']}>
                    <ul
                      className={
                        styles['p-comparisonCalendar__menu__body__header']
                      }
                    >
                      <li>{t('Sun')}</li>
                      <li>{t('Mon')}</li>
                      <li>{t('Tue')}</li>
                      <li>{t('Wed')}</li>
                      <li>{t('Thu')}</li>
                      <li>{t('Fri')}</li>
                      <li>{t('Sat')}</li>
                    </ul>
                    <ul
                      className={
                        styles['p-comparisonCalendar__menu__body__day']
                      }
                    >
                      {dates.map((date) => {
                        const yyyyMmDd = date.format('YYYY-MM-DD');
                        return (
                          <li
                            key={yyyyMmDd}
                            onClick={() => {
                              switch (activeDate) {
                                case 'BASE_START_DATE':
                                  setEditingBaseDateRange({
                                    ...editingBaseDateRange,
                                    start: yyyyMmDd,
                                  });
                                  setActiveDate('BASE_END_DATE');
                                  setBaseDateRangePreset('CUSTOM');
                                  if (comparisonStyle) {
                                    setComparisonStyle('CUSTOM');
                                  }
                                  break;
                                case 'BASE_END_DATE':
                                  setEditingBaseDateRange({
                                    ...editingBaseDateRange,
                                    end: yyyyMmDd,
                                  });
                                  if (comparisonStyle) {
                                    setActiveDate('COMPARE_START_DATE');
                                  } else {
                                    setActiveDate('BASE_START_DATE');
                                  }
                                  setBaseDateRangePreset('CUSTOM');
                                  if (comparisonStyle) {
                                    setComparisonStyle('CUSTOM');
                                  }
                                  break;
                                case 'COMPARE_START_DATE':
                                  setEditingComparisonDateRange({
                                    start: yyyyMmDd,
                                    end: editingComparisonDateRange?.end ?? '',
                                  });
                                  setActiveDate('COMPARE_END_DATE');
                                  if (comparisonStyle) {
                                    setComparisonStyle('CUSTOM');
                                  }
                                  break;
                                case 'COMPARE_END_DATE':
                                  setEditingComparisonDateRange({
                                    start:
                                      editingComparisonDateRange?.start ?? '',
                                    end: yyyyMmDd,
                                  });
                                  setActiveDate('BASE_START_DATE');
                                  if (comparisonStyle) {
                                    setComparisonStyle('CUSTOM');
                                  }
                                  break;
                              }
                            }}
                            className={clsx(
                              date.month() !==
                                firstDayOfCalendarMonth.month() &&
                                styles['disabled'],
                              editingBaseDateRange.start <= yyyyMmDd &&
                                yyyyMmDd <= editingBaseDateRange.end &&
                                styles['blue'],
                              ((editingBaseDateRange.start < yyyyMmDd &&
                                yyyyMmDd < editingBaseDateRange.end) ||
                                (editingComparisonDateRange &&
                                  editingComparisonDateRange?.start <
                                    yyyyMmDd &&
                                  yyyyMmDd <
                                    editingComparisonDateRange?.end)) &&
                                styles['between'],
                              (editingBaseDateRange.start === yyyyMmDd ||
                                (editingComparisonDateRange &&
                                  editingComparisonDateRange?.start ===
                                    yyyyMmDd)) &&
                                styles['start'],
                              (editingBaseDateRange.end === yyyyMmDd ||
                                (editingComparisonDateRange &&
                                  editingComparisonDateRange?.end ===
                                    yyyyMmDd)) &&
                                styles['end'],
                              editingComparisonDateRange &&
                                editingComparisonDateRange?.start <= yyyyMmDd &&
                                yyyyMmDd <= editingComparisonDateRange?.end &&
                                styles['pink']
                            )}
                          >
                            {date.date()}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className={styles['p-comparisonCalendar__note']}>
                  <p>
                    <span className={styles['blue']}></span>
                    {t('Base Range')}
                  </p>
                  {comparisonStyle && (
                    <p>
                      <span className={styles['pink']}></span>
                      {t('Compare Range')}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles['p-comparison__menu__actions']}>
              <Button
                size="md"
                color="white"
                text={t('Cancel')}
                onClick={() => setShowMenu(false)}
              />
              <Button
                size="md"
                color="primary"
                text={t('Apply')}
                onClick={() => {
                  setBaseDateRange(editingBaseDateRange);
                  setComparisonDateRange(editingComparisonDateRange);
                  setShowMenu(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
