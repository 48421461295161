import { createSelector } from 'reselect';

import type { TranslateFuncType } from 'client/components/Translate';
import { toPromotionShape } from 'client/libraries/util/promotionShape';
import type { PromotionShape } from 'client/libraries/util/promotionShape';
import type { ReduxState } from 'client/reducers';
import type { ProductSummary } from 'shared/models/swagger';
import { BadgeColor } from 'client/components/v3/Common/Badge';

export type TabType = 'ON' | 'OFF' | 'DISCONTINUED';

export const promotionsSelector = createSelector(
  (state: ReduxState) => state.promotions.all,
  (promotions) => promotions.map(toPromotionShape)
);

export const filterPromotions = (
  promotions: PromotionShape[],
  products: ProductSummary[],
  filter: string,
  t: TranslateFuncType
): PromotionShape[] => {
  if (!filter) {
    return promotions;
  }

  return promotions.filter((promo) => {
    const internalProductName =
      products.find((p) => p.id == promo.productId)?.internal_product_name ??
      '';
    return filter.split(/\s+/).every((word) => {
      const productName = promo.productName || t('All Products');
      const promoCode = promo.promoCode ?? '';
      return (
        productName.toLowerCase().includes(word.toLowerCase()) ||
        promoCode.toLowerCase().includes(word.toLowerCase()) ||
        internalProductName.toLowerCase().includes(word.toLowerCase())
      );
    });
  });
};

export const getBadgeColorForPromotionStatus = (status: string): BadgeColor => {
  switch (status) {
    case 'ON':
      return 'success';
    case 'OFF':
      return 'warning';
    case 'DISCONTINUED':
      return 'gray';
    default:
      return 'gray';
  }
};
