import { v4 as uuidv4 } from 'uuid';

import * as Swagger from 'shared/models/swagger';

export type ProvinceCityName = {
  province: string;
  cities: string[];
};

export type AreaGroup = {
  key: string;
  name: string;
  type: 'POSTAL_CODE' | 'PROVINCE_CITY_NAME';
  postalCodes: string[];
  provinceCityNames: ProvinceCityName[];
};

export const getInitialValues = (): AreaGroup => ({
  key: uuidv4(),
  name: '',
  type: 'POSTAL_CODE',
  postalCodes: [],
  provinceCityNames: [],
});

export const convertFormValuesToOrganizationPatch = (
  organization: Swagger.Organization | null,
  areaGroupIndex: number,
  values: AreaGroup
): Swagger.Organization$Patch => {
  const areaGroup: Swagger.CustomerAreaGroup = {
    key: values.key,
    name: values.name,
    type: values.type,
    postal_codes: values.postalCodes,
    province_city_names: values.provinceCityNames,
  };

  const customerAreaGroups = [
    ...(organization?.customer_ledger_settings?.customer_area_groups || []),
  ];

  if (customerAreaGroups.length <= areaGroupIndex) {
    customerAreaGroups.push(areaGroup);
  } else {
    customerAreaGroups[areaGroupIndex] = areaGroup;
  }

  return {
    customer_ledger_settings: {
      ...organization?.customer_ledger_settings,
      customer_area_groups: customerAreaGroups,
    },
  };
};

export const convertSwaggerAreaGroupToFormValues = (
  customerAreaGroup: Swagger.CustomerAreaGroup
): AreaGroup => ({
  key: customerAreaGroup.key || uuidv4(),
  name: customerAreaGroup.name || '',
  type: customerAreaGroup.type || 'POSTAL_CODE',
  postalCodes: customerAreaGroup.postal_codes || [],
  provinceCityNames: (customerAreaGroup.province_city_names || []).map(
    (region) => ({
      province: region.province || '',
      cities: region.cities || [],
    })
  ),
});
