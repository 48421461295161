import { Icon } from 'client/components/Icons/Icon';
import editIcon from 'client/images/ic_edit.svg';

type Props = {
  onClick?: () => void;
  className?: string;
};
export const Edit = (props: Props) => {
  return <Icon {...props} src={editIcon} />;
};
