import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import { AboutUsEditor } from './AboutUsEditor/AboutUsEditor';
import { FaqEditor } from './FaqEditor/FaqEditor';
import { Covid19Editor } from './Covid19Editor/Covid19Editor';
import { CustomPages } from './CustomPages/CustomPages';

type TabType = 'ABOUT_US' | 'FAQ' | 'COVID19' | 'CUSTOM';
export const EssentialPages = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<TabType>('ABOUT_US');
  return (
    <>
      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'ABOUT_US' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('ABOUT_US');
            }}
          >
            <a>{t('About Us')}</a>
          </li>

          <li
            className={clsx(
              activeTab === 'FAQ' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('FAQ');
            }}
          >
            <a>{t('FAQ')}</a>
          </li>

          <li
            className={clsx(
              activeTab === 'COVID19' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('COVID19');
            }}
          >
            <a>{t('COVID-19 Guidelines')}</a>
          </li>

          <li
            className={clsx(
              activeTab === 'CUSTOM' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('CUSTOM');
            }}
          >
            <a>{t('Custom Page')}</a>
          </li>
        </ul>
      </div>

      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          componentStyles['no-margin-top'],
          componentStyles['is-active']
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <div>
            {activeTab === 'ABOUT_US' && <AboutUsEditor />}
            {activeTab === 'FAQ' && <FaqEditor />}
            {activeTab === 'COVID19' && <Covid19Editor />}
            {activeTab === 'CUSTOM' && <CustomPages />}
          </div>
        </div>
      </div>
    </>
  );
};
