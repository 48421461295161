import moment from 'moment-timezone';
import { TFunction } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';

import currencies from './currencies.json';

export const formats = {
  email: (data: string) => {
    // Taken from https://emailregex.com/ with the hope that we never have to touch it again.

    /* eslint-disable no-useless-escape */
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      data
    );
    /* eslint-enable no-useless-escape */
  },
  'yyyy-mm': (s: string) => {
    const groups = s.match(/^([0-9]{4})-([0-9]{2})$/);
    return !!(
      groups &&
      groups.length > 2 &&
      groups[2] > '00' &&
      groups[2] < '13'
    );
  },
  'yyyy-mm-dd': (s: string) => {
    return moment(s, 'YYYY-MM-DD', true).isValid();
  },
  'datetime-local': (s: string) => {
    return moment(s, 'YYYY-MM-DDTH:mm', true).isValid();
  },
  uuid: (s: string) => {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(
      s
    );
  },
  phone: (s: string) => {
    return /^[0-9]{8,20}$/.test(s.replace(/[-\s]/gi, ''));
  },
  international_phone: (s: string) => {
    return /^\+[0-9]{8,20}$/.test(s.replace(/[-\s]/gi, ''));
  },
  '24-hour-time': (s: string) => {
    return moment(s, 'H:mm', true).isValid();
  },
  'time-offset': (s: string) => {
    const groups = s.match(/^-?([0-9]+):([0-9]{2})$/);
    return !!(
      groups &&
      groups.length > 2 &&
      groups[2] >= '00' &&
      groups[2] <= '59'
    );
  },
  'time-duration': (s: string) => {
    const groups = s.match(/^([0-9]+):([0-9]{2})$/);
    return !!(
      groups &&
      groups.length > 2 &&
      groups[2] >= '00' &&
      groups[2] <= '59'
    );
  },
  money: (s: string) => {
    const groups = s.match(/^([A-Z]{3})-?[0-9]+(\.[0-9]*)?$/);

    if (!groups || groups.length < 2) {
      return false;
    }

    const curr = currencies[groups[1] as keyof typeof currencies];

    if (!curr) {
      return false;
    }

    const decimalDigits = groups[2] ? groups[2].length - 1 : 0;

    // Allow input decimal digits less than or equal to the currency's decimal digits, since such values can be zero-padded.
    if (decimalDigits > curr.decimal_digits) {
      return false;
    }

    return true;
  },
  // Alphabet name.
  'alpha-name': (s: string) => {
    return /^[-a-zA-Z/. ]+$/.test(s);
  },
  'non-negative-integer': (s: string) => {
    return /^(0|[1-9][0-9]*)$/.test(s);
  },
  float: (s: string) => {
    return /^[+-]?([0-9]*[.])?[0-9]+$/.test(s);
  },
  youtube: (s: string) => {
    return /^https?:\/\/www\.youtube\.com\/embed\/(.+)$/.test(s);
  },
  friendly_phone: (s: string) => {
    return isValidPhoneNumber(s);
  },
  fqdn: (s: string) => {
    return /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/.test(
      s
    );
  },
};
export const matchesFormat = (
  s: string,
  format: keyof typeof formats
): boolean => {
  const fn = formats[format];

  if (fn) {
    return fn(s);
  }

  // We don't have a spec for the format so we assume a match.
  return true;
};
export const getValidators = (t: TFunction) => {
  return {
    required: (value: any | null | undefined): string => {
      if (!value) return t('Required');
      return '';
    },
    atLeastOneLanguageRequred: (value: any[] | null | undefined): string => {
      if (!value || value.length === 0)
        return t('At least one language required');
      return '';
    },
    atLeastOnePaymentTypeRequred: (value: any[] | null | undefined): string => {
      if (!value || value.length === 0)
        return t('At least one payment type required');
      return '';
    },
    atLeastOnePayOnBoardPaymentMethodRequred: (
      value: any[] | null | undefined
    ): string => {
      if (!value || value.length === 0)
        return t('At least one local payment method required');
      return '';
    },
    atLeastOneCurrencyRequred: (value: any[] | null | undefined): string => {
      if (!value || value.length === 0)
        return t('At least one currency required');
      return '';
    },
    atLeastOneProductRequired: (value: any[] | null | undefined): string => {
      if (!value || !value?.some((item) => Boolean(item)))
        return t('At least one product required');
      return '';
    },
    atLeastOneConditionRequired: (value: any[] | null | undefined): string => {
      if (!value || !value?.some((item) => Boolean(item)))
        return t('At least one condition required');
      return '';
    },
  };
};
