import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_RESTAURANT_ORDER_REPORT_DATA_SUCCESS,
  FETCH_RESTAURANT_ORDER_REPORT_DATA_FAILURE,
  FETCH_RESTAURANT_ORDER_REPORT_DATA_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchRestaurantOrderReportDataRequest = () => ({
  type: FETCH_RESTAURANT_ORDER_REPORT_DATA_REQUEST,
});

const fetchRestaurantOrderReportDataSuccess = (response: any) => ({
  type: FETCH_RESTAURANT_ORDER_REPORT_DATA_SUCCESS,
  response,
});

const fetchRestaurantOrderReportDataFailure = (error: any) => ({
  type: FETCH_RESTAURANT_ORDER_REPORT_DATA_FAILURE,
  error,
});

let fetchRestaurantOrderReportDataCancel: () => void | undefined;
export const fetchRestaurantOrderReportData =
  (dateRanges: { start: string; end: string }[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchRestaurantOrderReportDataCancel?.();
    dispatch(fetchRestaurantOrderReportDataRequest());
    axios
      .get('/api/restaurantorders/reportdata', {
        params: {
          date_ranges: dateRanges.map(
            (dateRange) => `${dateRange.start},${dateRange.end}`
          ),
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchRestaurantOrderReportDataCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchRestaurantOrderReportDataSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchRestaurantOrderReportDataFailure('canceled'));
        dispatch(fetchRestaurantOrderReportDataFailure(error));
      });
  };
