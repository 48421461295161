import { BadgeColor } from 'client/components/v3/Common/Badge';

export const getBadgeColorForProductStatus = (
  productStatus: string
): BadgeColor => {
  switch (productStatus) {
    case 'ON_SALE':
      return 'primary-contained';
    case 'LIST_ONLY':
      return 'warning-contained';
    case 'NOT_LISTED':
    default:
      return 'gray-contained';
  }
};
