import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { contentLanguageOptions, getLanguageName } from 'client/libraries/i18n';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import {
  getProductSalesStatus,
  getSalesStatusText,
} from 'client/libraries/util/getProductSalesStatus';
import type { Product } from 'shared/models/swagger';
import starIcon from 'client/images/ic_star_on.svg';
import { Badge } from 'client/components/v3/Common/Badge';
import { Button } from 'client/components/v3/Common/Button';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { getBadgeColorForProductStatus } from 'client/libraries/util/getBadgeColorForProductStatus';

import styles from './HeaderButtons.module.css';

interface Props {
  product: Product;
  contentLanguage: string;
  onContentLanguageChange: (arg0: string) => void;
}
export const HeaderButtons = ({
  product,
  contentLanguage,
  onContentLanguageChange,
}: Props) => {
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);

  const languageOptions = contentLanguageOptions.map((option) => ({
    value: option.iso,
    text: getLanguageName(option.iso, t),
  }));
  const productSalesStatus = getProductSalesStatus(product);

  return (
    <>
      <div className={styles['base-single__header']}>
        <div className={clsx(styles['base-single__title__wrapper'])}>
          {operationAllowed(activeUser, 'write', 'productContents') && (
            <Badge
              label={getSalesStatusText(productSalesStatus, t)}
              color={getBadgeColorForProductStatus(productSalesStatus)}
              size="lg"
            />
          )}
          <p>{product.internal_product_name}</p>
          {product.review_count != null && product.review_count != 0 && (
            <div className={styles['base-single__headerinfo__other__review']}>
              <a href="#reviews">
                <img src={starIcon} alt="star" />
                <span>{(product.review_rating ?? 0).toFixed(1)}</span>
                <span>({product.review_count})</span>
              </a>
            </div>
          )}
        </div>
        <div className={styles['base-single__buttons']}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            className={styles['language-select']}
          >
            <p
              style={{
                paddingRight: '8px',
              }}
            >
              {t('Content Language')}
            </p>
            <SingleDropdown
              width={240}
              options={languageOptions}
              selectedOption={contentLanguage}
              onChange={(value) => {
                onContentLanguageChange(value);
              }}
            />
          </div>
          {/* TODO: remove v3 from url later */}
          <Link to={`/products/${product.id}/translation-v3`}>
            {/* <Link to={`/products/${product.id}/translation`}> */}
            <Button
              text={t('Translation')}
              uiType="bg"
              size="md"
              color="white"
              iconBeforeText={
                <i className="c-icon-solid-general-translate-01"></i>
              }
            />
          </Link>
          {(productSalesStatus !== 'LIST_ONLY' ||
            (productSalesStatus === 'LIST_ONLY' &&
              operationAllowed(activeUser, 'write', 'productContents'))) && (
            <Link
              // TODO: remove v3 from url later
              to={{
                pathname: `/reservations/new-v3`,
                // Use state to persist to location
                state: {
                  productId: product.id,
                  isFromProductDetailsPage: true,
                },
              }}
            >
              <Button
                text={t('Check availability')}
                uiType="bg"
                size="md"
                color="white"
              />
            </Link>
          )}
          {operationAllowed(activeUser, 'write', 'productContents') &&
            hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST') && (
              <>
                {/* TODO: remove v3 from url later */}
                <Link to={`/products/${product.id}/edit-v3`}>
                  {/* <Link to={`/products/${product.id}/edit`}> */}
                  <Button
                    text={t('Edit')}
                    uiType="bg"
                    size="md"
                    color="primary"
                    iconBeforeText={
                      <i className="c-icon-outline-general-edit-05"></i>
                    }
                  />
                </Link>
              </>
            )}
        </div>
      </div>
    </>
  );
};
