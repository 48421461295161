import { useTranslation } from 'react-i18next';

import { BookingForm } from 'client/pages/v3/Reservation/ReservationCreate/Booking/BookingForm';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const ReservationBooking = () => {
  const { t } = useTranslation();

  const paths = [
    { text: t('Create New Reservation'), url: '/reservations/new-v3' },
    { text: t('Reservation Details'), url: '' },
  ];

  return (
    <V3Page>
      <PageHeader
        title={t('Fill Reservation Details')}
        breadcrumbPaths={paths}
      />
      <BookingForm />
    </V3Page>
  );
};
