// @flow

import { createSelector } from 'reselect';
import _ from 'lodash';

import { activeUserAllOrganizationsSelector } from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';

export const agentOptionsSelector = createSelector<ReduxState, *, *, *, *, *>(
  (state: ReduxState) => state.organizations.contracted,
  activeUserAllOrganizationsSelector,
  (contractedOrganizations, allOrganizations) => {
    return _.orderBy(
      _.uniqBy(
        [
          ...contractedOrganizations,
          ...allOrganizations
            .filter((org) => org.type === 'AGENT')
            .map((org) => ({
              id: org.id,
              name: org.name,
            })),
        ],
        (agent) => agent.id
      ),
      [(agent) => agent.name?.toLowerCase()]
    ).map((agent) => ({
      key: agent.id,
      text: agent.name,
      value: agent.id,
    }));
  }
);
