// @flow

import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { BasicStep } from 'client/components/NewProductEditor/BasicStep';
import { DetailsStep } from 'client/components/NewProductEditor/DetailsStep/DetailsStep';
import { ReservationParamsStep } from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsStep';
import { ProductRegistrationCompleteStep } from 'client/pages/Home/Tutorial/ProductEditTutorial/ProductRegistrationCompleteStep';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import { fetchSourceLanguageProductById } from 'client/actions/products';
import { Loading } from 'client/pages/Loading/Loading';
import type { ReduxState } from 'client/reducers';
import styles from 'client/pages/Home/Tutorial/ProductEditTutorial/ProductEditTutorial.module.css';

export const ProductEditTutorial = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const scrollTo = React.useContext(ScrollToContext);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const organizationLoading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  const tutorialProductId = activeUserOrganization?.tutorial_product_id || '';
  const [editingProductId, setEditingProductId] = React.useState<string>(
    activeUserOrganization?.tutorial_product_id || ''
  );
  const [copy, setCopy] = React.useState<boolean>(
    tutorialProductId === editingProductId
  );
  const tutorialProduct = useSelector(
    (state: ReduxState) => state.products.byID[editingProductId]
  );

  React.useEffect(() => {
    setCopy(tutorialProductId === editingProductId);
  }, [editingProductId]);

  React.useEffect(() => {
    if (editingProductId) {
      dispatch(fetchSourceLanguageProductById(editingProductId));
    }
  }, [
    tutorialProductId,
    editingProductId,
    activeUserOrganization?.tutorial_stage,
  ]);

  React.useEffect(() => {
    scrollTo(0, 0);
  }, [activeUserOrganization?.tutorial_stage]);

  if (
    !activeUserOrganization ||
    organizationLoading ||
    (tutorialProductId && !tutorialProduct) ||
    (editingProductId && !tutorialProduct)
  ) {
    return <Loading />;
  }

  let activeStep = 'basic';
  if (activeUserOrganization?.tutorial_stage === 'PRODUCT_EDIT_BASIC_INFO') {
    activeStep = 'basic';
  } else if (
    activeUserOrganization?.tutorial_stage === 'PRODUCT_EDIT_RESERVATION_INFO'
  ) {
    activeStep = 'reservation';
  } else if (
    activeUserOrganization?.tutorial_stage === 'PRODUCT_EDIT_DETAILED_INFO'
  ) {
    activeStep = 'detailed';
  } else if (activeUserOrganization?.tutorial_stage === 'TEST_RESERVATION') {
    activeStep = 'complete';
  } else {
    if (activeUserOrganization?.tutorial_stage === 'SITE_SETTINGS') {
      activeStep = 'complete';
      return <Redirect to="/home/tutorial/settings" />;
    }
  }

  return (
    <EditingProductContext.Provider value={tutorialProduct ?? null}>
      <div>
        {(activeStep === 'basic' ||
          activeStep === 'reservation' ||
          activeStep === 'detailed') && (
          <ul className={styles['tutorial__nav']}>
            <li className={clsx(activeStep === 'basic' && styles['is-active'])}>
              STEP1<span> - {t('Basic Information')}</span>
            </li>
            <li
              className={clsx(
                activeStep === 'reservation' && styles['is-active']
              )}
            >
              STEP2<span> - {t('Reservation Parameters')}</span>
            </li>
            <li
              className={clsx(activeStep === 'detailed' && styles['is-active'])}
            >
              STEP3<span> - {t('Detail Information')}</span>
            </li>
          </ul>
        )}
        {activeStep === 'basic' && (
          <>
            <BasicStep
              copy={copy}
              sourceProductId={tutorialProductId}
              onNextClick={(productId: string) => {
                dispatch(
                  updateOrganization(activeUserOrganization.id, 'SUPPLIER', {
                    tutorial_stage: 'PRODUCT_EDIT_RESERVATION_INFO',
                  })
                );
                setEditingProductId(productId);
              }}
            />
          </>
        )}
        {activeStep === 'reservation' && (
          <ReservationParamsStep
            onNextClick={() =>
              dispatch(
                updateOrganization(activeUserOrganization.id, 'SUPPLIER', {
                  tutorial_stage: 'PRODUCT_EDIT_DETAILED_INFO',
                })
              )
            }
            onBackClick={() => {
              dispatch(
                updateOrganization(activeUserOrganization.id, 'SUPPLIER', {
                  tutorial_stage: 'PRODUCT_EDIT_BASIC_INFO',
                })
              );
            }}
          />
        )}
        {activeStep === 'detailed' && (
          <DetailsStep
            onNextClick={() =>
              dispatch(
                updateOrganization(activeUserOrganization.id, 'SUPPLIER', {
                  tutorial_stage: 'SITE_SETTINGS',
                })
              )
            }
            onBackClick={() =>
              dispatch(
                updateOrganization(activeUserOrganization.id, 'SUPPLIER', {
                  tutorial_stage: 'PRODUCT_EDIT_RESERVATION_INFO',
                })
              )
            }
          />
        )}
        {activeStep === 'complete' && <ProductRegistrationCompleteStep />}
      </div>
    </EditingProductContext.Provider>
  );
};
