import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { v4 as uuidv4 } from 'uuid';

import { Modal } from 'client/components/Modal/Modal';
import { Button, FieldWrapper, Radio } from 'client/components/Form';
import { getArrayMutators } from 'client/libraries/util/form';
import { config } from 'client/config';

import { GuidancePageItem } from './formValues';
import { DigitalGuidanceItemNormalTypeForm } from './DigitalGuidanceItemNormalTypeForm';
import { DigitalGuidanceItemProductTypeForm } from './DigitalGuidanceItemProductTypeForm';

type Props = {
  existingItem?: GuidancePageItem;
  open: boolean;
  onClose: () => void;
  onSave: (item: GuidancePageItem) => void;
};

export const EditDigitalGuidanceItemModal = ({
  existingItem,
  open,
  onClose,
  onSave,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('Edit Digital Guidance Item')}
      open={open}
      onClose={onClose}
      insertRoot
      width="wide"
    >
      <Form
        onSubmit={(values: GuidancePageItem) => {
          console.log('value', values);
          onSave(values);
          onClose();
        }}
        initialValues={
          existingItem ?? {
            key: uuidv4(),
            title: '',
            content: null,
            itemType: 'NORMAL',
          }
        }
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, values }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              e.stopPropagation();
            }}
          >
            {config.enableBookInDigitalGuidance ? (
              <Field name="itemType">
                {({ input }) => (
                  <>
                    <FieldWrapper
                      label={t('Type')}
                      tooltipText={t(
                        'Select "Normal" to write an article for local guide. Select "Additional Reservations/purchases" to sell additional ticket on the guidance page.'
                      )}
                    >
                      <Radio
                        label={t('Normal')}
                        checked={input.value === 'NORMAL'}
                        onChange={() => {
                          input.onChange('NORMAL');
                        }}
                      />
                      <Radio
                        label={t('Additional reservations/purchases')}
                        checked={input.value === 'PRODUCT'}
                        onChange={() => {
                          input.onChange('PRODUCT');
                        }}
                      />
                    </FieldWrapper>
                    {input.value === 'NORMAL' && (
                      <DigitalGuidanceItemNormalTypeForm />
                    )}
                    {input.value === 'PRODUCT' && (
                      <DigitalGuidanceItemProductTypeForm />
                    )}
                  </>
                )}
              </Field>
            ) : (
              <DigitalGuidanceItemNormalTypeForm />
            )}

            <Modal.Actions>
              <Button
                disabled={values.editorJsIsLoading}
                size="middle"
                style="blue"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
