export type eTicketDailyUsesDisplaySettings = {
  productId: string;
  selectedDate: string;
  selectedProductGroup: string;
  shownPerProductGroup: boolean;
};

export const defaultVisibleColumns = [
  'REDEMPTION_DATE',
  'PRODUCT_NAME',
  'TICKET_USAGE',
  'TICKET_USAGE_OPTION',
  'AGENT_REFERENCE',
  'SUPPLIER_REFERENCE',
  'GUEST_DISPLAY_NAME',
];
