import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Select } from 'client/components/Form';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import baseStyles from 'client/base.module.css';

import styles from './DetailsStep.module.css';

type ListEditorProps = {
  title?: string;
  tooltipText?: string;
  name: string;
  style?: Record<string, any>;
  fieldDisabled?: boolean;
  buttonDisabled?: boolean;
  suggestions?: string[];
};
export const ListEditor = ({
  title,
  tooltipText,
  name,
  style,
  fieldDisabled,
  buttonDisabled,
  suggestions,
}: ListEditorProps) => {
  const { t } = useTranslation();
  const { translationTargetLanguageName, translationTargetLanguage } =
    useTranslationTargetLanguage(t);
  const combinedStyle = {
    maxWidth: 800,
    ...style,
  };
  return (
    <div className={styles['detailInfoEdit']}>
      {title && (
        <ul className={styles['detailInfoEditTtl']}>
          <li className={styles['detailInfo__ttl']}>
            <p>{title}</p>
            {tooltipText && <Tooltip text={tooltipText} />}
          </li>
        </ul>
      )}
      <FieldArray name={name}>
        {({ fields }) =>
          fields.length === 0 ? (
            <>
              {!buttonDisabled && (
                <AddIcon onClick={() => (fields as any).insertAt(0, '')} />
              )}
            </>
          ) : (
            fields.map((name, idx) => (
              <ul key={name} className={styles['detailInfoEditBody']}>
                <TranslatedField name={name}>
                  {({ input, translationInput }) => (
                    <>
                      <li>
                        <div className={baseStyles['base-flex']}>
                          {suggestions && suggestions.length > 0 ? (
                            <Select
                              search
                              allowFreeInput={true}
                              value={input.value}
                              onChange={(e, { value }) => input.onChange(value)}
                              options={suggestions
                                .filter(
                                  (suggestion) =>
                                    !(fields.value ?? []).includes(
                                      suggestion
                                    ) || input.value === suggestion
                                )
                                .map((suggestion) => ({
                                  value: suggestion,
                                  text: suggestion,
                                }))}
                            />
                          ) : (
                            <input
                              {...input}
                              disabled={fieldDisabled}
                              className={baseStyles['base-form-text']}
                              style={combinedStyle}
                            />
                          )}
                          <div className={baseStyles['base-flex']}>
                            {!buttonDisabled && (
                              <>
                                <AddIcon
                                  onClick={() =>
                                    (fields as any).insertAt(idx + 1, '')
                                  }
                                />
                                <DeleteIcon
                                  onClick={() => fields.remove(idx)}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </li>
                      {translationTargetLanguage && (
                        <li>
                          <div className={baseStyles['base-flex']}>
                            <input
                              {...translationInput}
                              placeholder={translationTargetLanguageName}
                              className={baseStyles['base-form-text']}
                              style={combinedStyle}
                            />
                          </div>
                        </li>
                      )}
                    </>
                  )}
                </TranslatedField>
              </ul>
            ))
          )
        }
      </FieldArray>
    </div>
  );
};
