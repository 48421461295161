import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactNode, useEffect, useRef, useState } from 'react';

import baseStyles from 'client/v3-base.module.css';

import styles from './TruncatedTextWithSeeMoreButton.module.css';

interface Props {
  text?: any;
  children?: ReactNode;
  onResize?: () => void;
}

export const TruncatedTextWithSeeMoreButton = ({
  onResize,
  text,
  children,
}: Props) => {
  const { t } = useTranslation();
  const textDisplayRef = useRef<HTMLParagraphElement>(null);

  const [showAllText, setShowAllText] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    const scrollHeight = textDisplayRef.current?.scrollHeight ?? 0;
    const clientHeight = textDisplayRef.current?.clientHeight ?? 0;
    if (scrollHeight > clientHeight) {
      setShowButtons(true);
      onResize?.();
    }
  }, [
    text,
    children,
    onResize,
    textDisplayRef.current?.scrollHeight,
    textDisplayRef.current?.clientHeight,
  ]);

  return (
    <>
      <p
        className={clsx(
          styles['see-more-body'],
          showAllText ? '' : styles['is-close']
        )}
        ref={textDisplayRef}
      >
        {text ?? children}
      </p>
      {showButtons && (
        <div className={styles['see-more-buttons']}>
          <a
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['small'],
              baseStyles['more']
            )}
            onClick={() => {
              setShowAllText(!showAllText);
              onResize?.();
            }}
          >
            {showAllText ? t('Close') : t('See more')}
          </a>
        </div>
      )}
    </>
  );
};
