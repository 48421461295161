import { useTranslation } from 'react-i18next';

import { getWeekdayText } from 'client/libraries/util/weekdays';
import type { Weekday } from 'client/libraries/util/weekdays';
import { Checkbox } from 'client/components/v3/Form/Checkbox';

import styles from './WeekdaysInput.module.css';

const weekdays: Weekday[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

type Props = {
  value: Weekday[];
  onChange: (arg0: Weekday[]) => void;
};

export const WeekdaysInput = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['c-weekdayInput']}>
      {weekdays.map((weekday, idx) => (
        <div key={idx} className={styles['c-weekdayInput__item']}>
          <label>
            <Checkbox
              label={getWeekdayText(weekday as Weekday, t)}
              checked={value?.includes(weekday as Weekday)}
              onChange={() => {
                if (value?.includes(weekday as Weekday)) {
                  onChange(value.filter((w) => w !== weekday));
                } else {
                  onChange(
                    weekdays.filter(
                      (w: Weekday) =>
                        value?.includes(w as Weekday) || w === weekday
                    )
                  );
                }
              }}
              size="sm"
            />
            <p></p>
          </label>
        </div>
      ))}
    </div>
  );
};
