import { SalesOffer, SalesOfferParams } from 'shared/models/swagger';

export interface FormValues {
  title: string;

  status: 'DRAFT' | 'ACTIVE';

  deliveryMedium: 'WEBSITE' | 'EMAIL';

  /* CONTENT */

  offerContentType: 'PRODUCT' | 'COUPON' | 'ANNUAL_PASS' | 'PRODUCT_LIST';

  displayType: 'INLINE' | 'POPUP';

  // if offeringContent is PRODUCT
  productIds: string[];
  promoCode: string;

  // if offeringContent is COUPON
  couponId: string;

  thumbnailUrl: string;

  /* TRIGGER & CONDITIONS */

  displayTriggerType:
    | 'ETICKET_BEFORE_REDEMPTION'
    | 'ETICKET_AFTER_REDEMPTION'
    | 'RESERVATION_THANK_YOU_PAGE'
    | 'ONLINE_GUIDE_PROXIMITY_TO_KEY_LOCATION'
    | 'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION';

  // if displayTriggerType is ONLINE_GUIDE_AFTER_COUPON_REDEMPTION the couponId of the coupon that triggers the offer
  triggerCouponIds: string[];

  // if displayTriggerType is ONLINE_GUIDE_PROXIMITY_TO_KEY_LOCATION
  locationTriggerSettings?: LocationTriggerSettings;

  /* CONDITIONS */

  conditionRules: ConditionRule[];
}

export interface ConditionRule {
  participationStartDate: string;
  participationEndDate: string;

  shouldFilterByBookedProductIds: boolean;
  bookedProductIds: string[];

  shouldFilterByTimeOfDay: boolean;
  // hh:mm
  timeOfDayStart: string;
  // hh:mm
  timeOfDayEnd: string;

  /* OFFER LIMITS */

  limitType: 'NO_LIMIT' | 'EXPIRATION_TIME_OF_DAY' | 'LIMITED_CLAIMS_PER_DAY';

  // if expirationType is EXPIRATION_TIME_OF_DAY
  // hh:mm
  expirationTimeOfDay: string;

  // if expirationType is EXPIRES_AFTER_X_USES
  dailyClaimLimit?: number;
}

interface LocationTriggerSettings {
  locationName: string;
  latitude: number;
  longitude: number;
  activationRange: number;
}

export const convertFormValuesToSwagger = (
  values: FormValues
): SalesOfferParams => {
  return {
    title: values.title,
    status: values.status,
    content: {
      content_type: values.offerContentType,
      product_ids: values.productIds,
      promo_code: values.promoCode,
      coupon_id: values.couponId,
      thumbnail_url: values.thumbnailUrl,
      display_type: values.displayType,
    },
    trigger: {
      display_trigger_type: values.displayTriggerType,
      trigger_coupon_ids: values.triggerCouponIds,
      location_trigger_settings: {
        location_name: values.locationTriggerSettings?.locationName,
        latitude: values.locationTriggerSettings?.latitude,
        longitude: values.locationTriggerSettings?.longitude,
        activation_range: values.locationTriggerSettings?.activationRange,
      },
    },
    condition_rules:
      values?.conditionRules.map((rule) => ({
        participation_start_date: rule.participationStartDate,
        participation_end_date: rule.participationEndDate,
        booked_product_ids: rule.shouldFilterByBookedProductIds
          ? rule.bookedProductIds
          : [],
        time_of_day_start: rule.shouldFilterByTimeOfDay
          ? rule.timeOfDayStart
          : '',
        time_of_day_end: rule.shouldFilterByTimeOfDay ? rule.timeOfDayEnd : '',
        limit_type: rule.limitType,
        expiration_time_of_day: rule.expirationTimeOfDay,
        max_claims_per_day: rule.dailyClaimLimit,
      })) ?? [],
    medium: values.deliveryMedium,
  };
};

export const convertSwaggerToFormValues = (
  salesOffer: SalesOffer
): FormValues => {
  return {
    title: salesOffer.title ?? '',
    status: salesOffer.status ?? 'DRAFT',
    deliveryMedium: salesOffer.medium ?? 'WEBSITE',
    offerContentType: salesOffer.content?.content_type ?? 'PRODUCT',
    displayType: salesOffer.content?.display_type ?? 'POPUP',
    productIds: salesOffer.content?.product_ids ?? [],
    promoCode: salesOffer.content?.promo_code ?? '',
    couponId: salesOffer.content?.coupon_id ?? '',
    thumbnailUrl: salesOffer.content?.thumbnail_url ?? '',
    displayTriggerType:
      salesOffer.trigger?.display_trigger_type ?? 'ETICKET_BEFORE_REDEMPTION',
    locationTriggerSettings:
      salesOffer.trigger?.display_trigger_type ===
      'ONLINE_GUIDE_PROXIMITY_TO_KEY_LOCATION'
        ? {
            locationName:
              salesOffer.trigger?.location_trigger_settings?.location_name ??
              '',
            latitude:
              salesOffer.trigger?.location_trigger_settings?.latitude ?? 0,
            longitude:
              salesOffer.trigger?.location_trigger_settings?.longitude ?? 0,
            activationRange:
              salesOffer.trigger?.location_trigger_settings?.activation_range ??
              0,
          }
        : undefined,
    triggerCouponIds:
      salesOffer.trigger?.display_trigger_type ===
      'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION'
        ? salesOffer.trigger?.trigger_coupon_ids ?? []
        : [],
    conditionRules:
      salesOffer.condition_rules?.map((rule) => ({
        participationStartDate: rule?.participation_start_date ?? '',
        participationEndDate: rule?.participation_end_date ?? '',
        shouldFilterByBookedProductIds: Boolean(
          rule?.booked_product_ids?.length
        ),
        bookedProductIds: rule?.booked_product_ids ?? [],
        shouldFilterByTimeOfDay: Boolean(rule?.time_of_day_start?.length),
        timeOfDayStart: rule?.time_of_day_start ?? '00:00',
        timeOfDayEnd: rule?.time_of_day_end ?? '23:59',
        limitType: rule?.limit_type ?? 'NO_LIMIT',
        expirationTimeOfDay: rule?.expiration_time_of_day ?? '',
        dailyClaimLimit: rule?.max_claims_per_day ?? 0,
      })) ?? [],
  };
};

export const getDefaultConditionRule = (): ConditionRule => ({
  shouldFilterByBookedProductIds: false,
  bookedProductIds: [],
  participationStartDate: '',
  participationEndDate: '',
  shouldFilterByTimeOfDay: false,
  timeOfDayStart: '00:00',
  timeOfDayEnd: '23:59',

  // Limits
  limitType: 'NO_LIMIT',
  expirationTimeOfDay: '23:59',
});
