import * as React from 'react';
import { Field, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import _ from 'lodash';

import { TimePicker } from 'client/components/TimePicker/TimePicker';
import baseStyles from 'client/base.module.css';

import styles from './DeadlineInput.module.css';

type Props = {
  name: string;
  allowAnytimeDeadline?: boolean;
  allowMinutes?: boolean;
};

export const DeadlineInput = ({
  name,
  allowAnytimeDeadline,
  allowMinutes,
}: Props) => {
  const field = useField(name);
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles['input-container']}>
        {field.input.value.deadlineType === 'HOUR' && (
          <label
            className={baseStyles['base-form-select']}
            style={{
              width: '50px',
            }}
          >
            <Field
              name={`${name}.hoursBefore`}
              parse={(value) =>
                typeof value === 'string' ? parseInt(value) : value
              }
            >
              {({ input }) => (
                <select {...input}>
                  {_.times(101).map((daysBefore) => (
                    <option key={daysBefore} value={daysBefore}>
                      {daysBefore}
                    </option>
                  ))}
                </select>
              )}
            </Field>
          </label>
        )}
        {field.input.value.deadlineType === 'DAY' && (
          <label
            className={baseStyles['base-form-select']}
            style={{
              width: '50px',
            }}
          >
            <Field
              name={`${name}.daysBefore`}
              parse={(value) =>
                typeof value === 'string' ? parseInt(value) : value
              }
            >
              {({ input }) => (
                <select {...input}>
                  {_.times(366).map((daysBefore) => (
                    <option key={daysBefore} value={daysBefore}>
                      {daysBefore}
                    </option>
                  ))}
                </select>
              )}
            </Field>
          </label>
        )}
        {field.input.value.deadlineType === 'MINUTE' && (
          <label
            className={baseStyles['base-form-select']}
            style={{
              width: '70px',
            }}
          >
            <Field
              name={`${name}.minutesBefore`}
              parse={(value) =>
                typeof value === 'string' ? parseInt(value) : value
              }
            >
              {({ input }) => (
                <select {...input}>
                  {_.times(100).map((minutesBefore) => (
                    <option key={minutesBefore} value={minutesBefore * 5}>
                      {minutesBefore * 5}
                    </option>
                  ))}
                </select>
              )}
            </Field>
          </label>
        )}
        <label className={baseStyles['base-form-select']}>
          <Field name={`${name}.deadlineType`}>
            {({ input }) => (
              <select
                value={input.value}
                onChange={(e) => {
                  if (e.target.value === 'HOUR') {
                    field.input.onChange({
                      ...field.input.value,
                      deadlineType: 'HOUR',
                      hoursBefore: field.input.value.hoursBefore ?? 24,
                    });
                  } else if (e.target.value === 'DAY') {
                    field.input.onChange({
                      ...field.input.value,
                      deadlineType: 'DAY',
                      daysBefore: field.input.value.daysBefore ?? 1,
                      timeOfDay: field.input.value.timeOfDay ?? '17:00',
                    });
                  } else if (e.target.value === 'MINUTE') {
                    field.input.onChange({
                      ...field.input.value,
                      deadlineType: 'MINUTE',
                      minutesBefore: field.input.value.minutesBefore ?? 30,
                    });
                  } else {
                    field.input.onChange({
                      ...field.input.value,
                      deadlineType: 'ETERNAL',
                    });
                  }
                }}
              >
                {allowMinutes && (
                  <option value="MINUTE">{t('minutes before')}</option>
                )}
                <option value="HOUR">{t('hours before')}</option>
                <option value="DAY">{t('days before')}</option>
                {allowAnytimeDeadline && (
                  <option value="ETERNAL">{t('No deadline')}</option>
                )}
              </select>
            )}
          </Field>
        </label>
        {field.input.value.deadlineType === 'DAY' && (
          <Field name={`${name}.timeOfDay`}>
            {({ input }) => (
              <TimePicker
                allowEmpty={false}
                value={moment(input.value, 'HH:mm')}
                onChange={(newMoment) => {
                  if (!newMoment) {
                    return;
                  }

                  input.onChange(newMoment.format('HH:mm'));
                }}
              />
            )}
          </Field>
        )}
      </div>
    </div>
  );
};
