import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { currency } from 'shared/libraries/currency';
import { ReduxState, localeSelector } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { QuantityGadget } from '../../components/v3/DashboardGadgets/QuantityGadget';
import { ProgressBarGadget } from '../../components/v3/DashboardGadgets/ProgressBarGadget';

import styles from './Gadgets.module.css';
import { GraphGadget } from './GraphGadget';
import { CampaignRankingGadget } from './CampaignRankingGadget';
// import { CampaignDistributionGadget } from './CampaignDistributionGadget';

export const Gadgets = () => {
  const { t } = useTranslation();
  const org = useSelector(activeUserOrganizationSelector);
  const locale = useSelector(localeSelector);

  const reportDataSets = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.reportData
  );

  const loading = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.loading
  );

  const baseDataSet = reportDataSets[0];
  const baseTotals = baseDataSet?.totals;

  const baseGrossSales = baseTotals?.gross_sales ?? '';
  const baseConversions = baseTotals?.conversion_count ?? 0;
  const baseClicks = baseTotals?.click_count ?? 0;
  const baseImpressions = baseTotals?.impression_count ?? 0;
  const baseDeliveries = baseTotals?.delivered_count ?? 0;
  const baseTargets = baseTotals?.targeted_count ?? 0;
  const baseConversionRate =
    baseImpressions > 0 ? (100 * baseConversions) / baseTargets : 0;
  const baseClickRate =
    baseImpressions > 0 ? (100 * baseClicks) / baseDeliveries : 0;
  const baseAveragePurchaseValue = baseConversions
    ? currency(baseGrossSales)?.value / baseConversions
    : 0;

  const comparisonDataSet =
    reportDataSets?.length > 1 ? reportDataSets[1] : null;
  const comparisonTotals = comparisonDataSet?.totals;

  const comparisonGrossSales = comparisonTotals?.gross_sales ?? '';
  const comparisonConversions = comparisonTotals?.conversion_count ?? 0;
  const comparisonClicks = comparisonTotals?.click_count ?? 0;
  const comparisonImpressions = comparisonTotals?.impression_count ?? 0;
  const comparisonDeliveries = comparisonTotals?.delivered_count ?? 0;
  const comparisonTargets = comparisonTotals?.targeted_count ?? 0;
  const comparisonConversionRate =
    comparisonImpressions > 0
      ? (100 * comparisonConversions) / comparisonTargets
      : 0;
  const comparisonClickRate =
    comparisonImpressions > 0
      ? (100 * comparisonClicks) / comparisonDeliveries
      : 0;
  const comparisonAveragePurchaseValue =
    comparisonConversions > 0
      ? currency(comparisonGrossSales)?.value / comparisonConversions
      : 0;

  return (
    <div className={styles['container']}>
      <div className={styles['container-row']}>
        <QuantityGadget
          header={t('Targets')}
          unit={locale === 'ja' ? '件' : ''}
          amount={baseTargets}
          changeAmount={
            comparisonDataSet ? baseTargets - comparisonTargets : null
          }
        />
        <QuantityGadget
          header={t('Impressions')}
          unit={locale === 'ja' ? '回' : ''}
          amount={baseImpressions}
          changeAmount={
            comparisonDataSet ? baseImpressions - comparisonImpressions : null
          }
        />
        <QuantityGadget
          header={t('Clicks')}
          unit={locale === 'ja' ? '回' : ''}
          amount={baseClicks}
          changeAmount={
            comparisonDataSet ? baseClicks - comparisonClicks : null
          }
        />
      </div>
      <div className={styles['container-row']}>
        <ProgressBarGadget
          header={t('Click Rate')}
          percent={baseClickRate}
          percentChange={
            comparisonDataSet ? baseClickRate - comparisonClickRate : null
          }
        />
        <QuantityGadget
          header={t('Bookings')}
          unit={locale === 'ja' ? '件' : ''}
          amount={baseConversions}
          changeAmount={
            comparisonDataSet ? baseConversions - comparisonConversions : null
          }
          loading={loading}
        />
        <ProgressBarGadget
          header={t('Conversion Rate')}
          percent={baseConversionRate}
          percentChange={
            comparisonDataSet
              ? baseConversionRate - comparisonConversionRate
              : null
          }
        />
      </div>
      <div className={styles['container-row']}>
        <QuantityGadget
          header={t('Sales')}
          unit={org?.default_currency ?? ''}
          amount={currency(baseGrossSales ?? '').value}
          changeAmount={
            comparisonDataSet
              ? currency(baseGrossSales ?? '').subtract(
                  currency(comparisonGrossSales ?? '')
                ).value
              : null
          }
          numberFormatter={(value) =>
            currency(value, org?.default_currency).format().substring(3)
          }
          loading={loading}
        />
        <QuantityGadget
          header={t('Average Purchase Value')}
          unit={org?.default_currency ?? ''}
          amount={baseAveragePurchaseValue}
          changeAmount={
            comparisonDataSet
              ? baseAveragePurchaseValue - comparisonAveragePurchaseValue
              : null
          }
          numberFormatter={(value) =>
            currency(value, org?.default_currency).format().substring(3)
          }
        />
      </div>
      <div className={styles['container-row']}>
        <GraphGadget />
      </div>
      <div className={styles['container-row']}>
        <CampaignRankingGadget />
      </div>
      {/*
      <div className={styles['container-row']}>
        <CampaignDistributionGadget />
      </div>
      */}
    </div>
  );
};
