import type {
  AdjustedUnitPrice,
  Channel,
  DayOfWeek,
  FamilyDiscount,
  GroupDiscount,
  Pricing,
  ProductPromotion,
  Promotion,
  PromotionStatus,
  PromotionType,
  RelativeDateTime,
  UnitPriceDelta,
} from 'shared/models/swagger';

export interface DateRange {
  startDate: string;
  endDate: string;
}

export type PromotionShape = {
  id: string;
  appliesToAllProducts: boolean;
  productId: string;
  productName: string;
  type: PromotionType;
  status: PromotionStatus;
  startDateLocalFrom: string;
  startDateLocalTo: string;
  participationDateRanges: DateRange[];
  participationDaysOfWeek: DayOfWeek[];
  bookedDateLocalFrom: string;
  bookedDateLocalTo: string;
  unitPriceDeltas: UnitPriceDelta[];
  earlyBirdDeadline?: RelativeDateTime;
  lastMinuteStart?: RelativeDateTime;
  familyDiscount?: FamilyDiscount;
  groupDiscount?: GroupDiscount;
  channels: Channel[];
  promoCode: string;
  useOneTimePromoCode: boolean;
  referenceLabel: string;
};
type SimpleDiscount = {
  grossAmountDiscount: string;
  netAmountDiscount: string;
  percentDiscount: number;
};
export type PromotionConditions = {
  simpleDiscount?: SimpleDiscount;
};
export const toPromotionShape = (promotion: Promotion): PromotionShape => {
  return {
    id: promotion.id || '',
    appliesToAllProducts: !promotion.product_id,
    productName: promotion.product_name || '',
    productId: promotion.product_id || '',
    type: promotion.type,
    status: promotion.status,
    startDateLocalFrom: promotion.start_date_local_from || '',
    startDateLocalTo: promotion.start_date_local_to || '',
    participationDateRanges: (promotion.participation_date_ranges || [])?.map(
      (dateRange) => ({
        startDate: dateRange.start_date_local || '',
        endDate: dateRange.end_date_local || '',
      })
    ),
    participationDaysOfWeek: promotion.participation_days_of_week || [],
    bookedDateLocalFrom: promotion.booked_date_local_from || '',
    bookedDateLocalTo: promotion.booked_date_local_to || '',
    unitPriceDeltas: promotion.unit_price_deltas || [],
    earlyBirdDeadline: promotion.early_bird_deadline,
    lastMinuteStart: promotion.last_minute_start,
    familyDiscount: promotion.family_discount,
    groupDiscount: promotion.group_discount,
    channels: promotion.channels || [],
    promoCode: promotion.promo_code || '',
    useOneTimePromoCode: promotion.use_one_time_promo_code || false,
    referenceLabel: promotion.reference_label || '',
  };
};
export type ProductPromotionShape = {
  type: PromotionType;
  startDateLocalFrom: string;
  startDateLocalTo: string;
  bookedDateLocalFrom: string;
  bookedDateLocalTo: string;
  adjustedUnitPrices: AdjustedUnitPrice[];
  earlyBirdDeadline?: RelativeDateTime;
  lastMinuteStart?: RelativeDateTime;
  familyDiscount?: FamilyDiscount;
  groupDiscount?: GroupDiscount;
  channels: Channel[];
  promoCode: string;
};
export const toProductPromotionShape = (
  promotion: ProductPromotion
): ProductPromotionShape => {
  return {
    type: promotion.type || 'SIMPLE_DISCOUNT',
    startDateLocalFrom: promotion.start_date_local_from || '',
    startDateLocalTo: promotion.start_date_local_to || '',
    bookedDateLocalFrom: promotion.booked_date_local_from || '',
    bookedDateLocalTo: promotion.booked_date_local_to || '',
    adjustedUnitPrices: promotion.adjusted_unit_prices || [],
    earlyBirdDeadline: promotion.early_bird_deadline,
    lastMinuteStart: promotion.last_minute_start,
    familyDiscount: promotion.family_discount,
    groupDiscount: promotion.group_discount,
    channels: promotion.channels || [],
    promoCode: promotion.promo_code || '',
  };
};

export const getPriceSchedulesActiveInDateRanges = (
  priceSchedules: Pricing[],
  dateRanges: DateRange[]
): Pricing[] => {
  if (!dateRanges.length) return priceSchedules;

  return priceSchedules.filter((priceSched) => {
    const priceStartDateLocal = priceSched.start_date_local;
    const priceEndDateLocal = priceSched.end_date_local;
    return dateRanges.some((dateRange) =>
      dateRangesOverlap(dateRange, {
        startDate: priceStartDateLocal ?? '',
        endDate: priceEndDateLocal ?? '',
      })
    );
  });
};

export const dateRangesOverlap = (
  dateRange1: DateRange,
  dateRange2: DateRange
): boolean => {
  return (
    (!dateRange1.endDate ||
      !dateRange2.startDate ||
      dateRange1.endDate >= dateRange2.startDate) &&
    (!dateRange1.startDate ||
      !dateRange2.endDate ||
      dateRange1.startDate <= dateRange2.endDate)
  );
};
