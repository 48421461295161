import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { fetchETicketFootprints } from 'client/actions/eTicketFootprints';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import type { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import newWindowIcon from 'client/images/ic_newwindow.svg';

import styles from './ReservationFootprintTab.module.css';

type Props = {
  reservation?: Reservation;
};

export const ReservationFootprintTab = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const eTicketFootprints = useSelector(
    (state: ReduxState) => state.eTicketFootprints.all
  );

  React.useEffect(() => {
    dispatch(
      fetchETicketFootprints({
        reservation_id: reservation?.id ?? '',
      })
    );
  }, []);

  const footprints = React.useMemo(() => {
    return eTicketFootprints
      .map((record) => {
        let nearestLocationNameOrStubText = '';
        if (record.reservation_is_free_start_date_time && record.stub_text) {
          let text = t('Free Pass');
          if (record.stub_text) {
            text += `(${record.stub_text})`;
          }
          nearestLocationNameOrStubText = text;
        } else {
          nearestLocationNameOrStubText = record.nearest_location_name ?? '';
        }

        return {
          id: record.id,
          dateTime: record?.date_time_utc
            ? moment.tz(
                record.date_time_utc,
                reservation?.start_timezone ?? 'UTC'
              )
            : null,
          nearestLocationName: nearestLocationNameOrStubText,
          latitude: record.latitude,
          longitude: record.longitude,
        };
      })
      .sort((a, b) => {
        if (!a.dateTime) {
          return 1;
        }

        if (!b.dateTime) {
          return -1;
        }

        if (a.dateTime.isBefore(b.dateTime)) {
          return -1;
        } else {
          return 1;
        }
      });
  }, [eTicketFootprints, reservation]);

  return (
    <div className={clsx(baseStyles['base-main__body__box'])}>
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('E-ticket View history')}
        </div>
        {(footprints ?? []).length > 0 && (
          <a
            href={`https://www.google.com/maps/dir/${footprints
              .filter((footprint) => footprint.latitude && footprint.longitude)
              .map(
                (footprint) => `${footprint.latitude},${footprint.longitude}`
              )
              .join('/')}`}
            target="_blank"
            rel="noreferrer"
            className={clsx(baseStyles['link'])}
          >
            <Box ml={1}>
              <span
                className={clsx(
                  baseStyles['base-btn'],
                  baseStyles['middle'],
                  baseStyles['blue']
                )}
              >
                <p className={clsx(baseStyles['semantic-ui-react-override'])}>
                  {t('Show route on Google Maps')}
                </p>
                <img src={newWindowIcon} />
              </span>
            </Box>
          </a>
        )}
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <div className={clsx(styles['c-table-nowrap'])}>
          <table>
            <tbody>
              <tr>
                <th
                  className={clsx(
                    baseStyles['base-t-192'],
                    styles['center-align']
                  )}
                >
                  {t('Date/Time')}
                </th>
                <th
                  className={clsx(
                    styles['c-table-latlong'],
                    styles['center-align']
                  )}
                >
                  {t('Latitude') + ' / ' + t('Longitude')}
                </th>
                <th
                  className={clsx(
                    styles['c-table-place'],
                    styles['center-align']
                  )}
                >
                  {t('Nearest Location Name') + ' / ' + t('Ticket Usage')}
                </th>
              </tr>
              {footprints.map((footprint, idx) => {
                return (
                  <tr key={idx}>
                    <td className={clsx(styles['center-align'])}>
                      {footprint.dateTime?.format('lll') ?? ''}
                    </td>
                    <td className={clsx(styles['center-align'])}>
                      {footprint.latitude && footprint.longitude && (
                        <a
                          href={`https://www.google.com/maps?q=${footprint.latitude},${footprint.longitude}`}
                          target="_blank"
                          rel="noreferrer"
                          className={clsx(baseStyles['link'])}
                        >
                          {footprint.latitude}, {footprint.longitude}
                        </a>
                      )}
                    </td>
                    <td
                      className={clsx(
                        baseStyles['base-t-192'],
                        styles['center-align']
                      )}
                    >
                      {footprint.nearestLocationName}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
