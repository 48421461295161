import * as React from 'react';

import styles from 'client/base.module.css';

type Props = {
  error?: string;
  label: string | React.ReactNode;
  onChange: (
    arg0: React.SyntheticEvent<Record<string, any>>,
    arg1: {
      value: string;
    }
  ) => void;
  checked: boolean;
  name?: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
};
export const Radio = ({
  error,
  label,
  onChange,
  checked,
  name,
  value,
  disabled,
}: Props) => {
  return (
    <div className={styles['base-form-box']}>
      <div className={styles['base-form-box__body']}>
        <div className={styles['base-form-radio']}>
          <label>
            <input
              onChange={(e) =>
                onChange(e, {
                  value: e.target.dataset.value as string,
                })
              }
              type="radio"
              name={name || ''}
              value={value || ''}
              checked={checked}
              data-value={value || ''}
              disabled={disabled || false}
            />
            <p></p>
            {label}
          </label>
        </div>
        {error && <p className={styles['base-form-box__err']}>{error}</p>}
      </div>
    </div>
  );
};
