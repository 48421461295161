import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { AccessReportDataSet } from 'shared/models/swagger';
import styles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  dataSets: AccessReportDataSet[];
};
export const CompareTablePane = ({ dataSets }: Props) => {
  const { t } = useTranslation();

  if (dataSets?.length !== 2) {
    return <div>Invalid data</div>;
  }

  const datas = dataSets.map((dataSet) => {
    let totalVisitCount = 0;
    let totalUserCount = 0;
    let totalReservationCount = 0;
    (dataSet?.items || []).forEach((item) => {
      totalVisitCount += item?.visit_count || 0;
      totalUserCount += item?.user_count || 0;
      totalReservationCount += item?.reservation_count || 0;
    });
    const ratio = totalUserCount ? totalReservationCount / totalUserCount : 0;
    return {
      start: dataSet.range?.start,
      end: dataSet.range?.end,
      totalVisitCount,
      totalUserCount,
      totalReservationCount,
      ratio,
    };
  });

  const calcRatio = (compare: number, base: number): string => {
    return `${compare - base > 0 ? '+' : ''}${(
      ((compare - base) / base) *
      100
    ).toFixed(2)} %`;
  };

  return (
    <table className={clsx(styles['c-tableSmall'], styles['row'])}>
      <thead>
        <tr>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {t('Date')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('visitCount')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('userCount')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('reservationCount')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('Conversion Rate')}
          </th>
        </tr>
      </thead>
      <tbody>
        {datas.map((data, i) => {
          return (
            <tr key={i}>
              <td>
                {data.start} ~ {data.end}
              </td>
              <td style={{ textAlign: 'right' }}>{data.totalVisitCount}</td>
              <td style={{ textAlign: 'right' }}>{data.totalUserCount}</td>
              <td style={{ textAlign: 'right' }}>
                {data.totalReservationCount}
              </td>
              <td style={{ textAlign: 'right' }}>
                {(data.ratio * 100).toFixed(1)} %
              </td>
            </tr>
          );
        })}
        <tr>
          <td></td>
          <td style={{ textAlign: 'right' }}>
            {calcRatio(datas[1].totalVisitCount, datas[0].totalVisitCount)}
          </td>
          <td style={{ textAlign: 'right' }}>
            {calcRatio(datas[1].totalUserCount, datas[0].totalUserCount)}
          </td>
          <td style={{ textAlign: 'right' }}>
            {calcRatio(
              datas[1].totalReservationCount,
              datas[0].totalReservationCount
            )}
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};
