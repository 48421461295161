import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import moment from 'moment-timezone';

import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { updateReservation } from 'client/actions/reservations';
import { getArrayMutators } from 'client/libraries/util/form';
import { Button } from 'client/components/Form/Button';
import { Modal } from 'client/components/Modal/Modal';
import { LocationSearchInput } from 'client/components/LocationSearchInput';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import {
  convertFormValuesToReservationPatch,
  getInitialValues,
} from 'client/formValues/ReservationTransportRouteFormValues';
import type { FormValues } from 'client/formValues/ReservationTransportRouteFormValues';

import { ReservationContext } from '../ReservationContext';

import styles from './EditTransportRouteModal.module.css';

type Props = {
  open: boolean;
  onClose: () => void;
};
export const EditTransportRouteModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reservation = React.useContext(ReservationContext);
  const initialValues = React.useMemo(
    () => getInitialValues(reservation),
    [reservation]
  );
  return (
    <Modal open={open} onClose={onClose} title={t('Edit Transport Routes')}>
      <Form
        onSubmit={async (values: FormValues) => {
          const patch: any = convertFormValuesToReservationPatch(
            values,
            reservation?.start_date_time_utc ?? '',
            reservation?.start_timezone ?? ''
          );
          await dispatch(updateReservation(reservation?.id ?? '', patch));
          onClose?.();
        }}
        initialValues={initialValues}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <FormTableBox>
                <table>
                  <tbody>
                    <FieldArray name="transportRouteItems">
                      {({ fields }) =>
                        fields.map((name, idx) => (
                          <>
                            <tr>
                              <th
                                rowSpan={2}
                                className={styles['table-cell-minimal']}
                              >{`#${idx + 1}`}</th>
                              <td className={styles['table-cell-short']}>
                                {t('Departure')}
                              </td>
                              <td className={styles['table-cell-short']}>
                                <Field name={`${name}.locationFrom.hhMm`}>
                                  {({ input }) =>
                                    idx === 0 ? (
                                      <div>{input.value}</div>
                                    ) : (
                                      <TimePicker
                                        allowEmpty={false}
                                        value={moment(input.value, 'HH:mm')}
                                        onChange={(newValue) => {
                                          if (!newValue) {
                                            return;
                                          }

                                          input.onChange(
                                            newValue.format('HH:mm')
                                          );
                                        }}
                                      />
                                    )
                                  }
                                </Field>
                              </td>
                              <td>
                                <Field
                                  name={`${name}.locationFrom.locationName`}
                                >
                                  {({ input }) =>
                                    idx === 0 ? (
                                      <div>{input.value}</div>
                                    ) : (
                                      <LocationSearchInput
                                        location={input.value}
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        onSearchChange={() => {}}
                                        onLocationSelect={({
                                          title,
                                          key,
                                        }: {
                                          title: string;
                                          key: string;
                                        }) => {
                                          input.onChange(title);
                                          form.change(
                                            `${name}.locationFrom.googlePlaceId` as any,
                                            key
                                          );
                                        }}
                                      />
                                    )
                                  }
                                </Field>
                              </td>
                            </tr>
                            <tr>
                              <td>{t('Arrival')}</td>
                              <td>
                                <Field name={`${name}.locationTo.hhMm`}>
                                  {({ input }) =>
                                    idx === (fields?.value ?? []).length - 1 ? (
                                      <div>{input.value}</div>
                                    ) : (
                                      <TimePicker
                                        allowEmpty={false}
                                        value={moment(input.value, 'HH:mm')}
                                        onChange={(newValue) => {
                                          if (!newValue) {
                                            return;
                                          }

                                          input.onChange(
                                            newValue.format('HH:mm')
                                          );
                                        }}
                                      />
                                    )
                                  }
                                </Field>
                              </td>
                              <td>
                                <Field name={`${name}.locationTo.locationName`}>
                                  {({ input }) =>
                                    idx === (fields?.value ?? []).length - 1 ? (
                                      <div>{input.value}</div>
                                    ) : (
                                      <LocationSearchInput
                                        location={input.value}
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        onSearchChange={() => {}}
                                        onLocationSelect={({
                                          title,
                                          key,
                                        }: {
                                          title: string;
                                          key: string;
                                        }) => {
                                          input.onChange(title);
                                          form.change(
                                            `${name}.locationFrom.googlePlaceId` as any,
                                            key
                                          );
                                        }}
                                      />
                                    )
                                  }
                                </Field>
                              </td>
                            </tr>
                          </>
                        ))
                      }
                    </FieldArray>
                  </tbody>
                </table>
              </FormTableBox>
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
