import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { TranslateFuncType } from 'client/components/Translate';
import { TextField } from 'client/components/v3/Form/TextField';
import { putDashboardSettings } from 'client/actions/dashboardSettings';
import { ReduxState } from 'client/reducers';
import { DashboardGadget } from 'client/reducers/dashboardSettings';
import { ReservationReportGadgetEditor } from 'client/pages/v3/FlexibleDashboard/ReservationReportGadget/ReservationReportGadgetEditor';
import { AccessReportGadgetEditor } from 'client/pages/v3/FlexibleDashboard/AccessReportGadget/AccessReportGadgetEditor';
import styles from 'client/pages/v3/FlexibleDashboard/FlexibleDashboard.module.css';
import {
  checkIfAccessReportGadgetParamsHaveChanged,
  checkIfAccessSummaryGadgetParamsHaveChanged,
  checkIfReservationReportGadgetParamsHaveChanged,
  checkIfReservationSummaryListGadgetParamsHaveChanged,
  getAccessReportGadgetInitialParams,
  getCustomGadgetKey,
  getReservationListGadgetInitialParams,
  getReservationReportGadgetInitialParams,
  getReservationSummaryGadgetInitialParams,
} from 'client/pages/v3/FlexibleDashboard/util';
import { ReservationSummaryGadgetEditor } from 'client/pages/v3/FlexibleDashboard/ReservationSummaryGadget/ReservationSummaryGadgetEditor';
import { AccessSummaryGadgetEditor } from 'client/pages/v3/FlexibleDashboard/AccessSummaryGadget/AccessSummaryGadgetEditor';
import { ReservationListGadgetEditor } from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/ReservationListGadgetEditor';

const getEditModalTitle = (
  editingGadget: DashboardGadget,
  t: TranslateFuncType
) => {
  switch (editingGadget.gadgetType) {
    case 'access-report':
      return t('Edit Access Report');
    case 'access-summary':
      return t('Edit Access Summary');
    case 'reservation-report':
      return t('Edit Reservation Report');
    case 'reservation-summary':
      return t('Edit Reservation Summary');
    case 'reservation-list':
      return t('Edit Reservation List');
    default:
      return '';
  }
};

interface Props {
  onClose: () => void;
  editingGadget: DashboardGadget;
}

const checkIfGadgetParamsHaveChanged = (
  gadget: DashboardGadget,
  t: TranslateFuncType
) => {
  switch (gadget.gadgetType) {
    case 'reservation-report':
      return checkIfReservationReportGadgetParamsHaveChanged(
        getReservationReportGadgetInitialParams(
          gadget.params.dataType,
          gadget.params.displayType
        ),
        gadget.params
      );
    case 'access-report':
      return checkIfAccessReportGadgetParamsHaveChanged(
        getAccessReportGadgetInitialParams(
          gadget.params.dataType,
          gadget.params.displayType
        ),
        gadget.params
      );
    case 'access-summary':
      return checkIfAccessSummaryGadgetParamsHaveChanged(
        { dateRange: 'PREV_7_DAYS' },
        gadget.params
      );
    case 'reservation-summary':
      return checkIfReservationSummaryListGadgetParamsHaveChanged(
        getReservationSummaryGadgetInitialParams(t),
        gadget.params
      );
    case 'reservation-list':
      return checkIfReservationSummaryListGadgetParamsHaveChanged(
        getReservationListGadgetInitialParams(),
        gadget.params
      );
  }
};

export const GadgetEditorModal = ({ onClose, editingGadget }: Props) => {
  const { t } = useTranslation();

  const existingSettings = useSelector(
    (state: ReduxState) => state.dashboardSettings.data
  );
  const loading = useSelector(
    (state: ReduxState) => state.dashboardSettings.loading
  );

  const dispatch = useDispatch();

  return (
    <Form
      initialValues={{
        ...editingGadget,
      }}
      onSubmit={async (values: DashboardGadget) => {
        // Determine if gadget should be saved as custom
        let newValues: DashboardGadget;
        if (!editingGadget.key.includes('custom')) {
          const paramsHaveChanged = checkIfGadgetParamsHaveChanged(values, t);

          // Set new key
          if (paramsHaveChanged) {
            const key = getCustomGadgetKey(
              values.gadgetType,
              values.gadgetType === 'reservation-report' ||
                values.gadgetType === 'access-report'
                ? values.params.dataType
                : undefined,
              values.gadgetType === 'reservation-report' ||
                values.gadgetType === 'access-report'
                ? values.params.displayType
                : undefined
            );

            newValues = {
              ...values,
              key: key,
            };
          }
        }

        const newSettings = {
          gadgets: existingSettings?.gadgets
            ? existingSettings.gadgets.map((gadget: DashboardGadget) =>
                gadget.key === editingGadget.key ? newValues ?? values : gadget
              )
            : [values],
        };

        await dispatch(putDashboardSettings(JSON.stringify(newSettings)));
        await onClose();
      }}
    >
      {({ handleSubmit, values, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={getEditModalTitle(editingGadget, t)}
            open={true}
            onClose={onClose}
            rightActionChildren={
              <>
                <Button
                  text={t('Cancel')}
                  size="md"
                  color="white"
                  onClick={onClose}
                />
                <Button
                  text={t('Save')}
                  type="submit"
                  loading={loading || submitting}
                />
              </>
            }
            // Limit width of modal because multi-select may make the modal width change
            style={{ maxWidth: '600px', height: '600px' }}
          >
            <ul className={styles['p-dashboardModal']}>
              <li className={styles['p-dashboardModal__item']}>
                <p className={styles['p-dashboardModal__item__ttl']}>
                  {t('Title')}
                </p>
                <div className={styles['p-dashboardModal__item__body']}>
                  <Field name="title">
                    {({ input }) => (
                      <TextField
                        value={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              </li>
              {values.gadgetType === 'reservation-report' && (
                <ReservationReportGadgetEditor />
              )}
              {values.gadgetType === 'access-report' && (
                <AccessReportGadgetEditor />
              )}
              {values.gadgetType === 'access-summary' && (
                <AccessSummaryGadgetEditor />
              )}
              {values.gadgetType === 'reservation-summary' && (
                <ReservationSummaryGadgetEditor />
              )}
              {values.gadgetType === 'reservation-list' && (
                <ReservationListGadgetEditor />
              )}
            </ul>
          </Modal>
        </form>
      )}
    </Form>
  );
};
