/**
 * This code was generated by Builder.io.
 */
import React from 'react';

import styles from './FeedbackModule.module.css';
import StarRating from './StarRating';

interface AverageRatingProps {
  rating: number;
  count: number;
}

const AverageRating: React.FC<AverageRatingProps> = ({ rating, count }) => (
  <section className={styles.averageRatingSection}>
    <h2 className={styles.averageRatingTitle}>average rating</h2>
    <div className={styles.ratingWrapper}>
      <span className={styles.ratingValue}>{rating.toFixed(1)}</span>
      <span className={styles.ratingCount}>({count})</span>
      <StarRating rating={rating} />
    </div>
  </section>
);

export default AverageRating;
