import * as React from 'react';

import { ListItem } from './ListItem';

type Item<T> = {
  key: T;
  text: string;
};
type Props<T> = {
  items: Item<T>[];
  setItems: (items: Item<T>[]) => void;
};
export const DndList = ({ items, setItems }: Props<any>) => {
  const moveItem = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = items[dragIndex];
      const newSelectedItems = [...items];
      newSelectedItems.splice(dragIndex, 1);
      newSelectedItems.splice(hoverIndex, 0, dragItem);
      setItems(newSelectedItems);
    },
    [items, setItems]
  );
  return (
    <>
      {items.map((item, idx) => (
        <ListItem
          key={item.key}
          text={item.text}
          index={idx}
          id={item}
          moveItem={moveItem}
        />
      ))}
    </>
  );
};
