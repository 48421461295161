import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_MEDIA_DOWNLOAD_PRICES_SUCCESS,
  FETCH_MEDIA_DOWNLOAD_PRICES_FAILURE,
  FETCH_MEDIA_DOWNLOAD_PRICES_REQUEST,
  DELETE_MEDIA_DOWNLOAD_PRICE_FAILURE,
  DELETE_MEDIA_DOWNLOAD_PRICE_REQUEST,
  DELETE_MEDIA_DOWNLOAD_PRICE_SUCCESS,
  CREATE_MEDIA_DOWNLOAD_PRICE_REQUEST,
  UPDATE_MEDIA_DOWNLOAD_PRICE_FAILURE,
  UPDATE_MEDIA_DOWNLOAD_PRICE_SUCCESS,
  UPDATE_MEDIA_DOWNLOAD_PRICE_REQUEST,
  CREATE_MEDIA_DOWNLOAD_PRICE_FAILURE,
  CREATE_MEDIA_DOWNLOAD_PRICE_SUCCESS,
} from 'client/constants/ActionTypes';
import { ReduxState } from 'client/reducers';
import {
  NewMediaDownloadPrice,
  MediaDownloadPrice,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchMediaDownloadPricesRequest = () => ({
  type: FETCH_MEDIA_DOWNLOAD_PRICES_REQUEST,
});

const fetchMediaDownloadPricesSuccess = (response: any) => ({
  type: FETCH_MEDIA_DOWNLOAD_PRICES_SUCCESS,
  response,
});

const fetchMediaDownloadPricesFailure = (error: any) => ({
  type: FETCH_MEDIA_DOWNLOAD_PRICES_FAILURE,
  error,
});

export const fetchMediaDownloadPrices =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchMediaDownloadPricesRequest());
    axios
      .get('/api/mediadownloadprices', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchMediaDownloadPricesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchMediaDownloadPricesFailure('canceled'));
        else dispatch(fetchMediaDownloadPricesFailure(error));
      });
  };

const deleteMediaDownloadPriceRequest = (id: string) => ({
  type: DELETE_MEDIA_DOWNLOAD_PRICE_REQUEST,
  id,
});

const deleteMediaDownloadPriceSuccess = (response: any, id: string) => ({
  type: DELETE_MEDIA_DOWNLOAD_PRICE_SUCCESS,
  response,
  id,
});

const deleteMediaDownloadPriceFailure = (error: any) => ({
  type: DELETE_MEDIA_DOWNLOAD_PRICE_FAILURE,
  error,
});

export const deleteMediaDownloadPrice =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteMediaDownloadPriceRequest(id));
    axios
      .delete(`/api/mediadownloadprices/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) =>
        dispatch(deleteMediaDownloadPriceSuccess(result.data, id))
      )
      .catch((error) => dispatch(deleteMediaDownloadPriceFailure(error)));
  };

const createMediaDownloadPriceRequest = (
  newMediaDownloadPrice: NewMediaDownloadPrice
) => ({
  type: CREATE_MEDIA_DOWNLOAD_PRICE_REQUEST,
  newMediaDownloadPrice,
});

const createMediaDownloadPriceSuccess = (response: any) => ({
  type: CREATE_MEDIA_DOWNLOAD_PRICE_SUCCESS,
  response,
});

const createMediaDownloadPriceFailure = (error: any) => ({
  type: CREATE_MEDIA_DOWNLOAD_PRICE_FAILURE,
  error,
});

export const createMediaDownloadPrice =
  (newMediaDownloadPrice: NewMediaDownloadPrice) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createMediaDownloadPriceRequest(newMediaDownloadPrice));
    return axios
      .post('/api/mediadownloadprices', newMediaDownloadPrice, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createMediaDownloadPriceSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createMediaDownloadPriceFailure(err.message));
      });
  };

const updateMediaDownloadPriceRequest = (
  id: string,
  patch: MediaDownloadPrice
) => ({
  type: UPDATE_MEDIA_DOWNLOAD_PRICE_REQUEST,
  id,
  patch,
});

const updateMediaDownloadPriceSuccess = (response: any) => ({
  type: UPDATE_MEDIA_DOWNLOAD_PRICE_SUCCESS,
  response,
});

const updateMediaDownloadPriceFailure = (error: any) => ({
  type: UPDATE_MEDIA_DOWNLOAD_PRICE_FAILURE,
  error,
});

export const updateMediaDownloadPrice =
  (id: string, patch: MediaDownloadPrice) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateMediaDownloadPriceRequest(id, patch));
    return axios
      .patch(`/api/mediadownloadprices/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateMediaDownloadPriceSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateMediaDownloadPriceFailure(err.message));
      });
  };
