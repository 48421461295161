import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_MEMO_FAILURE,
  CREATE_MEMO_REQUEST,
  CREATE_MEMO_SUCCESS,
  DELETE_MEMO_FAILURE,
  DELETE_MEMO_REQUEST,
  DELETE_MEMO_SUCCESS,
  FETCH_MEMOS_FAILURE,
  FETCH_MEMOS_REQUEST,
  FETCH_MEMOS_SUCCESS,
  UPDATE_MEMO_FAILURE,
  UPDATE_MEMO_REQUEST,
  UPDATE_MEMO_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  Memo,
  CreateMemoResponse,
  ListMemosResponse,
  UpdateMemoResponse,
} from 'shared/models/swagger';

export const memosSelector = (state: ReduxState) => state.memos.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_MEMOS_FAILURE:
    case CREATE_MEMO_FAILURE:
    case UPDATE_MEMO_FAILURE:
    case DELETE_MEMO_FAILURE:
      return action.error;

    case FETCH_MEMOS_SUCCESS:
    case CREATE_MEMO_SUCCESS:
    case UPDATE_MEMO_SUCCESS:
    case DELETE_MEMO_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_MEMOS_REQUEST:
    case CREATE_MEMO_REQUEST:
    case UPDATE_MEMO_REQUEST:
    case DELETE_MEMO_REQUEST:
      return true;

    case FETCH_MEMOS_FAILURE:
    case CREATE_MEMO_FAILURE:
    case UPDATE_MEMO_FAILURE:
    case DELETE_MEMO_FAILURE:
    case FETCH_MEMOS_SUCCESS:
    case CREATE_MEMO_SUCCESS:
    case UPDATE_MEMO_SUCCESS:
    case DELETE_MEMO_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: Memo[] = [], action: any): Memo[] => {
  switch (action.type) {
    case FETCH_MEMOS_SUCCESS: {
      const response = action.response as ListMemosResponse;
      const newIds = response.memos.map((memo) => memo.id);
      return [
        ...state.filter((i) => !newIds.includes(i.id)),
        ...response.memos,
      ];
    }

    case CREATE_MEMO_SUCCESS: {
      const response = action.response as CreateMemoResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_MEMO_SUCCESS: {
      const response = action.response as UpdateMemoResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_MEMO_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const memos = combineReducers({
  error,
  loading,
  all,
});
