export const getBookingWidgetEmbeddedCheckoutUrl = (
  apiKey: string | null,
  productId: string,
  productInstanceId: string,
  guestCount: string,
  contentLanguage: string
): string => {
  const bookingWidgetBaseUrl = window.location.origin
    .toString()
    .replace('localhost:3000', 'dev.ntmg.com')
    .replace(/https?:\/\/(app\.)?/, 'https://book.');

  if (bookingWidgetBaseUrl && apiKey && productId && guestCount) {
    const params = new URLSearchParams();

    params.set('productInstanceId', productInstanceId);
    params.set('guestCount', guestCount);
    params.set('lng', contentLanguage);

    // Flag to prevent order
    params.set('preventOrder', 'true');

    return `${bookingWidgetBaseUrl}/embed/${apiKey}/products/${productId}/checkout?${params.toString()}`;
  }

  return '';
};
