import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { ProductInstanceModal } from 'client/pages/v3/Availability/Shared/Modal/ProductInstanceModal';
import { ReduxState } from 'client/reducers';
import { summariesSelector } from 'client/reducers/products';
import {
  fetchProductInstanceByID,
  fetchProductInstancesForProducts,
} from 'client/actions/productInstances';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import {
  ProductInstanceShape,
  toProductInstanceShape,
} from 'client/libraries/util/productInstanceShape';
import {
  ProductInstance,
  ProductDateAvailability,
  StartTimeAvailability,
} from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import stopIcon from 'client/images/ic_stop.svg';
import rqIcon from 'client/images/ic_rq.svg';
import {
  getAvailabilityIconType,
  ProductWithOccupancy,
} from 'client/pages/Availability/util';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import {
  CalendarEditContext,
  shouldShowBasedOnVisibilityByReservationSlot,
  shouldShowBasedOnVisibilityByResource,
} from 'client/pages/v3/Availability/Shared/CalendarEditContext';
import componentStyles from 'client/pages/Availability/components.module.css';
import calendarStyles from 'client/pages/v3/Availability/AvailabilityCalendar.module.css';
import { ViewContext } from 'client/pages/v3/Availability/AvailabilityMonthly/AvailabilityMonthlyBody/ViewContext';
import { Button } from 'client/components/v3/Common/Button';
import { ModalLoader } from 'client/components/ModalLoader';
import {
  reservationSlotVisibilitySettingSelector,
  resourceVisibilitySettingSelector,
} from 'client/libraries/util/visibilitySettings';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { LanguageISO, uppercaseIsoToLowercaseIso } from 'client/libraries/i18n';
import {
  ProductWithOccupancyPlusColor,
  assignColorsToProducts,
} from 'client/pages/v3/Availability/util';
import { Pagination } from 'client/components/v3/Pagination/Pagination';

type ProductWithOccupancyByDate = {
  [date: string]: ProductWithOccupancyPlusColor[];
};

const getDayOfWeek = (date: string, locale: string) => {
  const dateMoment = moment(date).locale(locale);
  return dateMoment.format('ddd');
};

const AvailabilityDataCellByDate = ({
  date,
  rowNumber,
  items,
  onEditProductInstanceClick,
  onExpandClick,
  showAllItemsByRow,
}: {
  date: string;
  rowNumber: number;
  items: ProductWithOccupancyPlusColor[];
  onEditProductInstanceClick: (params: ProductInstanceModalParams) => void;
  onExpandClick: () => void;
  showAllItemsByRow: {
    [key: number]: boolean;
  };
}) => {
  const { t } = useTranslation();
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);
  const availabilityLoading = useSelector(
    (state: ReduxState) => state.productAvailability.loading
  );

  const instanceVisibilityByReservationSlot = useSelector(
    reservationSlotVisibilitySettingSelector
  );
  const instanceVisibilityByResource = useSelector(
    resourceVisibilitySettingSelector
  );

  const productInstancesById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );
  const allReservations = useSelector(
    (state: ReduxState) => state.reservationSearch.all
  );

  if (availabilityLoading) {
    return (
      <div
        className={clsx(
          calendarStyles['p-calendarMonthly__body__item__bottom'],
          calendarStyles['p-calendarMonthly__body__item__loading']
        )}
      >
        <ModalLoader />
      </div>
    );
  }

  // If item does not exist for some reason (for example being filtered out)
  if (!items) {
    return <></>;
  }

  // Filter items based on visibility settings
  const filteredItems = items
    .map((item) => {
      // Access first element [0] because each item only has one startTimeAvailability after sortAndGroupProductsBasedOnStartTimes
      const startTimeAvailability = item.startTimeAvailabilities[0];
      const availability = startTimeAvailability.date_availabilities?.find(
        (availability) => availability.date === date
      );

      const dispatchCrews = allReservations.find(
        (reservation) =>
          reservation.product_instance_id === availability?.product_instance_id
      )?.dispatch_crew;
      const dispatchVehicles = allReservations.find(
        (reservation) =>
          reservation.product_instance_id === availability?.product_instance_id
      )?.dispatch_vehicles;
      const dispatchMiscResources = allReservations.find(
        (reservation) =>
          reservation.product_instance_id === availability?.product_instance_id
      )?.dispatch_misc_resources;

      const shouldRender =
        shouldShowBasedOnVisibilityByReservationSlot(
          instanceVisibilityByReservationSlot,
          availability?.occupied_slots ?? 0
        ) &&
        shouldShowBasedOnVisibilityByResource(
          instanceVisibilityByResource,
          availability?.product_instance_id ?? '',
          productInstancesById,
          dispatchCrews ?? [],
          dispatchVehicles ?? [],
          dispatchMiscResources ?? []
        );

      if (!shouldRender) {
        return null;
      }
      return item;
    })
    .filter((item): item is ProductWithOccupancyPlusColor => item !== null);

  // Iterate items to create availability card for each product in each start time in each date
  // Show up to only 3 cards initially, card no 4 and beyond will be hidden
  return (
    <>
      <ul className={calendarStyles['p-calendarMonthly__body__item__bottom']}>
        {filteredItems.map((item, index) => {
          // Access first element [0] because each item only has one startTimeAvailability after sortAndGroupProductsBasedOnStartTimes
          const startTimeAvailability = item.startTimeAvailabilities[0];
          const availability = startTimeAvailability.date_availabilities?.find(
            (availability) => availability.date === date
          );

          // No allotment
          if (!availability) {
            return <></>;
          }

          return (
            <AvailabilityCard
              key={index}
              item={item}
              availability={availability}
              onClick={() => {
                if (calendarEditModeIsActive) {
                  if (
                    selectedProductInstanceIds.includes(
                      availability?.product_instance_id ?? ''
                    )
                  ) {
                    deselectProductInstanceIds(
                      availability?.product_instance_id ?? ''
                    );
                  } else {
                    selectProductInstanceIds(
                      availability?.product_instance_id ?? ''
                    );
                  }
                } else {
                  onEditProductInstanceClick({
                    product: item,
                    startTime: startTimeAvailability,
                    date: date,
                    availability: availability,
                  });
                }
              }}
              itemIndex={index}
              rowNumber={rowNumber}
            />
          );
        })}
        {items.length > 3 && (
          <div className={clsx(baseStyles['u-mt-4'], baseStyles['u-pcHidden'])}>
            <Button
              text={
                showAllItemsByRow[rowNumber] ? t('Show less') : t('Show all')
              }
              size="xs"
              color="secondary"
              onClick={onExpandClick}
              iconAfterText={
                showAllItemsByRow[rowNumber] ? (
                  <i className="c-icon-outline-arrows-chevron-up"></i>
                ) : (
                  <i className="c-icon-outline-arrows-chevron-down"></i>
                )
              }
              useFullWidth={true}
            />
          </div>
        )}
      </ul>
    </>
  );
};

const AvailabilityCard = ({
  item,
  availability,
  onClick,
  itemIndex,
  rowNumber,
}: {
  item: ProductWithOccupancyPlusColor;
  availability: ProductDateAvailability;
  onClick: () => void;
  itemIndex: number;
  rowNumber: number;
}) => {
  const productInstancesById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );
  const allReservations = useSelector(
    (state: ReduxState) => state.reservationSearch.all
  );
  const allProducts = useSelector(summariesSelector);
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);
  const { showAllItemsByRow } = useContext(ViewContext);

  const formattedOccupancy = `${
    availability.occupied_slots ?? 0
  } / ${formattedTotalAllotmentSlots(availability.total_slots ?? 0)}`;

  const dispatchCrews = allReservations.find(
    (reservation) =>
      reservation.product_instance_id === availability.product_instance_id
  )?.dispatch_crew;
  const dispatchVehicles = allReservations.find(
    (reservation) =>
      reservation.product_instance_id === availability.product_instance_id
  )?.dispatch_vehicles;
  const dispatchMiscResources = allReservations.find(
    (reservation) =>
      reservation.product_instance_id === availability.product_instance_id
  )?.dispatch_misc_resources;

  const Icon = () => {
    const iconType = getAvailabilityIconType(
      availability.occupied_slots ?? 0,
      availability.total_slots ?? 0,
      availability.all_channels_are_closed ?? false,
      item.shouldRejectRequestBookingsBeyondCapacity ||
        availability.rejects_request_booking_beyond_capacity,
      availability?.booking_deadlines ?? []
    );

    if (iconType === 'STOP') {
      return (
        <img
          className={componentStyles['availability__table']}
          src={stopIcon}
        />
      );
    }

    if (iconType === 'REQUEST') {
      return (
        <img className={componentStyles['availability__table']} src={rqIcon} />
      );
    }

    return null;
  };

  const shouldShowOnlyFirstThreeItems = itemIndex <= 2;
  const shouldShowItem =
    shouldShowOnlyFirstThreeItems || showAllItemsByRow[rowNumber];

  return (
    <>
      {shouldShowItem && (
        <li
          className={clsx(
            calendarStyles['p-availabilityCard'],
            calendarStyles['p-availabilityCard__monthly']
          )}
          style={{
            color: item.cardColor?.color,
            backgroundColor: item.cardColor?.backgroundColor,
            borderColor: item.cardColor?.borderColor,
          }}
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          <div
            className={calendarStyles['p-availabilityCard__check']}
            style={{ display: calendarEditModeIsActive ? 'block' : 'none' }}
          >
            <Checkbox
              checked={selectedProductInstanceIds.includes(
                availability.product_instance_id ?? ''
              )}
              onChange={() => {
                if (
                  selectedProductInstanceIds.includes(
                    availability.product_instance_id ?? ''
                  )
                ) {
                  deselectProductInstanceIds(
                    availability.product_instance_id ?? ''
                  );
                } else {
                  selectProductInstanceIds(
                    availability.product_instance_id ?? ''
                  );
                }
              }}
              size="sm"
            />
          </div>
          <div className={calendarStyles['p-availabilityCard__ttl']}>
            <p>
              {getDisplayProductName(allProducts.find((p) => p.id == item.id))}
            </p>
            {availability.product_instance_id &&
            productInstancesById[availability.product_instance_id]?.memo ? (
              <i className="c-icon-outline-communication-message-text-square-02"></i>
            ) : null}
          </div>
          <div className={calendarStyles['p-availabilityCard__ttl']}>
            <p>{item.startTimeAvailabilities[0].start_time_local}</p>
          </div>
          <div className={calendarStyles['p-availabilityCard__num']}>
            <Icon />
            <p> {formattedOccupancy}</p>
          </div>
          <div className={calendarStyles['p-availabilityCard__info']}>
            {item.childProducts.length > 0 && (
              <div className={calendarStyles['p-availabilityCard__item']}>
                <i className="c-icon-outline-general-link-03"></i>
                <div>
                  {item.childProducts.map((childProduct) => (
                    <Link
                      key={childProduct.id}
                      to={`/products/${childProduct.id}`}
                    >
                      <p>{childProduct.name}</p>
                    </Link>
                  ))}
                </div>
              </div>
            )}
            {dispatchCrews && dispatchCrews.length > 0 && (
              <div className={calendarStyles['p-availabilityCard__item']}>
                <i className="c-icon-solid-users-user-circle"></i>
                <div>
                  {dispatchCrews &&
                    dispatchCrews.map((crew, index) => (
                      <p key={index}>{crew}</p>
                    ))}
                </div>
              </div>
            )}
            {dispatchVehicles && dispatchVehicles.length > 0 && (
              <div className={calendarStyles['p-availabilityCard__item']}>
                <i className="c-icon-outline-maps-bus"></i>

                <div>
                  {dispatchVehicles &&
                    dispatchVehicles.map((vehicle, index) => (
                      <p key={index}>{vehicle}</p>
                    ))}
                </div>
              </div>
            )}
            {dispatchMiscResources && dispatchMiscResources.length > 0 && (
              <div className={calendarStyles['p-availabilityCard__item']}>
                <i className="c-icon-outline-general-info-circle"></i>
                <div>
                  {dispatchMiscResources &&
                    dispatchMiscResources.map((resource, index) => (
                      <p key={index}>{resource}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
        </li>
      )}
    </>
  );
};

type Props = {
  items: ProductWithOccupancy[];
  setEditingProductInstance: (arg0: any) => any;
  setReservationListContext: (arg0: any) => any;
  totalPaxByDate: Record<string, number>;
  startDate?: string;
  onPageSizeChange: (arg0: number) => any;
  onCurrentPageChange: (arg0: number) => any;
};

export type CustomTableColumn = {
  accessor: string;
};

type ProductInstanceModalParams = {
  product: ProductWithOccupancyPlusColor;
  startTime: StartTimeAvailability;
  date: string;
  availability: ProductDateAvailability;
};

const sortAndGroupProductsBasedOnDatesInMonth = (
  data: ProductWithOccupancy[],
  calendarData: (string | null)[],
  languageLowercaseIso: LanguageISO
): ProductWithOccupancyByDate => {
  const groupedData: ProductWithOccupancyByDate = {};

  // Exclude elements with no startTimeAvailabilities.start_time_local
  const filteredData = data.filter(
    (item) => item.startTimeAvailabilities.length > 0
  );

  // Flatten the array to handle multiple date_availabilities values
  const flattenedData = filteredData.reduce((acc, item) => {
    const dateAvailabilities = item.startTimeAvailabilities.map(
      (availability) => availability.date_availabilities || []
    );

    const mappedItems = dateAvailabilities.flatMap(
      (dateAvailabilityArray, index) =>
        dateAvailabilityArray.map((dateAvailability) => ({
          ...item,
          startTimeAvailabilities: [
            {
              date_availabilities: [dateAvailability],
              start_time_local:
                item.startTimeAvailabilities[index]?.start_time_local ?? '',
            },
          ],
        }))
    );

    return acc.concat(mappedItems);
  }, [] as ProductWithOccupancyPlusColor[]);

  // Sort based on calendarData
  const sortedData = flattenedData.sort((a, b) => {
    const dateA =
      a.startTimeAvailabilities[0]?.date_availabilities?.[0]?.date || '';
    const dateB =
      b.startTimeAvailabilities[0]?.date_availabilities?.[0]?.date || '';

    const indexA = dateA ? calendarData.indexOf(dateA) : -1;
    const indexB = dateB ? calendarData.indexOf(dateB) : -1;

    return indexA - indexB;
  });

  // Group based on dates and maintain order based on start_time_local
  sortedData.forEach((item) => {
    const date =
      item.startTimeAvailabilities[0]?.date_availabilities?.[0]?.date || '';

    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');

      if (!groupedData[formattedDate]) {
        groupedData[formattedDate] = [];
      }

      // Convert start_time_local strings to actual time objects
      const itemTime = moment(
        item.startTimeAvailabilities[0]?.start_time_local, // Assumes time strings are in HH:mm format
        'HH:mm'
      );

      // Insert item into the group in ascending order based on start_time_local and name
      let index = groupedData[formattedDate].findIndex((existingItem) => {
        const existingItemTime = moment(
          existingItem.startTimeAvailabilities[0]?.start_time_local,
          'HH:mm'
        );

        // Compare start_time_local
        if (
          existingItemTime.isValid() &&
          itemTime.isValid() &&
          existingItemTime.isAfter(itemTime)
        ) {
          return true; // Item should be inserted before existingItem
        } else if (
          existingItemTime.isValid() &&
          itemTime.isValid() &&
          existingItemTime.isSame(itemTime)
        ) {
          // If start_time_local is the same, compare names using localeCompare
          return (
            existingItem.name.localeCompare(item.name, languageLowercaseIso, {
              sensitivity: 'base',
            }) > 0
          );
        } else {
          return false; // Item should be inserted after existingItem
        }
      });

      if (index === -1) {
        // If no item with greater start_time_local, insert at the end
        index = groupedData[formattedDate].length;
      }

      groupedData[formattedDate].splice(index, 0, item);
    }
  });

  return groupedData;
};

export const AvailabilityCustomCalendar = ({
  items,
  setEditingProductInstance,
  setReservationListContext,
  startDate,
  totalPaxByDate,
  onPageSizeChange,
  onCurrentPageChange,
}: Props) => {
  const [currentMonthCalendarData, setCurrentMonthCalendarData] = useState<
    (string | null)[]
  >([]);
  const [showAllItemsByRow, setShowAllItemsByRow] = useState<{
    [key: number]: boolean;
  }>({});

  const { t } = useTranslation();
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);

  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const languageLowercaseIso = uppercaseIsoToLowercaseIso[defaultLanguage];

  const [productInstanceModalParams, setProductInstanceModalParams] =
    useState<ProductInstanceModalParams | null>(null);
  const [openProductInstanceModal, setOpenProductInstanceModal] =
    useState<boolean>(false);

  const allProducts = useSelector(summariesSelector);
  const dispatch = useDispatch();
  const [noData, setNoData] = useState<boolean>(false);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const [rowCount, setRowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    onPageSizeChange(rowCount);
  }, [rowCount]);
  useEffect(() => {
    onCurrentPageChange(currentPage - 1);
  }, [currentPage]);

  const rowCountChangeHandler = (count: number) => {
    setRowCount(count);
    setCurrentPage(1);
  };
  const pageCount =
    Math.floor(items.length / rowCount) + (items.length % rowCount ? 1 : 0);
  const pageForwardClickHandler = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const generateCalendarData = (
      currentMonth: moment.Moment,
      locale: string
    ): [(string | null)[], (string | null)[]] => {
      moment.locale(locale);

      const firstDayOfMonth = currentMonth.clone().startOf('month');
      const lastDayOfMonth = currentMonth.clone().endOf('month');

      // Find the beginning of the week that includes the first day of the month considering Sunday as the start of the week
      const startDate = firstDayOfMonth.clone().startOf('week');

      const endDate = lastDayOfMonth.clone().endOf('week');

      const data: (string | null)[] = [];
      const groupingData: (string | null)[] = [];

      let currentDate = startDate.clone();

      while (currentDate.isSameOrBefore(endDate)) {
        const itemToPush = currentDate.isBetween(
          firstDayOfMonth,
          lastDayOfMonth,
          null,
          '[]'
        )
          ? currentDate.format('YYYY-MM-DD')
          : null;
        data.push(itemToPush);
        groupingData.push(itemToPush);

        currentDate = currentDate.clone().add(1, 'day');

        // Insert a custom element after every 7 elements for row operations
        if (data.length % 8 === 7) {
          data.push(null);
        }
      }

      return [data, groupingData];
    };

    // Use startOf('month') instead of startOf('isoWeek').add(1, 'day')
    const currentMonth = moment(startDate).startOf('month');
    setCurrentMonthCalendarData(generateCalendarData(currentMonth, locale)[0]);

    const numberOfSubarrays: number = Math.ceil(
      generateCalendarData(currentMonth, locale)[1].length / 7
    );
    const array: { [key: string]: boolean } = Object.fromEntries(
      Array.from({ length: numberOfSubarrays }, (_, i) => [i.toString(), false])
    );
    setShowAllItemsByRow(array);
  }, [startDate, locale]);

  const productInstancesById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );
  const clickedProductInstance: ProductInstance | null = useMemo(
    () =>
      productInstancesById[
        productInstanceModalParams?.availability?.product_instance_id ?? ''
      ] ?? null,
    [
      productInstancesById,
      productInstanceModalParams?.availability?.product_instance_id,
    ]
  );
  const clickedProductInstanceShape: ProductInstanceShape | null = useMemo(
    () =>
      clickedProductInstance
        ? toProductInstanceShape(clickedProductInstance)
        : null,
    [clickedProductInstance]
  );

  const productSummaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  // Fetch product instance by ID whenever params change because we click on a card
  useEffect(() => {
    if (productInstanceModalParams?.availability?.product_instance_id) {
      dispatch(
        fetchProductInstanceByID(
          productInstanceModalParams?.availability?.product_instance_id
        )
      );
    }
  }, [productInstanceModalParams?.availability?.product_instance_id]);

  // ProductInstanceModal relies on child product instances existing in redux state in order to show these
  // reservations in the reservation list modal.
  useEffect(() => {
    if (clickedProductInstance) {
      const product = productSummaries.find(
        (product) => product.id === clickedProductInstance.product_id
      );

      const childProductIds =
        product?.shared_allotment_references?.child_product_ids ?? [];
      if (childProductIds.length > 0) {
        const startDate = moment.parseZone(
          clickedProductInstance.start_date_time_local
        );
        const endDate = moment
          .parseZone(clickedProductInstance.start_date_time_local)
          .add(1, 'days');
        dispatch(
          fetchProductInstancesForProducts(childProductIds, startDate, endDate)
        );
      }
    }
  }, [clickedProductInstance, productSummaries]);

  // Determine whether we have data or not to display
  useEffect(() => {
    if (!items || items.length === 0) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [items]);

  const itemsWithColor = assignColorsToProducts(items);
  const visibleItemsByDate = sortAndGroupProductsBasedOnDatesInMonth(
    itemsWithColor,
    currentMonthCalendarData,
    languageLowercaseIso
  );

  const handleRowClick = (rowNumber: number) => {
    setShowAllItemsByRow((prevShowAllItems) => ({
      ...prevShowAllItems,
      [rowNumber]: !prevShowAllItems[rowNumber], // Toggle the boolean value
    }));
  };

  const dayOfWeekColumns = [
    { id: 1, accessor: t('Sun') },
    { id: 2, accessor: t('Mon') },
    { id: 3, accessor: t('Tue') },
    { id: 4, accessor: t('Wed') },
    { id: 5, accessor: t('Thu') },
    { id: 6, accessor: t('Fri') },
    { id: 7, accessor: t('Sat') },
  ];

  return (
    <ViewContext.Provider value={{ showAllItemsByRow }}>
      <div className={calendarStyles['p-calendarMonthly']}>
        {noData && (
          <div className={calendarStyles['p-calendar__noData']}>
            {t(
              'There is no reservation slots to show. Please adjust the filters and visibility settings.'
            )}
          </div>
        )}
        <>
          {/* Calendar header */}
          <ul
            className={clsx(
              calendarStyles['p-calendarMonthly__header'],
              baseStyles['u-spHidden']
            )}
            style={{ display: noData ? 'none' : undefined }}
          >
            {dayOfWeekColumns.map((column) => {
              return (
                <li key={column.id}>
                  <p>{column.accessor}</p>
                </li>
              );
            })}
          </ul>

          {/* Calendar body */}
          <ul
            className={calendarStyles['p-calendarMonthly__body']}
            style={{ display: noData ? 'none' : undefined }}
          >
            {currentMonthCalendarData.map((calendarDate, index) => {
              // Render operation row after every 7th item
              const rowNumber = Math.floor(index / 8);

              if (index % 8 === 7) {
                // Check list of items for each calendar date and see if any of them has more than 3 items
                // Hide operation row if none matches
                let shouldRenderOperationRow = false;
                for (let i = 0; i < currentMonthCalendarData.length; i++) {
                  const previousSevenDates = currentMonthCalendarData.slice(
                    index - 7,
                    i
                  );
                  for (const date of previousSevenDates) {
                    if (date && visibleItemsByDate[date]) {
                      shouldRenderOperationRow =
                        visibleItemsByDate[date].length > 3;
                    }
                  }
                }

                return (
                  <li
                    key={index}
                    className={calendarStyles['p-calendarMonthly__body__item']}
                    style={{ display: shouldRenderOperationRow ? '' : 'none' }}
                  >
                    <Button
                      text={
                        showAllItemsByRow[rowNumber]
                          ? t('Show less')
                          : t('Show all')
                      }
                      size="xs"
                      color="secondary"
                      onClick={() => handleRowClick(rowNumber)}
                      iconAfterText={
                        showAllItemsByRow[rowNumber] ? (
                          <i className="c-icon-outline-arrows-chevron-up"></i>
                        ) : (
                          <i className="c-icon-outline-arrows-chevron-down"></i>
                        )
                      }
                      useFullWidth={true}
                    />
                  </li>
                );
              } else {
                const columnProductInstanceIds: string[] = [];

                Object.entries(visibleItemsByDate).forEach(([date, items]) => {
                  if (date === calendarDate) {
                    for (const item of items) {
                      for (const availability of item.startTimeAvailabilities) {
                        const productInstanceId =
                          availability.date_availabilities?.find(
                            (dateAvailability) => dateAvailability.date === date
                          )?.product_instance_id;

                        if (productInstanceId) {
                          columnProductInstanceIds.push(productInstanceId);
                        }
                      }
                    }
                  }
                });

                const columnIsChecked = columnProductInstanceIds.every((id) =>
                  selectedProductInstanceIds.includes(id)
                );

                return (
                  <li
                    key={index}
                    className={clsx(
                      calendarStyles['p-calendarMonthly__body__item'],
                      !calendarDate && baseStyles['u-spHidden']
                    )}
                  >
                    {calendarDate ? (
                      <>
                        <div
                          className={
                            calendarStyles['p-calendarMonthly__body__item__top']
                          }
                        >
                          {/* Checkbox to select the whole date column */}
                          {calendarEditModeIsActive &&
                            visibleItemsByDate[calendarDate] && (
                              <div
                                className={
                                  calendarStyles[
                                    'p-calendarMonthly__body__item__top__check'
                                  ]
                                }
                              >
                                <Checkbox
                                  checked={columnIsChecked}
                                  onChange={() => {
                                    if (columnIsChecked) {
                                      deselectProductInstanceIds(
                                        ...columnProductInstanceIds
                                      );
                                    } else {
                                      selectProductInstanceIds(
                                        ...columnProductInstanceIds
                                      );
                                    }
                                  }}
                                  size="sm"
                                />
                              </div>
                            )}
                          <p
                            className={clsx(
                              calendarStyles[
                                'p-calendarMonthly__body__item__top__week'
                              ],
                              baseStyles['u-pcHidden']
                            )}
                          >
                            {getDayOfWeek(calendarDate, locale)}
                          </p>
                          <p
                            className={
                              calendarStyles[
                                'p-calendarMonthly__body__item__top__date'
                              ]
                            }
                          >
                            {moment(calendarDate).format('D')}
                          </p>
                          <p
                            className={clsx(
                              calendarStyles[
                                'p-calendarMonthly__body__item__top__total'
                              ],
                              baseStyles['u-pcHidden']
                            )}
                          >
                            {typeof totalPaxByDate[calendarDate] ===
                            'number' ? (
                              <>
                                {t('Total: ')}
                                <span>
                                  {t('{{count}} pax', {
                                    count: totalPaxByDate[calendarDate],
                                  })}
                                </span>
                              </>
                            ) : (
                              <ModalLoader />
                            )}
                          </p>
                        </div>
                        <AvailabilityDataCellByDate
                          date={calendarDate}
                          rowNumber={rowNumber}
                          items={visibleItemsByDate[calendarDate]}
                          onEditProductInstanceClick={(
                            params: ProductInstanceModalParams
                          ) => {
                            setProductInstanceModalParams(params);
                            setOpenProductInstanceModal(true);
                          }}
                          onExpandClick={() => handleRowClick(rowNumber)}
                          showAllItemsByRow={showAllItemsByRow}
                        />
                      </>
                    ) : (
                      <div
                        className={
                          calendarStyles['p-calendarMonthly__body__item__top']
                        }
                      >
                        {/* Render day of month outside the current month here */}
                        {calendarDate}
                      </div>
                    )}
                  </li>
                );
              }
            })}
          </ul>
        </>
      </div>
      <div className={calendarStyles['p-availabilityPagination']}>
        <Pagination
          totalItems={items.length}
          currentPage={currentPage}
          pageCount={pageCount}
          selectedNumberOfLinesToDisplay={rowCount}
          onChangeNumberOfLinesToDisplay={rowCountChangeHandler}
          onClickPrevButton={pageBackClickHandler}
          onClickNextButton={pageForwardClickHandler}
          disableNumberOfLinesToDisplay={true}
        />
      </div>

      {productInstanceModalParams &&
        clickedProductInstance &&
        clickedProductInstanceShape &&
        openProductInstanceModal && (
          <ProductInstanceModal
            productName={getDisplayProductName(
              allProducts.find(
                (p) => p.id == productInstanceModalParams.product.id
              )
            )}
            allotmentSettings={
              productInstanceModalParams.product.allotmentSettings
            }
            timezone={productInstanceModalParams.product.timezone}
            onEditClick={setEditingProductInstance}
            onListClick={() =>
              setReservationListContext({
                productIds: [
                  productInstanceModalParams.product.id,
                  ...productInstanceModalParams.product.childProducts.map(
                    (p) => p.id
                  ),
                ],
                rootProductInstanceId:
                  productInstanceModalParams.availability.product_instance_id ??
                  0,
                date: productInstanceModalParams.date,
              })
            }
            productInstance={clickedProductInstance}
            instance={clickedProductInstanceShape}
            onClose={() => {
              setOpenProductInstanceModal(false);
            }}
            shouldRejectBookingsBeyondCapacity={
              allProducts.find(
                (p) => p.id == productInstanceModalParams.product.id
              )?.request_booking_settings
                ?.should_reject_bookings_beyond_capacity ?? false
            }
          />
        )}
    </ViewContext.Provider>
  );
};
