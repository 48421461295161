import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './AccordionList.module.css';

export type AccordionItemContent = {
  header: string;
  content: React.ComponentType<any>;
  isActiveDefault?: boolean;
};
interface Props {
  isActiveDefault?: boolean;
  item: AccordionItemContent;
}
export const AccordionItem = ({ item }: Props) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(
    item.isActiveDefault || false
  );
  const Content = item.content;

  return (
    <li className={styles['c-accordion__list']}>
      <div
        className={clsx(
          styles['c-accordion__list__header'],
          isActive && styles['is-active']
        )}
        onClick={() => setIsActive(!isActive)}
      >
        <h3 className={styles['c-accordion__list__header__text']}>
          {item.header}
        </h3>
        <p className={styles['c-accordion__list__header__ic']} />
        <p className={styles['c-accordion__list__header__ic__text']}>
          {isActive ? t('Hide') : t('Show')}
        </p>
      </div>
      <div
        className={clsx(
          styles['c-accordion__list__body'],
          isActive && styles['is-active']
        )}
      >
        <Content />
      </div>
    </li>
  );
};
